<template>
  <div class="text-center" style="place-items: center !important;">
    <finger-reader-raw
      @change="huellaCapturada"
    >
    </finger-reader-raw>
    <lottie :options="loadingAnimation" :height="250" :width="250" />
    <br>
    <v-progress-linear
      :value="progreso"
      style="max-width: 750px !important;"
      height="10"
    >
    </v-progress-linear>
  </div>
</template>


<script>
import FingerReaderRaw from '@/components/FingerReaderRaw.vue';
import Lottie from 'vue-lottie';
import chartsAnimationData from '@/assets/finger-reader.json';
export default {
  name: 'FingerReader',
  props: {
    numCaptures: {
      type: Number,
      default: 3,
    },
  },
  components: {
    FingerReaderRaw,
    'lottie': Lottie
  },
  computed: {
    progreso(){
      if(this.numCaptures <= 0 || this.lecturas == null) return 0;
      if(this.lecturas.length >= this.numCaptures) return 100;
      else return (this.lecturas.length / this.numCaptures) * 100;
    }
  },
  data() {
    return {
      loadingAnimation: {animationData: chartsAnimationData},
      lecturas: [],
    };
  },
  methods: {
    huellaCapturada(evt){
      this.lecturas.push(evt);
      if(this.lecturas.length >= this.numCaptures){
        this.$emit('change', this.lecturas);
        this.lecturas = [];
      }
    }
  }
}
</script>