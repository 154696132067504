<template>
  <v-container style="max-width: 1800px !important">
    <div class="pa-3 ma-3">
      <v-alert-dialog ref="alert"> </v-alert-dialog>
      <v-dialog v-model="dialogAddEdit" max-width="90%" persistent>
        <v-card>
          <v-card-title>
            {{
              editedItem.id_proveedor === null ? "Crear" : "Editar"
            }}
            Proveedor
          </v-card-title>

          <v-card-text>
            <v-stepper v-model="step">
              <!-- Step 1 -->
              <v-stepper-header>
                <v-stepper-step
                  :complete="step > 1"
                  step="1"
                >
                  Datos Generales
                </v-stepper-step>

                <v-divider></v-divider>

                <!-- Step 2 -->
                <v-stepper-step
                  :complete="step > 2"
                  step="2"
                  :disabled="editedItem.id_proveedor == null"
                >
                  Datos Fiscales
                </v-stepper-step>

                <v-divider></v-divider>

                <!-- Step 3 -->
                <v-stepper-step
                  :complete="step > 3"
                  step="3"
                  :disabled="editedItem.id_proveedor == null"
                >
                  Cuentas Bancarias
                </v-stepper-step>
              </v-stepper-header>

              <v-stepper-items>
                <!-- Step 1 Content -->
                <v-stepper-content step="1">
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" class="text-center">
                        <h3>Información Básica</h3>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-autocomplete
                          :items="tipo_proveedores"
                          v-model="editedItem.id_tipo_proveedor"
                          label="Tipo de Proveedor"
                          item-value="id_tipo_proveedor"
                          item-text="nombre"
                          dense
                          outlined
                          hide-details
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-autocomplete
                          :items="tiposPersonas"
                          v-model="editedItem.id_tipo_persona"
                          label="Tipo de Persona"
                          item-value="id_tipo_persona"
                          item-text="nombre"
                          dense
                          outlined
                          hide-details
                        ></v-autocomplete>
                      </v-col>
                      <template v-if="editedItem.id_tipo_proveedor == '34a6560f-61c4-4bd0-a778-b7e641818fd0'">
                        <v-col cols="12" md="6">
                          <v-text-field
                            label="RFC *"
                            dense
                            outlined
                            :hide-details="!editedItemFiscal.bloquear_rfc"
                            :error="editedItemFiscal.bloquear_rfc"
                            :error-messages="editedItemFiscal.bloquear_rfc ? 'El RFC proporcionado se encuentra en el listado del Artículo 69-B del Código Fiscal de la Federación.' : []"
                            v-model="editedItemFiscal.rfc"
                            counter
                            :maxlength="(editedItem.id_tipo_persona == '689a6d20-3aff-4a09-b5b4-52105ad212f0') ? 12 : 13"
                            @input="validateRFC"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6">
                          <v-select
                            label="Tipo de Proveedor Nacional*"
                            dense
                            outlined
                            hide-details
                            v-model="editedItem.id_tipo_proveedor_nacional"
                            :items="tiposProveedorNacional"
                            item-text="nombre"
                            item-value="id_tipo_proveedor_nacional"
                          >
                          </v-select>
                        </v-col>
                      </template>

                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="editedItem.nombre_corto"
                          label="Nombre Corto del Proveedor *"
                          dense
                          outlined
                          hide-details
                        >
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="editedItem.nombre_completo"
                          label="Nombre Completo del Proveedor *"
                          dense
                          outlined
                          hide-details
                        >
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="editedItem.nombre_contacto"
                          label="Nombre del Contacto *"
                          dense
                          outlined
                          hide-details
                        >
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="editedItem.telefono_contacto"
                          label="Teléfono de Contacto *"
                          dense
                          outlined
                          hide-details
                        >
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="editedItem.correo_electronico"
                          label="Correo Electrónico *"
                          dense
                          outlined
                          hide-details
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>

                    <v-row justify="center">
                      <v-expansion-panels
                        v-model="panelsAddEdit"
                        accordion
                      >
                        <v-expansion-panel>
                          <v-expansion-panel-header class="text-center grey--text text--darken-2">
                              <h4>Datos Geográficos</h4>
                            </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-row>
                              <v-col cols="12">
                              <google-maps-url-input
                                v-model="editedItem.direccion_google_maps"
                                label="URL de la ubicación en Google Maps"
                                dense
                                hide-details
                                outlined
                                ></google-maps-url-input>
                              </v-col>
                              <v-col cols="6" class="py-1">
                                <v-file-input-plus
                                  :urlBase="urlBase"
                                  dense
                                  accept=".jpg,.jpeg,.png,.pdf"
                                  :max-size="5242880"
                                  hide-details
                                  outlined
                                  label="Fotografía de la fachada de la empresa 1"
                                  v-model="editedItem.fachada_empresa_1"
                                ></v-file-input-plus>
                              </v-col>
                              <v-col cols="6" class="py-1">
                                <v-file-input-plus
                                  :urlBase="urlBase"
                                  dense
                                  accept=".jpg,.jpeg,.png,.pdf"
                                  :max-size="5242880"
                                  hide-details
                                  outlined
                                  label="Fotografía de la fachada de la empresa 2"
                                  v-model="editedItem.fachada_empresa_2"
                                ></v-file-input-plus>
                              </v-col>
                            </v-row>
                          </v-expansion-panel-content>
                        </v-expansion-panel>

                        <v-expansion-panel>
                          <v-expansion-panel-header class="text-center grey--text text--darken-2">
                              <div>
                                <h4>Datos de la Sucursal</h4>
                                <br>
                                (Si aplica)
                              </div>
                            </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-row>
                              <!-- Datos Proveedor Nacional -->
                              <template v-if="editedItem.id_tipo_proveedor == '34a6560f-61c4-4bd0-a778-b7e641818fd0'">
                                <v-col cols="6">
                                  <v-text-field
                                    label="Calle"
                                    dense
                                    outlined
                                    hide-details
                                    v-model="editedItem.calle_sucursal"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="3">
                                  <v-text-field
                                    label="Núm Exterior"
                                    dense
                                    outlined
                                    hide-details
                                    v-model="editedItem.num_ext_sucursal"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="3">
                                  <v-text-field
                                    label="Núm Interior"
                                    dense
                                    outlined
                                    hide-details
                                    v-model="editedItem.num_int_sucursal"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                  <v-autocomplete
                                    :items="estados"
                                    label="Estado"
                                    item-value="id_estado"
                                    item-text="nombre"
                                    dense
                                    outlined
                                    hide-details
                                    @change="getMunicipiosSucursal"
                                    v-model="editedItem.id_estado_sucursal"
                                  ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" md="6">
                                  <v-autocomplete
                                    :items="municipiosSucursal"
                                    item-value="id_municipio"
                                    item-text="nombre"
                                    label="Municipio"
                                    dense
                                    outlined
                                    hide-details
                                    :loading="loadingMunicipios"
                                    v-model="editedItem.id_municipio_sucursal"
                                  ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" md="6">
                                  <v-text-field
                                    label="Colonia"
                                    dense
                                    outlined
                                    hide-details
                                    v-model="editedItem.colonia_sucursal"
                                  ></v-text-field>
                                </v-col>    
                                <v-col cols="12" md="6">
                                  <v-text-field
                                    label="Código Postal"
                                    dense
                                    outlined
                                    hide-details
                                    v-model="editedItem.codigo_postal_sucursal"
                                  ></v-text-field>
                                </v-col>
                              </template>

                              
                              <!-- Datos Sucursal Internacionales -->
                              <template v-if="editedItem.id_tipo_proveedor == '4ae22cb8-3564-41e9-a726-302cb6762e02'">
                                <v-col cols="6">
                                  <v-text-field
                                    label="Calle"
                                    dense
                                    outlined
                                    hide-details
                                    v-model="editedItem.calle_sucursal"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="3">
                                  <v-text-field
                                    label="Núm Exterior"
                                    dense
                                    outlined
                                    hide-details
                                    v-model="editedItem.num_ext_sucursal"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="3">
                                  <v-text-field
                                    label="Núm Interior"
                                    dense
                                    outlined
                                    hide-details
                                    v-model="editedItem.num_int_sucursal"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                  <v-text-field
                                    label="Código Postal"
                                    dense
                                    outlined
                                    hide-details
                                    v-model="editedItem.codigo_postal_sucursal"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                  <v-text-field
                                    label="Ciudad"
                                    dense
                                    outlined
                                    hide-details
                                    v-model="editedItem.ciudad_sucursal"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                  <v-text-field
                                    label="Región/Estado/Provincia"
                                    dense
                                    outlined
                                    hide-details
                                    v-model="editedItem.region_sucursal"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                  <v-autocomplete
                                    :items="paises"
                                    label="País"
                                    item-value="id_pais"
                                    item-text="nombre"
                                    dense
                                    outlined
                                    hide-details
                                    v-model="editedItem.pais_sucursal"
                                  ></v-autocomplete>
                                </v-col>
                              </template>
                            </v-row>
                          </v-expansion-panel-content>
                        </v-expansion-panel>

                        <v-expansion-panel>
                          <v-expansion-panel-header class="text-center grey--text text--darken-2">
                              <h4>Documentos Adjuntos</h4>
                            </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-row>
                              <!-- Documentos para Proveedor Nacional / Persona Fisica -->
                              <template v-if="editedItem.id_tipo_proveedor == '34a6560f-61c4-4bd0-a778-b7e641818fd0' && editedItem.id_tipo_persona == 'abddb0b2-32b4-47d4-8a75-7ac30e95c69b'">
                                <v-col cols="6">
                                  <v-row class="pa-0 ma-0">
                                    <v-col cols="12" class="ma-0 pa-0 py-2">
                                      <v-file-input-plus
                                        :urlBase="urlBase"
                                        dense
                                        accept=".jpg,.jpeg,.png,.pdf"
                                        :max-size="5242880"
                                        outlined
                                        label="Currículum Vitae empresarial"
                                        v-model="editedItem.curriculum_vitae_empresarial"
                                      ></v-file-input-plus>
                                    </v-col>
                                    <v-col cols="12" class="ma-0 pa-0 py-2">
                                      <v-file-input-plus
                                        :urlBase="urlBase"
                                        dense
                                        accept=".jpg,.jpeg,.png,.pdf"
                                        :max-size="5242880"
                                        outlined
                                        label="Constancia de situación fiscal"
                                        v-model="editedItem.situacion_fiscal"
                                      ></v-file-input-plus>
                                      <div class="text-caption note-style">Nota: Emitida en el mes que solicita el registro.</div>
                                    </v-col>
                                    <v-col cols="12" class="ma-0 pa-0 py-2">
                                      <v-file-input-plus
                                        :urlBase="urlBase"
                                        dense
                                        accept=".jpg,.jpeg,.png,.pdf"
                                        :max-size="5242880"
                                        outlined
                                        label="Opinión de cumplimiento positiva de obligaciones fiscales"
                                        v-model="editedItem.opinion_cumplimiento"
                                      ></v-file-input-plus>
                                      <div class="text-caption note-style">Nota: Emitida en el mes que solicita el registro.</div>
                                    </v-col>
                                    <v-col cols="12" class="ma-0 pa-0 py-2">
                                      <v-file-input-plus
                                        :urlBase="urlBase"
                                        dense
                                        accept=".jpg,.jpeg,.png,.pdf"
                                        :max-size="5242880"
                                        outlined
                                        label="Copia de la carátula del estado de cuenta"
                                        v-model="editedItem.caratula_estado_cuenta"
                                      ></v-file-input-plus>
                                      <div class="text-caption note-style">
                                        En la portada, se debe incluir la siguiente información: nombre del titular de la cuenta, número de cuenta, CLABE interbancaria, moneda, sucursal, plaza e institución bancaria, y/o contrato de apertura. Además, debe incluirse la leyenda al margen: "Ratifico ser el titular de esta cuenta y me responsabilizo de la información proporcionada", junto con la firma correspondiente.
                                      </div>
                                    </v-col>
                                    <v-col cols="12" class="ma-0 pa-0 py-2">
                                      <v-file-input-plus
                                        :urlBase="urlBase"
                                        dense
                                        accept=".jpg,.jpeg,.png,.pdf"
                                        :max-size="5242880"
                                        outlined
                                        :label="`Certificado 1`"
                                        v-model="editedItem.certificado_1"
                                      ></v-file-input-plus>
                                    </v-col>
                                    <v-col cols="12" class="ma-0 pa-0 py-2">
                                      <v-file-input-plus
                                        :urlBase="urlBase"
                                        dense
                                        accept=".jpg,.jpeg,.png,.pdf"
                                        :max-size="5242880"
                                        outlined
                                        label="Certificado 2"
                                        v-model="editedItem.certificado_2"
                                      ></v-file-input-plus>
                                    </v-col>
                                    <v-col cols="12" class="ma-0 pa-0 py-2">
                                      <v-file-input-plus
                                        :urlBase="urlBase"
                                        dense
                                        accept=".jpg,.jpeg,.png,.pdf"
                                        :max-size="5242880"
                                        outlined
                                        label="Certificado 3"
                                        v-model="editedItem.certificado_3"
                                      ></v-file-input-plus>
                                    </v-col>

                                    <template
                                      v-if="editedItem.id_tipo_proveedor_nacional == 2"
                                    >
                                      <v-col cols="12" class="ma-0 pa-0 py-2">
                                        <v-file-input-plus
                                          :urlBase="urlBase"
                                          dense
                                          accept=".jpg,.jpeg,.png,.pdf"
                                          :max-size="5242880"
                                          outlined
                                          :label="`Póliza de responsabilidad civil medica`"
                                          v-model="editedItem.poliza_responsabilidad_civil_medica"
                                        ></v-file-input-plus>
                                      </v-col>
                                      <v-col cols="12" class="ma-0 pa-0 py-2">
                                        <v-file-input-plus
                                          :urlBase="urlBase"
                                          dense
                                          accept=".jpg,.jpeg,.png,.pdf"
                                          :max-size="5242880"
                                          outlined
                                          label="Copia de título de Medico"
                                          v-model="editedItem.copia_titulo_medico"
                                        ></v-file-input-plus>
                                      </v-col>
                                      <v-col cols="12" class="ma-0 pa-0 py-2">
                                        <v-file-input-plus
                                          :urlBase="urlBase"
                                          dense
                                          accept=".jpg,.jpeg,.png,.pdf"
                                          :max-size="5242880"
                                          outlined
                                          label="Copia de Cedula profesional como Medico"
                                          v-model="editedItem.cedula_profesional_medico"
                                        ></v-file-input-plus>
                                      </v-col>
                                      <v-col cols="12" class="ma-0 pa-0 py-2">
                                        <v-file-input-plus
                                          :urlBase="urlBase"
                                          dense
                                          accept=".jpg,.jpeg,.png,.pdf"
                                          :max-size="5242880"
                                          outlined
                                          label="Copia del diploma e especialidad"
                                          v-model="editedItem.diploma_especialidad"
                                        ></v-file-input-plus>
                                      </v-col>
                                      <v-col cols="12" class="ma-0 pa-0 py-2">
                                        <v-file-input-plus
                                          :urlBase="urlBase"
                                          dense
                                          accept=".jpg,.jpeg,.png,.pdf"
                                          :max-size="5242880"
                                          outlined
                                          label="Copia de la cedula de radiología"
                                          v-model="editedItem.cedula_radiologia"
                                        ></v-file-input-plus>
                                      </v-col>
                                    </template>
                                  </v-row>
                                </v-col>
                                <v-col cols="6">
                                  <v-row class="ma-0 pa-0">
                                    <v-col cols="12" class="ma-0 pa-0 py-2">
                                      <v-file-input-plus
                                        :urlBase="urlBase"
                                        dense
                                        accept=".jpg,.jpeg,.png,.pdf"
                                        :max-size="5242880"
                                        outlined
                                        label="Copia simple de identificación oficial"
                                        v-model="editedItem.identificacion_oficial"
                                      ></v-file-input-plus>
                                    </v-col>
                                    <v-col cols="12" class="ma-0 pa-0 py-2">
                                      <v-file-input-plus
                                        :urlBase="urlBase"
                                        dense
                                        accept=".jpg,.jpeg,.png,.pdf"
                                        :max-size="5242880"
                                        outlined
                                        label="Copia simple de comprobante domicilio"
                                        v-model="editedItem.comprobante_domicilio"
                                      ></v-file-input-plus>
                                      <div class="text-caption note-style">Nota: No mayor a 3 meses.</div>
                                    </v-col>
                                    <v-col cols="12" class="ma-0 pa-0 py-2">
                                      <v-file-input-plus
                                        :urlBase="urlBase"
                                        dense
                                        accept=".jpg,.jpeg,.png,.pdf"
                                        :max-size="5242880"
                                        outlined
                                        label="Carta de referencia comercial 1"
                                        v-model="editedItem.referencia_comercial_1"
                                      ></v-file-input-plus>
                                      <div class="text-caption note-style">Nota: En papel membretado (no mayor a 3 meses).</div>
                                    </v-col>
                                    <v-col cols="12" class="ma-0 pa-0 py-2">
                                      <v-file-input-plus
                                        :urlBase="urlBase"
                                        dense
                                        accept=".jpg,.jpeg,.png,.pdf"
                                        :max-size="5242880"
                                        outlined
                                        label="Carta de referencia comercial 2"
                                        v-model="editedItem.referencia_comercial_2"
                                      ></v-file-input-plus>
                                      <div class="text-caption note-style">Nota: En papel membretado (no mayor a 3 meses).</div>
                                    </v-col>
                                    <v-col cols="12" class="ma-0 pa-0 py-2">
                                      <v-file-input-plus
                                        :urlBase="urlBase"
                                        dense
                                        accept=".jpg,.jpeg,.png,.pdf"
                                        :max-size="5242880"
                                        outlined
                                        label="Copia de la certificación, licencia o permiso"
                                        v-model="editedItem.certificacion"
                                      ></v-file-input-plus>
                                      <div class="text-caption note-style">Nota: Asegúrate que el documento esté vigente.</div>
                                    </v-col>
                                    <v-col cols="12" class="ma-0 pa-0 py-2">
                                      <v-file-input-plus
                                        :urlBase="urlBase"
                                        dense
                                        accept=".jpg,.jpeg,.png,.pdf"
                                        :max-size="5242880"
                                        outlined
                                        label="Fotografía del producto o servicio 1"
                                        v-model="editedItem.fotografia_producto_1"
                                      ></v-file-input-plus>
                                    </v-col>
                                    <v-col cols="12" class="ma-0 pa-0 py-2">
                                      <v-file-input-plus
                                        :urlBase="urlBase"
                                        dense
                                        accept=".jpg,.jpeg,.png,.pdf"
                                        :max-size="5242880"
                                        outlined
                                        label="Fotografía del producto o servicio 2"
                                        v-model="editedItem.fotografia_producto_2"
                                      ></v-file-input-plus>
                                    </v-col>
                                    <v-col cols="12" class="ma-0 pa-0 py-2">
                                      <v-file-input-plus
                                        :urlBase="urlBase"
                                        dense
                                        accept=".jpg,.jpeg,.png,.pdf"
                                        :max-size="5242880"
                                        outlined
                                        label="Fotografía del producto o servicio 3"
                                        v-model="editedItem.fotografia_producto_3"
                                      ></v-file-input-plus>
                                    </v-col>
                                    <v-col cols="12" class="ma-0 pa-0 py-2">
                                      <v-file-input-plus
                                        :urlBase="urlBase"
                                        dense
                                        accept=".jpg,.jpeg,.png,.pdf"
                                        :max-size="5242880"
                                        outlined
                                        label="Fotografía del producto o servicio 4"
                                        v-model="editedItem.fotografia_producto_4"
                                      ></v-file-input-plus>
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </template>

                              <!-- Documentos para Proveedor Nacional / Persona Moral -->
                              <template v-else-if="editedItem.id_tipo_proveedor == '34a6560f-61c4-4bd0-a778-b7e641818fd0' && editedItem.id_tipo_persona == '689a6d20-3aff-4a09-b5b4-52105ad212f0'">
                                <v-col cols="6">
                                  <v-row class="ma-0 pa-0">
                                    <v-col cols="12" class="ma-0 pa-0 py-2">
                                      <v-file-input-plus
                                        :urlBase="urlBase"
                                        dense
                                        accept=".jpg,.jpeg,.png,.pdf"
                                        :max-size="5242880"
                                        outlined
                                        label="Currículum de la empresa"
                                        v-model="editedItem.curriculum_empresa"
                                      ></v-file-input-plus>
                                    </v-col>
                                    <v-col cols="12" class="ma-0 pa-0 py-2">
                                      <v-file-input-plus
                                        :urlBase="urlBase"
                                        dense
                                        accept=".jpg,.jpeg,.png,.pdf"
                                        :max-size="5242880"
                                        outlined
                                        label="Copia simple del acta constitutiva y actas modificatorias"
                                        v-model="editedItem.acta_constitutiva"
                                      ></v-file-input-plus>
                                    </v-col>
                                    <v-col cols="12" class="ma-0 pa-0 py-2">
                                      <v-file-input-plus
                                        :urlBase="urlBase"
                                        dense
                                        accept=".jpg,.jpeg,.png,.pdf"
                                        :max-size="5242880"
                                        outlined
                                        label="Copia simple del poder notariado del representante legal"
                                        v-model="editedItem.poder_representante"
                                      ></v-file-input-plus>
                                    </v-col>
                                    <v-col cols="12" class="ma-0 pa-0 py-2">
                                      <v-file-input-plus
                                        :urlBase="urlBase"
                                        dense
                                        accept=".jpg,.jpeg,.png,.pdf"
                                        :max-size="5242880"
                                        outlined
                                        label="Copia de la carátula del estado de cuenta"
                                        v-model="editedItem.caratula_estado_cuenta"
                                      ></v-file-input-plus>
                                      <div class="text-caption note-style">
                                        En la portada, se debe incluir la siguiente información: nombre del titular de la cuenta, número de cuenta, CLABE interbancaria, moneda, sucursal, plaza e institución bancaria, y/o contrato de apertura. Además, debe incluirse la leyenda al margen: "Ratifico ser el titular de esta cuenta y me responsabilizo de la información proporcionada", junto con la firma correspondiente.
                                      </div>
                                    </v-col>
                                    <v-col cols="12" class="ma-0 pa-0 py-2">
                                      <v-file-input-plus
                                        :urlBase="urlBase"
                                        dense
                                        accept=".jpg,.jpeg,.png,.pdf"
                                        :max-size="5242880"
                                        outlined
                                        label="Constancia de situación fiscal"
                                        v-model="editedItem.situacion_fiscal"
                                      ></v-file-input-plus>
                                      <div class="text-caption note-style">Nota: Emitida en el mes que solicita el registro.</div>
                                    </v-col>
                                    <v-col cols="12" class="ma-0 pa-0 py-2">
                                      <v-file-input-plus
                                        :urlBase="urlBase"
                                        dense
                                        accept=".jpg,.jpeg,.png,.pdf"
                                        :max-size="5242880"
                                        outlined
                                        label="Opinión de cumplimiento positiva de obligaciones fiscales"
                                        v-model="editedItem.opinion_cumplimiento"
                                      ></v-file-input-plus>
                                      <div class="text-caption note-style">Nota: Emitida en el mes que solicita el registro.</div>
                                    </v-col>

                                    <v-col cols="12" class="ma-0 pa-0 py-2">
                                      <v-file-input-plus
                                        :urlBase="urlBase"
                                        dense
                                        accept=".jpg,.jpeg,.png,.pdf"
                                        :max-size="5242880"
                                        outlined
                                        :label="`Certificado 1`"
                                        v-model="editedItem.certificado_1"
                                      ></v-file-input-plus>
                                    </v-col>
                                    <v-col cols="12" class="ma-0 pa-0 py-2">
                                      <v-file-input-plus
                                        :urlBase="urlBase"
                                        dense
                                        accept=".jpg,.jpeg,.png,.pdf"
                                        :max-size="5242880"
                                        outlined
                                        label="Certificado 2"
                                        v-model="editedItem.certificado_2"
                                      ></v-file-input-plus>
                                    </v-col>
                                    <v-col cols="12" class="ma-0 pa-0 py-2">
                                      <v-file-input-plus
                                        :urlBase="urlBase"
                                        dense
                                        accept=".jpg,.jpeg,.png,.pdf"
                                        :max-size="5242880"
                                        outlined
                                        label="Certificado 3"
                                        v-model="editedItem.certificado_3"
                                      ></v-file-input-plus>
                                    </v-col>
                                  </v-row>
                                </v-col>
                                <v-col cols="6">
                                  <v-row class="ma-0 pa-0">
                                    <v-col cols="12" class="ma-0 pa-0 py-2">
                                      <v-file-input-plus
                                        :urlBase="urlBase"
                                        dense
                                        accept=".jpg,.jpeg,.png,.pdf"
                                        :max-size="5242880"
                                        outlined
                                        label="Copia simple de identificación oficial del representante legal"
                                        v-model="editedItem.identificacion_representante"
                                      ></v-file-input-plus>
                                    </v-col>
                                    <v-col cols="12" class="ma-0 pa-0 py-2">
                                      <v-file-input-plus
                                        :urlBase="urlBase"
                                        dense
                                        accept=".jpg,.jpeg,.png,.pdf"
                                        :max-size="5242880"
                                        outlined
                                        label="Copia de la certificación, licencia o permiso"
                                        v-model="editedItem.certificacion"
                                      ></v-file-input-plus>
                                      <div class="text-caption note-style">Nota: Asegúrate que el documento esté vigente.</div>
                                    </v-col>
                                    <v-col cols="12" class="ma-0 pa-0 py-2">
                                      <v-file-input-plus
                                        :urlBase="urlBase"
                                        dense
                                        accept=".jpg,.jpeg,.png,.pdf"
                                        :max-size="5242880"
                                        outlined
                                        label="Copia simple de comprobante domicilio"
                                        v-model="editedItem.comprobante_domicilio"
                                      ></v-file-input-plus>
                                      <div class="text-caption note-style">Nota: No mayor a 3 meses.</div>
                                    </v-col>
                                    <v-col cols="12" class="ma-0 pa-0 py-2">
                                      <v-file-input-plus
                                        :urlBase="urlBase"
                                        dense
                                        accept=".jpg,.jpeg,.png,.pdf"
                                        :max-size="5242880"
                                        outlined
                                        label="Carta de referencia comercial 1"
                                        v-model="editedItem.referencia_comercial_1"
                                      ></v-file-input-plus>
                                      <div class="text-caption note-style">Nota: En papel membretado (no mayor a 3 meses).</div>
                                    </v-col>
                                    <v-col cols="12" class="ma-0 pa-0 py-2">
                                      <v-file-input-plus
                                        :urlBase="urlBase"
                                        dense
                                        accept=".jpg,.jpeg,.png,.pdf"
                                        :max-size="5242880"
                                        outlined
                                        label="Carta de referencia comercial 2"
                                        v-model="editedItem.referencia_comercial_2"
                                      ></v-file-input-plus>
                                      <div class="text-caption note-style">Nota: En papel membretado (no mayor a 3 meses).</div>
                                    </v-col>

                                    <template
                                      v-if="editedItem.id_tipo_proveedor_nacional == 2"
                                    >
                                      <v-col cols="12" class="ma-0 pa-0 py-2">
                                        <v-file-input-plus
                                          :urlBase="urlBase"
                                          dense
                                          accept=".jpg,.jpeg,.png,.pdf"
                                          :max-size="5242880"
                                          outlined
                                          :label="`Póliza de responsabilidad civil medica`"
                                          v-model="editedItem.poliza_responsabilidad_civil_medica"
                                        ></v-file-input-plus>
                                      </v-col>
                                      <v-col cols="12" class="ma-0 pa-0 py-2">
                                        <v-file-input-plus
                                          :urlBase="urlBase"
                                          dense
                                          accept=".jpg,.jpeg,.png,.pdf"
                                          :max-size="5242880"
                                          outlined
                                          label="Copia de título de Medico"
                                          v-model="editedItem.copia_titulo_medico"
                                        ></v-file-input-plus>
                                      </v-col>
                                      <v-col cols="12" class="ma-0 pa-0 py-2">
                                        <v-file-input-plus
                                          :urlBase="urlBase"
                                          dense
                                          accept=".jpg,.jpeg,.png,.pdf"
                                          :max-size="5242880"
                                          outlined
                                          label="Copia de Cedula profesional como Medico"
                                          v-model="editedItem.cedula_profesional_medico"
                                        ></v-file-input-plus>
                                      </v-col>
                                      <v-col cols="12" class="ma-0 pa-0 py-2">
                                        <v-file-input-plus
                                          :urlBase="urlBase"
                                          dense
                                          accept=".jpg,.jpeg,.png,.pdf"
                                          :max-size="5242880"
                                          outlined
                                          label="Copia del diploma e especialidad"
                                          v-model="editedItem.diploma_especialidad"
                                        ></v-file-input-plus>
                                      </v-col>
                                      <v-col cols="12" class="ma-0 pa-0 py-2">
                                        <v-file-input-plus
                                          :urlBase="urlBase"
                                          dense
                                          accept=".jpg,.jpeg,.png,.pdf"
                                          :max-size="5242880"
                                          outlined
                                          label="Copia de la cedula de radiología"
                                          v-model="editedItem.cedula_radiologia"
                                        ></v-file-input-plus>
                                      </v-col>
                                    </template>
                                  </v-row>
                                </v-col>
                              </template>

                              <!-- Documentos para Proveedor Internacional / Persona Física -->
                              <template v-else-if="editedItem.id_tipo_proveedor == '4ae22cb8-3564-41e9-a726-302cb6762e02' && editedItem.id_tipo_persona == 'abddb0b2-32b4-47d4-8a75-7ac30e95c69b'">
                                <v-col cols="6">
                                  <v-row class="ma-0 pa-0">
                                    <v-col cols="12" class="ma-0 pa-0 py-2">
                                      <v-file-input-plus
                                        :urlBase="urlBase"
                                        dense
                                        accept=".jpg,.jpeg,.png,.pdf"
                                        :max-size="5242880"
                                        outlined
                                        label="Cédula de identificación fiscal"
                                        v-model="editedItem.cedula_identificacion_fiscal"
                                      ></v-file-input-plus>
                                    </v-col>
                                    <v-col cols="12" class="ma-0 pa-0 py-2">
                                      <v-file-input-plus
                                        :urlBase="urlBase"
                                        dense
                                        accept=".jpg,.jpeg,.png,.pdf"
                                        :max-size="5242880"
                                        outlined
                                        label="Copia de la carátula del estado de cuenta"
                                        v-model="editedItem.caratula_estado_cuenta"
                                      ></v-file-input-plus>
                                      <div class="text-caption note-style">
                                        En la portada, se debe incluir la siguiente información: nombre del
                                        titular de la cuenta, número de cuenta, CLABE interbancaria, moneda,
                                        sucursal, plaza e institución bancaria, y/o contrato de apertura.
                                        Además, debe incluirse la leyenda al margen: "Ratifico ser el titular
                                        de esta cuenta y me responsabilizo de la información proporcionada",
                                        junto con la firma correspondiente.
                                      </div>
                                    </v-col>
                                  </v-row>
                                </v-col>
                                <v-col cols="6">
                                  <v-row class="ma-0 pa-0">
                                    <v-col cols="12" class="ma-0 pa-0 py-2">
                                      <v-file-input-plus
                                        :urlBase="urlBase"
                                        dense
                                        accept=".jpg,.jpeg,.png,.pdf"
                                        :max-size="5242880"
                                        outlined
                                        label="Identificación oficial con fotografía del país de origen"
                                        v-model="editedItem.identificacion_oficial"
                                      ></v-file-input-plus>
                                    </v-col>
                                    <v-col cols="12" class="ma-0 pa-0 py-2">
                                      <v-file-input-plus
                                        :urlBase="urlBase"
                                        dense
                                        accept=".jpg,.jpeg,.png,.pdf"
                                        :max-size="5242880"
                                        outlined
                                        label="Clave única de registro de población"
                                        v-model="editedItem.curp"
                                      ></v-file-input-plus>
                                      <div class="text-caption note-style">Nota: Si existe en el país de origen.</div>
                                    </v-col>
                                    <v-col cols="12" class="ma-0 pa-0 py-2">
                                      <v-file-input-plus
                                        :urlBase="urlBase"
                                        dense
                                        accept=".jpg,.jpeg,.png,.pdf"
                                        :max-size="5242880"
                                        outlined
                                        label="Solicitud de certificación del medio de identificación electrónica para personas físicas extranjeras"
                                        v-model="editedItem.solicitud_certificacion"
                                      ></v-file-input-plus>
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </template>

                              <!-- Documentos para Proveedor Internacional / Persona Moral -->
                              <template v-else-if="editedItem.id_tipo_proveedor == '4ae22cb8-3564-41e9-a726-302cb6762e02' && editedItem.id_tipo_persona == '689a6d20-3aff-4a09-b5b4-52105ad212f0'">
                                <v-col cols="6">
                                  <v-row class="ma-0 pa-0">
                                    <v-col cols="12" class="ma-0 pa-0 py-2">
                                      <v-file-input-plus
                                        :urlBase="urlBase"
                                        dense
                                        accept=".jpg,.jpeg,.png,.pdf"
                                        :max-size="5242880"
                                        outlined
                                        label="Cédula de identificación fiscal de la persona moral"
                                        v-model="editedItem.cedula_fiscal_moral"
                                      ></v-file-input-plus>
                                    </v-col>
                                    <v-col cols="12" class="ma-0 pa-0 py-2">
                                      <v-file-input-plus
                                        :urlBase="urlBase"
                                        dense
                                        accept=".jpg,.jpeg,.png,.pdf"
                                        :max-size="5242880"
                                        outlined
                                        label="Testimonio de la escritura pública"
                                        v-model="editedItem.testimonio_escritura"
                                      ></v-file-input-plus>
                                      <div class="text-caption note-style">
                                        Nota: Debe presentarse debidamente apostillada o legalizada y en el
                                        idioma español.
                                      </div>
                                    </v-col>
                                    <v-col cols="12" class="ma-0 pa-0 py-2">
                                      <v-file-input-plus
                                        :urlBase="urlBase"
                                        dense
                                        accept=".jpg,.jpeg,.png,.pdf"
                                        :max-size="5242880"
                                        outlined
                                        label="Copia de la carátula del estado de cuenta"
                                        v-model="editedItem.caratula_estado_cuenta"
                                      ></v-file-input-plus>
                                      <div class="text-caption note-style">
                                        En la portada, se debe incluir la siguiente información: nombre del
                                        titular de la cuenta, número de cuenta, CLABE interbancaria, moneda,
                                        sucursal, plaza e institución bancaria, y/o contrato de apertura.
                                        Además, debe incluirse la leyenda al margen: "Ratifico ser el titular
                                        de esta cuenta y me responsabilizo de la información proporcionada",
                                        junto con la firma correspondiente.
                                      </div>
                                    </v-col>
                                  </v-row>
                                </v-col>
                                <v-col cols="6">
                                  <v-row class="ma-0 pa-0">
                                    <v-col cols="12" class="ma-0 pa-0 py-2">
                                      <v-file-input-plus
                                        :urlBase="urlBase"
                                        dense
                                        accept=".jpg,.jpeg,.png,.pdf"
                                        :max-size="5242880"
                                        outlined
                                        label="Solicitud de certificación del medio de identificación electrónica para personas morales extranjeras"
                                        v-model="editedItem.solicitud_certificacion_morales"
                                      ></v-file-input-plus>
                                    </v-col>
                                    <v-col cols="12" class="ma-0 pa-0 py-2">
                                      <v-file-input-plus
                                        :urlBase="urlBase"
                                        dense
                                        accept=".jpg,.jpeg,.png,.pdf"
                                        :max-size="5242880"
                                        outlined
                                        label="Clave única de registro de población (CURP) del representante legal o apoderado"
                                        v-model="editedItem.curp_representante"
                                      ></v-file-input-plus>
                                    </v-col>
                                    <v-col cols="12" class="ma-0 pa-0 py-2">
                                      <v-file-input-plus
                                        :urlBase="urlBase"
                                        dense
                                        accept=".jpg,.jpeg,.png,.pdf"
                                        :max-size="5242880"
                                        outlined
                                        label="Identificación oficial con fotografía del representante legal"
                                        v-model="editedItem.identificacion_representante"
                                      ></v-file-input-plus>
                                      <div class="text-caption note-style">Nota: Ejemplo pasaporte vigente.</div>
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </template>
                            </v-row>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="red"
                      text
                      @click="saveDialogAddEdit"
                      :loading="loadingSave"
                      class="white--text"
                      >
                        Siguiente</v-btn
                    >
                  </v-card-actions>
                </v-stepper-content>

                <!-- Step 2 Content -->
                <v-stepper-content step="2">
                  <v-card-text>
                    <v-row>
                      <!-- Datos Fiscales Nacionales -->
                      <template v-if="editedItem.id_tipo_proveedor == '34a6560f-61c4-4bd0-a778-b7e641818fd0'">
                        <v-col cols="12" md="6">
                          <v-autocomplete
                            :items="regimenFiscales"
                            label="Régimen Fiscal *"
                            item-text="nombre"
                            item-value="id_regimen_fiscal"
                            dense
                            outlined
                            hide-details
                            v-model="editedItemFiscal.id_regimen_fiscal"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            label="Nombre/Razón Social *"
                            dense
                            outlined
                            hide-details
                            v-model="editedItemFiscal.razon_social"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            label="RFC *"
                            dense
                            outlined
                            hide-details
                            v-model="editedItemFiscal.rfc"
                            counter
                            :maxlength="(editedItem.id_tipo_persona == '689a6d20-3aff-4a09-b5b4-52105ad212f0') ? 12 : 13"
                            readonly
                            disabled
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            label="Calle *"
                            dense
                            outlined
                            hide-details
                            v-model="editedItemFiscal.calle"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            label="Núm Exterior *"
                            dense
                            outlined
                            hide-details
                            v-model="editedItemFiscal.num_ext"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            label="Núm Interior"
                            dense
                            outlined
                            hide-details
                            v-model="editedItemFiscal.num_int"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-autocomplete
                            :items="estados"
                            label="Estado *"
                            item-value="id_estado"
                            item-text="nombre"
                            dense
                            outlined
                            hide-details
                            @change="getMunicipios"
                            v-model="editedItemFiscal.id_estado"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-autocomplete
                            :items="municipios"
                            item-value="id_municipio"
                            item-text="nombre"
                            label="Municipio *"
                            dense
                            outlined
                            hide-details
                            :loading="loadingMunicipios"
                            v-model="editedItemFiscal.id_municipio"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            label="Colonia *"
                            dense
                            outlined
                            hide-details
                            v-model="editedItemFiscal.colonia"
                          ></v-text-field>
                        </v-col>    
                        <v-col cols="12" md="6">
                          <v-text-field
                            label="Código Postal *"
                            dense
                            outlined
                            hide-details
                            v-model="editedItemFiscal.codigo_postal"
                          ></v-text-field>
                        </v-col>
                      </template>

                      <!-- Datos Fiscales Internacionales -->
                      <template v-if="editedItem.id_tipo_proveedor == '4ae22cb8-3564-41e9-a726-302cb6762e02'">
                        <v-col cols="12" md="6">
                          <v-text-field
                            label="Nombre/Razón Social *"
                            dense
                            outlined
                            hide-details
                            v-model="editedItemFiscal.razon_social"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            label="Número de Identificación Fiscal *"
                            dense
                            outlined
                            hide-details
                            v-model="editedItemFiscal.numero_fiscal"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            label="Calle *"
                            dense
                            outlined
                            hide-details
                            v-model="editedItemFiscal.calle"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            label="Núm Exterior *"
                            dense
                            outlined
                            hide-details
                            v-model="editedItemFiscal.num_ext"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            label="Núm Interior"
                            dense
                            outlined
                            hide-details
                            v-model="editedItemFiscal.num_int"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            label="Código Postal *"
                            dense
                            outlined
                            hide-details
                            v-model="editedItemFiscal.codigo_postal"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            label="Ciudad *"
                            dense
                            outlined
                            hide-details
                            v-model="editedItemFiscal.ciudad"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            label="Región/Estado/Provincia *"
                            dense
                            outlined
                            hide-details
                            v-model="editedItemFiscal.region"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-autocomplete
                            :items="paises"
                            label="País *"
                            item-value="id_pais"
                            item-text="nombre"
                            dense
                            outlined
                            hide-details
                            v-model="editedItemFiscal.id_pais"
                          ></v-autocomplete>
                        </v-col>
                      </template>

                      <v-col cols="12" md="6">
                        <v-text-field
                          label="Teléfono Empresarial/Sucursal *"
                          dense
                          outlined
                          hide-details
                          v-model="editedItemFiscal.telefono"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green" text @click="step = 1"
                      >Anterior</v-btn
                    >
                    <v-btn
                      color="red"
                      text
                      @click="saveDialogDatosFiscales"
                      :loading="loadingSaveDatosFiscales"
                      class="white--text"
                      >
                        Siguiente
                      </v-btn
                    >
                  </v-card-actions>
                </v-stepper-content>

                <!-- Step 3 Content -->
                <v-stepper-content step="3">
                  <v-card-text>
                    <v-expansion-panels v-model="panel" accordion mandatory>
                      <v-expansion-panel>
                        <v-expansion-panel-header class="font-weight-medium text-center">
                          <v-spacer/>
                          <v-img src="/img/mexico.png" aspect-ratio="1.0" max-width="48px"></v-img>
                          &nbsp;&nbsp;
                          Cuentas Bancarias Nacionales
                          <v-spacer/>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <nacionales
                            ref="nacionales"
                            :id_proveedor="editedItem.id_proveedor"
                          ></nacionales>
                        </v-expansion-panel-content>
                      </v-expansion-panel>

                      <v-expansion-panel>
                        <v-expansion-panel-header class="font-weight-medium text-center">
                          <v-spacer/>
                          <v-img src="/img/planeta.png" aspect-ratio="1.0" max-width="48px"></v-img>
                          &nbsp;&nbsp;
                          Cuentas Bancarias Internacionales
                          <v-spacer/>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <internacionales
                            ref="internacionales"
                            :id_proveedor="editedItem.id_proveedor"
                          ></internacionales>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green" text @click="step = 2"
                      >Anterior</v-btn
                    >
                    <v-btn color="red" text @click="closeDialogAddEdit"
                      >Finalizar</v-btn
                    >
                  </v-card-actions>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-card-text>

        </v-card>
      </v-dialog>

      <v-dialog
        v-model="dialogView" max-width="90%"
      >
        <v-card>
          <v-card-title>
            Información del Proveedor
          </v-card-title>
          <v-card-text>
            <v-alert
              v-if="editedItem.motivo_bloqueo != null && editedItem.motivo_bloqueo.length > 0"
              type="error"
              color="red"
              dark
              class="text-center"
            >
              {{editedItem.motivo_bloqueo}} - {{editedItem.usuario_bloqueo}} desde {{editedItem.bloqueado_desde}}.
            </v-alert>
            <v-row>
              <v-col cols="12" class="text-center">
                <h3>Información Básica</h3>
              </v-col>

              <v-col cols="12" md="6">
                <div><strong>Tipo de Proveedor:</strong> {{ editedItem.tipo_proveedor }}</div>
              </v-col>
              <v-col cols="12" md="6">
                <div><strong>Tipo de Persona:</strong> {{ editedItem.tipo_persona }}</div>
              </v-col>

              <template v-if="editedItem.id_tipo_proveedor == '34a6560f-61c4-4bd0-a778-b7e641818fd0'">
                <v-col cols="12" md="6">
                  <div><strong>Tipo de Proveedor Nacional:</strong> {{ getTipoProveedorNacional(editedItem.id_tipo_proveedor_nacional) }}</div>
                </v-col>
              </template>

              <v-col cols="12" md="6">
                <div><strong>Nombre Corto del Proveedor:</strong> {{ editedItem.nombre_corto }}</div>
              </v-col>

              <v-col cols="12" md="6">
                <div><strong>Nombre Completo del Proveedor:</strong> {{ editedItem.nombre_completo }}</div>
              </v-col>

              <v-col cols="12" md="6">
                <div><strong>Nombre del Contacto:</strong> {{ editedItem.nombre_contacto }}</div>
              </v-col>

              <v-col cols="12" md="6">
                <div><strong>Teléfono de Contacto:</strong> {{ editedItem.telefono_contacto }}</div>
              </v-col>

              <v-col cols="12" md="6">
                <div><strong>Correo Electrónico:</strong> {{ editedItem.correo_electronico }}</div>
              </v-col>
            </v-row>

            <v-row justify="center">
              <v-expansion-panels
                v-model="panelsView"
                accordion
                multiple
              >
                <!-- Datos Geográficos -->
                <v-expansion-panel>
                  <v-expansion-panel-header class="text-center grey--text text--darken-2">
                    <h4>Datos Geográficos</h4>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row>
                      <v-col cols="12">
                        <div><strong>URL de la ubicación en Google Maps:</strong> {{ editedItem.direccion_google_maps }}</div>
                      </v-col>
                      <v-col cols="6">
                        <v-viewer
                          v-model="editedItem.fachada_empresa_1"
                          urlBase="/api/Proveedores/DescargarDocumento"
                          label="Fotografía de la fachada de la empresa 1"
                        ></v-viewer>
                      </v-col>
                      <v-col cols="6">
                        <v-viewer
                          v-model="editedItem.fachada_empresa_2"
                          urlBase="/api/Proveedores/DescargarDocumento"
                          label="Fotografía de la fachada de la empresa 2"
                        ></v-viewer>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <!-- Datos de la Sucursal -->
                <v-expansion-panel
                  v-if="
                    editedItem.calle_sucursal != null ||
                    editedItem.num_ext_sucursal != null ||
                    editedItem.num_int_sucursal != null ||
                    editedItem.estado_nombre != null ||
                    editedItem.municipio_nombre != null ||
                    editedItem.colonia_sucursal != null ||
                    editedItem.codigo_postal_sucursal != null ||
                    editedItem.ciudad_sucursal != null ||
                    editedItem.region_sucursal != null ||
                    editedItem.pais_nombre != null
                  "
                >
                  <v-expansion-panel-header class="text-center grey--text text--darken-2">
                    <div>
                      <h4>Datos de la Sucursal</h4>
                      <br>
                      (Si aplica)
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row>
                      <template v-if="editedItem.id_tipo_proveedor == '34a6560f-61c4-4bd0-a778-b7e641818fd0'">
                        <v-col cols="6">
                          <div><strong>Calle:</strong> {{ editedItem.calle_sucursal }}</div>
                        </v-col>
                        <v-col cols="3">
                          <div><strong>Núm Exterior:</strong> {{ editedItem.num_ext_sucursal }}</div>
                        </v-col>
                        <v-col cols="3">
                          <div><strong>Núm Interior:</strong> {{ editedItem.num_int_sucursal }}</div>
                        </v-col>
                        <v-col cols="6">
                          <div><strong>Estado:</strong> {{ editedItem.estado_sucursal }}</div>
                        </v-col>
                        <v-col cols="6">
                          <div><strong>Municipio:</strong> {{ editedItem.municipio_sucursal }}</div>
                        </v-col>
                        <v-col cols="6">
                          <div><strong>Colonia:</strong> {{ editedItem.colonia_sucursal }}</div>
                        </v-col>
                        <v-col cols="6">
                          <div><strong>Código Postal:</strong> {{ editedItem.codigo_postal_sucursal }}</div>
                        </v-col>
                      </template>
                      <template v-if="editedItem.id_tipo_proveedor == '4ae22cb8-3564-41e9-a726-302cb6762e02'">
                        <v-col cols="6">
                          <div><strong>Calle:</strong> {{ editedItem.calle_sucursal }}</div>
                        </v-col>
                        <v-col cols="3">
                          <div><strong>Núm Exterior:</strong> {{ editedItem.num_ext_sucursal }}</div>
                        </v-col>
                        <v-col cols="3">
                          <div><strong>Núm Interior:</strong> {{ editedItem.num_int_sucursal }}</div>
                        </v-col>
                        <v-col cols="6">
                          <div><strong>Código Postal:</strong> {{ editedItem.codigo_postal_sucursal }}</div>
                        </v-col>
                        <v-col cols="6">
                          <div><strong>Ciudad:</strong> {{ editedItem.ciudad_sucursal }}</div>
                        </v-col>
                        <v-col cols="6">
                          <div><strong>Región/Estado/Provincia:</strong> {{ editedItem.region_sucursal }}</div>
                        </v-col>
                        <v-col cols="6">
                          <div><strong>País:</strong> {{ editedItem.pais_nombre }}</div>
                        </v-col>
                      </template>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel
                  v-if="editedItem.datos_fiscales != null && (
                    editedItem.datos_fiscales.nombre_regimen_fiscal ||
                    editedItem.datos_fiscales.razon_social ||
                    editedItem.datos_fiscales.rfc ||
                    editedItem.datos_fiscales.calle ||
                    editedItem.datos_fiscales.num_ext ||
                    editedItem.datos_fiscales.num_int ||
                    editedItem.datos_fiscales.nombre_estado ||
                    editedItem.datos_fiscales.nombre_municipio ||
                    editedItem.datos_fiscales.colonia ||
                    editedItem.datos_fiscales.codigo_postal ||
                    editedItem.datos_fiscales.numero_fiscal ||
                    editedItem.datos_fiscales.ciudad ||
                    editedItem.datos_fiscales.region ||
                    editedItem.datos_fiscales.nombre_pais
                  )"
                >
                  <v-expansion-panel-header class="text-center grey--text text--darken-2">
                    <h4>Datos Fiscales</h4>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row>
                      <!-- Datos Fiscales Nacionales -->
                      <template v-if="editedItem.id_tipo_proveedor == '34a6560f-61c4-4bd0-a778-b7e641818fd0'">
                        <v-col cols="6">
                          <div><strong>Régimen Fiscal:</strong> {{ editedItem.datos_fiscales.nombre_regimen_fiscal }}</div>
                        </v-col>
                        <v-col cols="6">
                          <div><strong>Nombre/Razón Social:</strong> {{ editedItem.datos_fiscales.razon_social }}</div>
                        </v-col>
                        <v-col cols="6">
                          <div :class="(editedItem.motivo_bloqueo != null && editedItem.motivo_bloqueo.length > 0) ? 'red--text' : ''"><strong>RFC:</strong> {{ editedItem.datos_fiscales.rfc }}</div>
                        </v-col>
                        <v-col cols="6">
                          <div><strong>Calle:</strong> {{ editedItem.datos_fiscales.calle }}</div>
                        </v-col>
                        <v-col cols="3">
                          <div><strong>Núm Exterior:</strong> {{ editedItem.datos_fiscales.num_ext }}</div>
                        </v-col>
                        <v-col cols="3">
                          <div><strong>Núm Interior:</strong> {{ editedItem.datos_fiscales.num_int }}</div>
                        </v-col>
                        <v-col cols="6">
                          <div><strong>Estado:</strong> {{ editedItem.datos_fiscales.nombre_estado }}</div>
                        </v-col>
                        <v-col cols="6">
                          <div><strong>Municipio:</strong> {{ editedItem.datos_fiscales.nombre_municipio }}</div>
                        </v-col>
                        <v-col cols="6">
                          <div><strong>Colonia:</strong> {{ editedItem.datos_fiscales.colonia }}</div>
                        </v-col>
                        <v-col cols="6">
                          <div><strong>Código Postal:</strong> {{ editedItem.datos_fiscales.codigo_postal }}</div>
                        </v-col>
                      </template>

                      <!-- Datos Fiscales Internacionales -->
                      <template v-if="editedItem.id_tipo_proveedor == '4ae22cb8-3564-41e9-a726-302cb6762e02'">
                        <v-col cols="6">
                          <div><strong>Nombre/Razón Social:</strong> {{ editedItem.datos_fiscales.razon_social }}</div>
                        </v-col>
                        <v-col cols="6">
                          <div><strong>Número de Identificación Fiscal:</strong> {{ editedItem.datos_fiscales.numero_fiscal }}</div>
                        </v-col>
                        <v-col cols="6">
                          <div><strong>Calle:</strong> {{ editedItem.datos_fiscales.calle }}</div>
                        </v-col>
                        <v-col cols="3">
                          <div><strong>Núm Exterior:</strong> {{ editedItem.datos_fiscales.num_ext }}</div>
                        </v-col>
                        <v-col cols="3">
                          <div><strong>Núm Interior:</strong> {{ editedItem.datos_fiscales.num_int }}</div>
                        </v-col>
                        <v-col cols="6">
                          <div><strong>Código Postal:</strong> {{ editedItem.datos_fiscales.codigo_postal }}</div>
                        </v-col>
                        <v-col cols="6">
                          <div><strong>Ciudad:</strong> {{ editedItem.datos_fiscales.ciudad }}</div>
                        </v-col>
                        <v-col cols="6">
                          <div><strong>Región/Estado/Provincia:</strong> {{ editedItem.datos_fiscales.region }}</div>
                        </v-col>
                        <v-col cols="6">
                          <div><strong>País:</strong> {{ editedItem.datos_fiscales.nombre_pais }}</div>
                        </v-col>
                      </template>

                      <!-- Teléfono -->
                      <v-col cols="6">
                        <div><strong>Teléfono Empresarial/Sucursal:</strong> {{ editedItem.datos_fiscales.telefono }}</div>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header class="text-center grey--text text--darken-2">
                    <h4>Cuentas Bancarias</h4>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div class="text-center d-flex text-subtitle-2" style="align-items: center !important;">
                      <v-spacer/>
                      <v-img src="/img/mexico.png" aspect-ratio="1.0" max-width="48px"></v-img>
                      &nbsp;&nbsp;
                      Cuentas Bancarias Nacionales
                      <v-spacer/>                        
                    </div>
                    <nacionales
                      :enableAddUpdate="false"
                      ref="nacionales_view"
                      :id_proveedor="editedItem.id_proveedor"
                    ></nacionales>
                    <div class="text-center d-flex text-subtitle-2" style="align-items: center !important;">
                      <v-spacer/>
                      <v-img src="/img/planeta.png" aspect-ratio="1.0" max-width="48px"></v-img>
                      &nbsp;&nbsp;
                      Cuentas Bancarias Internacionales
                      <v-spacer/>                        
                    </div>
                    <internacionales
                      :enableAddUpdate="false"
                      ref="internacionales_view"
                      :id_proveedor="editedItem.id_proveedor"
                    ></internacionales>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <!-- Documentos Adjuntos -->
                <v-expansion-panel
                  v-if="editedItem.curriculum_vitae_empresarial ||
                    editedItem.situacion_fiscal ||
                    editedItem.opinion_cumplimiento ||
                    editedItem.caratula_estado_cuenta ||
                    editedItem.certificado_1 ||
                    editedItem.certificado_2 ||
                    editedItem.certificado_3 ||
                    editedItem.identificacion_oficial ||
                    editedItem.comprobante_domicilio ||
                    editedItem.referencia_comercial_1 ||
                    editedItem.referencia_comercial_2 ||
                    editedItem.certificacion ||
                    editedItem.fotografia_producto_1 ||
                    editedItem.fotografia_producto_2 ||
                    editedItem.fotografia_producto_3 ||
                    editedItem.fotografia_producto_4 ||
                    editedItem.curriculum_empresa ||
                    editedItem.acta_constitutiva ||
                    editedItem.poder_representante ||
                    editedItem.identificacion_representante ||
                    editedItem.cedula_identificacion_fiscal ||
                    editedItem.curp ||
                    editedItem.solicitud_certificacion ||
                    editedItem.cedula_fiscal_moral ||
                    editedItem.testimonio_escritura ||
                    editedItem.solicitud_certificacion_morales ||
                    editedItem.curp_representante ||
                    editedItem.poliza_responsabilidad_civil_medica ||
                    editedItem.copia_titulo_medico ||
                    editedItem.cedula_profesional_medico ||
                    editedItem.diploma_especialidad ||
                    editedItem.cedula_radiologia"

                >
                  <v-expansion-panel-header class="text-center grey--text text--darken-2">
                    <h4>Documentos Adjuntos</h4>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-list dense>
                      <!-- Documentos para Proveedor Nacional / Persona Física -->
                      <template v-if="editedItem.id_tipo_proveedor === '34a6560f-61c4-4bd0-a778-b7e641818fd0' && editedItem.id_tipo_persona === 'abddb0b2-32b4-47d4-8a75-7ac30e95c69b'">
                        <v-list-item v-if="editedItem.curriculum_vitae_empresarial" @click="downloadFile(editedItem.curriculum_vitae_empresarial)">
                          <v-list-item-content><strong>Currículum Vitae empresarial</strong></v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="editedItem.situacion_fiscal" @click="downloadFile(editedItem.situacion_fiscal)">
                          <v-list-item-content><strong>Constancia de situación fiscal</strong></v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="editedItem.opinion_cumplimiento" @click="downloadFile(editedItem.opinion_cumplimiento)">
                          <v-list-item-content><strong>Opinión de cumplimiento positiva de obligaciones fiscales</strong></v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="editedItem.caratula_estado_cuenta" @click="downloadFile(editedItem.caratula_estado_cuenta)">
                          <v-list-item-content><strong>Copia de la carátula del estado de cuenta</strong></v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="editedItem.certificado_1" @click="downloadFile(editedItem.certificado_1)">
                          <v-list-item-content><strong>Certificado 1</strong></v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="editedItem.certificado_2" @click="downloadFile(editedItem.certificado_2)">
                          <v-list-item-content><strong>Certificado 2</strong></v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="editedItem.certificado_3" @click="downloadFile(editedItem.certificado_3)">
                          <v-list-item-content><strong>Certificado 3</strong></v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="editedItem.identificacion_oficial" @click="downloadFile(editedItem.identificacion_oficial)">
                          <v-list-item-content><strong>Copia simple de identificación oficial</strong></v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="editedItem.comprobante_domicilio" @click="downloadFile(editedItem.comprobante_domicilio)">
                          <v-list-item-content><strong>Copia simple de comprobante domicilio</strong></v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="editedItem.referencia_comercial_1" @click="downloadFile(editedItem.referencia_comercial_1)">
                          <v-list-item-content><strong>Carta de referencia comercial 1</strong></v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="editedItem.referencia_comercial_2" @click="downloadFile(editedItem.referencia_comercial_2)">
                          <v-list-item-content><strong>Carta de referencia comercial 2</strong></v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="editedItem.certificacion" @click="downloadFile(editedItem.certificacion)">
                          <v-list-item-content><strong>Copia de la certificación, licencia o permiso</strong></v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="editedItem.fotografia_producto_1" @click="downloadFile(editedItem.fotografia_producto_1)">
                          <v-list-item-content><strong>Fotografía del producto o servicio 1</strong></v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="editedItem.fotografia_producto_2" @click="downloadFile(editedItem.fotografia_producto_2)">
                          <v-list-item-content><strong>Fotografía del producto o servicio 2</strong></v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="editedItem.fotografia_producto_3" @click="downloadFile(editedItem.fotografia_producto_3)">
                          <v-list-item-content><strong>Fotografía del producto o servicio 3</strong></v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="editedItem.fotografia_producto_4" @click="downloadFile(editedItem.fotografia_producto_4)">
                          <v-list-item-content><strong>Fotografía del producto o servicio 4</strong></v-list-item-content>
                        </v-list-item>
                        <template v-if="editedItem.id_tipo_proveedor_nacional == 2">
                          <v-list-item v-if="editedItem.poliza_responsabilidad_civil_medica" @click="downloadFile(editedItem.poliza_responsabilidad_civil_medica)">
                            <v-list-item-content><strong>Póliza de responsabilidad civil medica</strong></v-list-item-content>
                          </v-list-item>
                          <v-list-item v-if="editedItem.copia_titulo_medico" @click="downloadFile(editedItem.copia_titulo_medico)">
                            <v-list-item-content><strong>Copia de título de Medico</strong></v-list-item-content>
                          </v-list-item>
                          <v-list-item v-if="editedItem.cedula_profesional_medico" @click="downloadFile(editedItem.cedula_profesional_medico)">
                            <v-list-item-content><strong>Copia de Cedula profesional como Medico</strong></v-list-item-content>
                          </v-list-item>
                          <v-list-item v-if="editedItem.diploma_especialidad" @click="downloadFile(editedItem.diploma_especialidad)">
                            <v-list-item-content><strong>Copia del diploma e especialidad</strong></v-list-item-content>
                          </v-list-item>
                          <v-list-item v-if="editedItem.cedula_radiologia" @click="downloadFile(editedItem.cedula_radiologia)">
                            <v-list-item-content><strong>Copia de la cedula de radiología</strong></v-list-item-content>
                          </v-list-item>
                        </template>
                      </template>

                      <!-- Documentos para Proveedor Nacional / Persona Moral -->
                      <template v-else-if="editedItem.id_tipo_proveedor === '34a6560f-61c4-4bd0-a778-b7e641818fd0' && editedItem.id_tipo_persona === '689a6d20-3aff-4a09-b5b4-52105ad212f0'">
                        <v-list-item v-if="editedItem.curriculum_empresa">
                          <v-list-item-content class="text-center">
                            <v-viewer
                              v-model="editedItem.curriculum_empresa"
                              urlBase="/api/Proveedores/DescargarDocumento"
                              label="Currículum de la empresa"
                            ></v-viewer>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="editedItem.acta_constitutiva">
                          <v-list-item-content>
                              <v-viewer
                                v-model="editedItem.acta_constitutiva"
                                urlBase="/api/Proveedores/DescargarDocumento"
                                label="Copia simple del acta constitutiva y actas modificatorias"
                              ></v-viewer>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="editedItem.poder_representante">
                          <v-list-item-content>
                            <v-viewer
                              v-model="editedItem.poder_representante"
                              urlBase="/api/Proveedores/DescargarDocumento"
                              label="Copia simple del poder notariado del representante legal"
                            ></v-viewer>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="editedItem.caratula_estado_cuenta">
                          <v-list-item-content>
                            <v-viewer
                              v-model="editedItem.caratula_estado_cuenta"
                              urlBase="/api/Proveedores/DescargarDocumento"
                              label="Copia de la carátula del estado de cuenta"
                            ></v-viewer>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="editedItem.situacion_fiscal">
                          <v-list-item-content>
                            <v-viewer
                              v-model="editedItem.situacion_fiscal"
                              urlBase="/api/Proveedores/DescargarDocumento"
                              label="Constancia de situación fiscal"
                            ></v-viewer>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="editedItem.opinion_cumplimiento">
                          <v-list-item-content>
                            <v-viewer
                              v-model="editedItem.opinion_cumplimiento"
                              urlBase="/api/Proveedores/DescargarDocumento"
                              label="Opinión de cumplimiento positiva de obligaciones fiscales"
                            ></v-viewer>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="editedItem.certificado_1">
                          <v-list-item-content>
                            <v-viewer
                              v-model="editedItem.certificado_1"
                              urlBase="/api/Proveedores/DescargarDocumento"
                              label="Certificado 1"
                            ></v-viewer>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="editedItem.certificado_2">
                          <v-list-item-content>
                            <v-viewer
                              v-model="editedItem.certificado_2"
                              urlBase="/api/Proveedores/DescargarDocumento"
                              label="Certificado 2"
                            ></v-viewer>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="editedItem.certificado_3">
                          <v-list-item-content>
                            <v-viewer
                              v-model="editedItem.certificado_3"
                              urlBase="/api/Proveedores/DescargarDocumento"
                              label="Certificado 3"
                            ></v-viewer>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="editedItem.identificacion_representante">
                          <v-list-item-content>
                            <v-viewer
                              v-model="editedItem.identificacion_representante"
                              urlBase="/api/Proveedores/DescargarDocumento"
                              label="Copia simple de identificación oficial del representante legal"
                            ></v-viewer>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item v-if="editedItem.certificacion">
                          <v-list-item-content>
                            <v-viewer
                              v-model="editedItem.certificacion"
                              urlBase="/api/Proveedores/DescargarDocumento"
                              label="Copia de la certificación, licencia o permiso"
                            ></v-viewer>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item v-if="editedItem.comprobante_domicilio">
                          <v-list-item-content>
                            <v-viewer
                              v-model="editedItem.comprobante_domicilio"
                              urlBase="/api/Proveedores/DescargarDocumento"
                              label="Copia simple de comprobante domicilio"
                            ></v-viewer>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item v-if="editedItem.referencia_comercial_1">
                          <v-list-item-content>
                            <v-viewer
                              v-model="editedItem.referencia_comercial_1"
                              urlBase="/api/Proveedores/DescargarDocumento"
                              label="Carta de referencia comercial 1"
                            ></v-viewer>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item v-if="editedItem.referencia_comercial_2">
                          <v-list-item-content>
                            <v-viewer
                              v-model="editedItem.referencia_comercial_2"
                              urlBase="/api/Proveedores/DescargarDocumento"
                              label="Carta de referencia comercial 2"
                            ></v-viewer>
                          </v-list-item-content>
                        </v-list-item>

                        <template v-if="editedItem.id_tipo_proveedor_nacional == 2">
                          <v-list-item v-if="editedItem.poliza_responsabilidad_civil_medica" @click="downloadFile(editedItem.poliza_responsabilidad_civil_medica)">
                            <v-list-item-content><strong>Póliza de responsabilidad civil medica</strong></v-list-item-content>
                          </v-list-item>
                          <v-list-item v-if="editedItem.copia_titulo_medico" @click="downloadFile(editedItem.copia_titulo_medico)">
                            <v-list-item-content><strong>Copia de título de Medico</strong></v-list-item-content>
                          </v-list-item>
                          <v-list-item v-if="editedItem.cedula_profesional_medico" @click="downloadFile(editedItem.cedula_profesional_medico)">
                            <v-list-item-content><strong>Copia de Cedula profesional como Medico</strong></v-list-item-content>
                          </v-list-item>
                          <v-list-item v-if="editedItem.diploma_especialidad" @click="downloadFile(editedItem.diploma_especialidad)">
                            <v-list-item-content><strong>Copia del diploma e especialidad</strong></v-list-item-content>
                          </v-list-item>
                          <v-list-item v-if="editedItem.cedula_radiologia" @click="downloadFile(editedItem.cedula_radiologia)">
                            <v-list-item-content><strong>Copia de la cedula de radiología</strong></v-list-item-content>
                          </v-list-item>
                        </template>
                      </template>

                      <!-- Documentos para Proveedor Internacional / Persona Física -->
                      <template v-else-if="editedItem.id_tipo_proveedor === '4ae22cb8-3564-41e9-a726-302cb6762e02' && editedItem.id_tipo_persona === 'abddb0b2-32b4-47d4-8a75-7ac30e95c69b'">
                        <v-list-item v-if="editedItem.cedula_identificacion_fiscal">
                          <v-list-item-content>
                            <v-viewer
                              v-model="editedItem.cedula_identificacion_fiscal"
                              urlBase="/api/Proveedores/DescargarDocumento"
                              label="Cédula de identificación fiscal"
                            ></v-viewer>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="editedItem.caratula_estado_cuenta">
                          <v-list-item-content>
                            <v-viewer
                              v-model="editedItem.caratula_estado_cuenta"
                              urlBase="/api/Proveedores/DescargarDocumento"
                              label="Copia de la carátula del estado de cuenta"
                            ></v-viewer>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="editedItem.identificacion_oficial">
                          <v-list-item-content>
                            <v-viewer
                              v-model="editedItem.identificacion_oficial"
                              urlBase="/api/Proveedores/DescargarDocumento"
                              label="Identificación oficial con fotografía del país de origen"
                            ></v-viewer>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="editedItem.curp">
                          <v-list-item-content>
                            <v-viewer
                              v-model="editedItem.curp"
                              urlBase="/api/Proveedores/DescargarDocumento"
                              label="Clave única de registro de población"
                            ></v-viewer>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="editedItem.solicitud_certificacion">
                          <v-list-item-content>
                            <v-viewer
                              v-model="editedItem.solicitud_certificacion"
                              urlBase="/api/Proveedores/DescargarDocumento"
                              label="Solicitud de certificación del medio de identificación electrónica para personas físicas extranjeras"
                            ></v-viewer>
                          </v-list-item-content>
                        </v-list-item>
                      </template>

                      <!-- Documentos para Proveedor Internacional / Persona Moral -->
                      <template v-else-if="editedItem.id_tipo_proveedor === '4ae22cb8-3564-41e9-a726-302cb6762e02' && editedItem.id_tipo_persona === '689a6d20-3aff-4a09-b5b4-52105ad212f0'">
                        <v-list-item v-if="editedItem.cedula_fiscal_moral">
                          <v-list-item-content>
                            <v-viewer
                              v-model="editedItem.cedula_fiscal_moral"
                              urlBase="/api/Proveedores/DescargarDocumento"
                              label="Cédula de identificación fiscal de la persona moral"
                            ></v-viewer>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item v-if="editedItem.testimonio_escritura">
                          <v-list-item-content>
                            <v-viewer
                              v-model="editedItem.testimonio_escritura"
                              urlBase="/api/Proveedores/DescargarDocumento"
                              label="Testimonio de la escritura pública"
                            ></v-viewer>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item v-if="editedItem.caratula_estado_cuenta">
                          <v-list-item-content>
                            <v-viewer
                              v-model="editedItem.caratula_estado_cuenta"
                              urlBase="/api/Proveedores/DescargarDocumento"
                              label="Copia de la carátula del estado de cuenta"
                            ></v-viewer>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item v-if="editedItem.solicitud_certificacion_morales">
                          <v-list-item-content>
                            <v-viewer
                              v-model="editedItem.solicitud_certificacion_morales"
                              urlBase="/api/Proveedores/DescargarDocumento"
                              label="Solicitud de certificación del medio de identificación electrónica para personas morales extranjeras"
                            ></v-viewer>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item v-if="editedItem.curp_representante">
                          <v-list-item-content>
                            <v-viewer
                              v-model="editedItem.curp_representante"
                              urlBase="/api/Proveedores/DescargarDocumento"
                              label="Clave única de registro de población (CURP) del representante legal o apoderado"
                            ></v-viewer>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item v-if="editedItem.identificacion_representante">
                          <v-list-item-content>
                            <v-viewer
                              v-model="editedItem.identificacion_representante"
                              urlBase="/api/Proveedores/DescargarDocumento"
                              label="Identificación oficial con fotografía del representante legal"
                            ></v-viewer>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="closeDialogView"
              color="green"
              text
            >
              Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      
      <v-dialog
        v-model="dialogLock"
        persistent
        max-width="65%"
      >
        <v-card>
          <v-card-title>
            Bloquear Proveedor
          </v-card-title>
          <v-card-text>
            ¿Desea bloquear el Proveedor {{editedItem.nombre_completo}} con Clave {{editedItem.clave}}?
            <br>
            <br>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="motivo_bloqueo"
                  outlined
                  dense
                  hide-details
                  label="Motivo de Bloqueo"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="green"
              class="white--text"
              @click="closeDialogLock"
            >
              Cancelar
            </v-btn>
            <v-btn
              text
              color="red"
              class="white--text"
              @click="saveDialogLock"
              :loading="loadingLock"
            >
              Bloquear Proveedor
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-row>
        <v-col cols="12">
          <v-btn color="primary" @click="openDialogAddEdit(null)">
            Agregar Proveedor Registro Parcial
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="search.nombre"
            label="Nombre(s)"
            dense
            outlined
            hide-details
            clearable
          >
          </v-text-field>
        </v-col>

        <v-col cols="12" md="3">
          <v-autocomplete
            v-model="search.id_tipo_proveedor"
            item-value="id_tipo_proveedor"
            item-text="nombre"
            :items="tipo_proveedores"
            label="Tipo de Proveedor"
            dense
            outlined
            hide-details
            clearable
          ></v-autocomplete>
        </v-col>

        <v-col cols="12" md="3">
          <v-autocomplete
            v-model="search.nivel_proveedor"
            item-value="nivel_proveedor"
            item-text="nombre"
            :items="niveles_proveedores"
            label="Nivel de Proveedor"
            dense
            outlined
            hide-details
            clearable
          ></v-autocomplete>
        </v-col>

        <v-col cols="12" md="3">
          <v-btn block color="primary" text outlined @click="getItems">
            <v-icon>mdi-magnify</v-icon>
            Buscar
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            :loading="loading"
            :headers="headers"
            :items="items"
            :item-class="getRowClass"
          >
            <template v-slot:[`item.estado`]="{ item }">
              <template>
                <v-icon
                  v-if="item.motivo_bloqueo == null || item.motivo_bloqueo.length < 0"
                  color="green"
                >
                  mdi-check
                </v-icon>
                <VTooltipIconV2
                  v-else
                  color="white"
                  icon="mdi-alert"
                  :tooltip-text="`${item.motivo_bloqueo} - ${item.usuario_bloqueo} desde ${item.bloqueado_desde}`"
                >

                </VTooltipIconV2>
              </template>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                :color="(item.motivo_bloqueo == null || item.motivo_bloqueo.length < 0) ? 'gray' : 'white'"
                class="mr-2" small @click="openDialogView(item)">
                mdi-eye
              </v-icon>
              <v-icon
                v-if="(item.motivo_bloqueo == null || item.motivo_bloqueo.length < 0)"
                class="mr-2"
                small
                @click="openDialogAddEdit(item)"
              >
                mdi-pencil
              </v-icon>
               <v-icon
                v-if="item.motivo_bloqueo == null || item.motivo_bloqueo.length < 0"
                class="mr-2"
                small
                @click="openDialogLock(item)"
              >
                mdi-lock
              </v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import VAlertDialog from "@/components/VAlertDialog.vue";
import Nacionales from './Cuentas/Nacionales.vue'
import Internacionales from './Cuentas/Internacionales.vue'
//import VFileInputPlus from '@/components/VFileInputPlus.vue';
import VTooltipIconV2 from '@/components/VTooltipIconV2.vue';
import VViewer from '@/components/VViewer.vue';

export default {
  components: {
    VAlertDialog,
    Nacionales,
    Internacionales,
    //VFileInputPlus,
    VTooltipIconV2,
    VViewer
  },
  data: () => ({
    panel: 0,
    search: {
      id_tipo_proveedor: null,
      nombre: null,
      nivel_proveedor: null,
    },
    dialogView: false,
    dialogAddEdit: false,
    loading: false,
    items: [],
    step: 1,
    //tabOpcion: "fiscales",
    // Items para los autocompletados
    estados: [],
    loadingEstados: false,
    municipios: [],
    loadingMunicipios: false,
    tiposPersonas: [],
    loadingTipoPersonas: false,
    regimenFiscales: [],
    loadingRegimenFiscales: false,
    tiposProveedorNacional: [
      {
        id_tipo_proveedor_nacional: 1,
        nombre: "Venta de Bienes y Servicios"
      },
      {
        id_tipo_proveedor_nacional: 2,
        nombre: "Servicio Profesional en Area Médica"
      },
      {
        id_tipo_proveedor_nacional: 3,
        nombre: "Servicios profesionales con acreditacion"
      }
    ],
    headers: [
      {
        text: "Clave del Proveedor",
        value: "clave",
        align: "center",
      },
      {
        text: "Nombre Corto del Proveedor",
        value: "nombre_corto",
        align: "center",
      },
      {
        text: "Nombre Completo del Proveedor",
        value: "nombre_completo",
        align: "center",
      },
      {
        text: "Tipo de Persona",
        value: "tipo_persona",
        align: "center",
      },
      {
        text: "Nivel de Proveedor",
        value: "nivel_proveedor",
        align: "center",
      },
      {
        text: "Tipo de Proveedor",
        value: "tipo_proveedor",
        align: "center",
      },
      {
        text: "Estado",
        value: "estado",
        align: "center",
      },
      {
        text: "Nombre del Contacto",
        value: "nombre_contacto",
        align: "center",
      },

      {
        text: "Teléfono de Contacto",
        value: "telefono_contacto",
        align: "center",
      },

      {
        text: "Correo Electrónico",
        value: "correo_electronico",
        align: "center",
      },

      {
        text: "Acciones",
        value: "actions",
        sortable: false,
        align: "center",
      },
    ],
    editedItem: {
      id_proveedor: null,
      clave: null,
      id_tipo_persona: null,
      tipo_persona: null,
      id_tipo_proveedor: null,
      tipo_proveedor: null,
      nombre_corto: null,
      nombre_completo: null,
      nombre_contacto: null,
      telefono_contacto: null,
      correo_electronico: null,
      id_tipo_proveedor_nacional: null,
      direccion_google_maps: null,
      calle_sucursal: null,
      num_ext_sucursal: null,
      num_int_sucursal: null,
      id_estado_sucursal: null,
      id_municipio_sucursal: null,
      colonia_sucursal: null,
      codigo_postal_sucursal: null,
      ciudad_sucursal: null,
      region_sucursal: null,
      pais_sucursal: null,
      curriculum_vitae_empresarial: null,
      identificacion_oficial: null,
      comprobante_domicilio: null,
      caratula_estado_cuenta: null,
      situacion_fiscal: null,
      opinion_cumplimiento: null,
      referencia_comercial_1: null,
      referencia_comercial_2: null,
      certificacion: null,
      fotografia_producto_1: null,
      fotografia_producto_2: null,
      fotografia_producto_3: null,
      fotografia_producto_4: null,
      curriculum_empresa: null,
      acta_constitutiva: null,
      poder_representante: null,
      identificacion_representante: null,
      fachada_empresa_1: null,
      fachada_empresa_2: null,
      cedula_identificacion_fiscal: null,
      curp: null,
      solicitud_certificacion: null,
      solicitud_certificacion_morales: null,
      curp_representante: null,
      cedula_fiscal_moral: null,
      testimonio_escritura: null,
      certificado_1: null,
      certificado_2: null,
      certificado_3: null,
      poliza_responsabilidad_civil_medica: null,
      copia_titulo_medico: null,
      cedula_profesional_medico: null,
      diploma_especialidad: null,
      cedula_radiologia: null,
      nivel_proveedor: null,
      datos_fiscales: {
        bloquear_rfc: false,
        calle: null,
        num_ext: null,
        num_int: null,
        razon_social: null,
        rfc: null,
        id_estado: null,
        id_municipio: null,
        id_regimen_fiscal: null,
        codigo_postal: null,
        colonia: null,
        numero_fiscal: null,
        ciudad: null,
        region: null,
        id_pais: null,
        telefono: null,
      }
    },
    defaultEditedItem: {
      id_proveedor: null,
      clave: null,
      id_tipo_persona: null,
      tipo_persona: null,
      id_tipo_proveedor: null,
      tipo_proveedor: null,
      nombre_corto: null,
      nombre_completo: null,
      nombre_contacto: null,
      telefono_contacto: null,
      correo_electronico: null,
      id_tipo_proveedor_nacional: null,
      direccion_google_maps: null,
      calle_sucursal: null,
      num_ext_sucursal: null,
      num_int_sucursal: null,
      id_estado_sucursal: null,
      id_municipio_sucursal: null,
      colonia_sucursal: null,
      codigo_postal_sucursal: null,
      ciudad_sucursal: null,
      region_sucursal: null,
      pais_sucursal: null,
      curriculum_vitae_empresarial: null,
      identificacion_oficial: null,
      comprobante_domicilio: null,
      caratula_estado_cuenta: null,
      situacion_fiscal: null,
      opinion_cumplimiento: null,
      referencia_comercial_1: null,
      referencia_comercial_2: null,
      certificacion: null,
      fotografia_producto_1: null,
      fotografia_producto_2: null,
      fotografia_producto_3: null,
      fotografia_producto_4: null,
      curriculum_empresa: null,
      acta_constitutiva: null,
      poder_representante: null,
      identificacion_representante: null,
      fachada_empresa_1: null,
      fachada_empresa_2: null,
      cedula_identificacion_fiscal: null,
      curp: null,
      solicitud_certificacion: null,
      solicitud_certificacion_morales: null,
      curp_representante: null,
      cedula_fiscal_moral: null,
      testimonio_escritura: null,
      certificado_1: null,
      certificado_2: null,
      certificado_3: null,
      poliza_responsabilidad_civil_medica: null,
      copia_titulo_medico: null,
      cedula_profesional_medico: null,
      diploma_especialidad: null,
      cedula_radiologia: null,
      nivel_proveedor: null,
      datos_fiscales: {
        bloquear_rfc: false,
        calle: null,
        num_ext: null,
        num_int: null,
        razon_social: null,
        rfc: null,
        id_estado: null,
        id_municipio: null,
        id_regimen_fiscal: null,
        codigo_postal: null,
        colonia: null,
        numero_fiscal: null,
        ciudad: null,
        region: null,
        id_pais: null,
        telefono: null,
      }
    },
    editedItemFiscal: {
      bloquear_rfc: false,
      calle: null,
      num_ext: null,
      num_int: null,
      razon_social: null,
      rfc: null,
      id_estado: null,
      id_municipio: null,
      id_regimen_fiscal: null,
      codigo_postal: null,
      colonia: null,
      numero_fiscal: null,
      ciudad: null,
      region: null,
      id_pais: null,
      telefono: null,
    },
    defaultEditedItemFiscal: {
      bloquear_rfc: false,
      calle: null,
      num_ext: null,
      num_int: null,
      razon_social: null,
      rfc: null,
      id_estado: null,
      id_municipio: null,
      id_regimen_fiscal: null,
      codigo_postal: null,
      colonia: null,
      numero_fiscal: null,
      ciudad: null,
      region: null,
      id_pais: null,
      telefono: null,
    },
    loadingSaveDatosFiscales: false,
    loadingSave: false,
    dialogLock: false,
    motivo_bloqueo: null,
    loadingLock: false,
    tipo_proveedores: [],
    loadingTipoProveedores: false,
    paises: [],
    loadingPaises: false,
    municipiosSucursal: [],
    urlBase: "/api/Proveedores/DescargarDocumento",
    panelsView: [],
    panelsAddEdit: null,
    niveles_proveedores: [
      {
        nivel_proveedor: 1,
        nombre: "Registro Completo"
      },
      {
        nivel_proveedor: 2,
        nombre: "Registro Parcial"
      }
    ]
  }),
  mounted() {
    this.getItems();
    this.getEstados();
    this.getTipoPersonas();
    this.getRegimenFiscales();
    this.getTipoProveedores();
    this.getPaises();
  },
  methods: {
    codigoNombre: item => `[${item.codigo}] - ${item.nombre}`,
    getTipoProveedores() {
      this.loadingTipoProveedores = true;
      axios
        .get("/Catalogos/ListarTipoProveedores")
        .then((response) => {
          this.tipo_proveedores = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingTipoProveedores = false;
        });
    },
    getItems() {
      const url = new URL(`http://localhost/Pagos/ListarProveedores`);
      if(this.search.id_tipo_proveedor != null) url.searchParams.append("id_tipo_proveedor", this.search.id_tipo_proveedor);
      if(this.search.nombre != null) url.searchParams.append("nombre", this.search.nombre);
      if(this.search.nivel_proveedor != null) url.searchParams.append("nivel_proveedor", this.search.nivel_proveedor);
      var url_string = url.href.replace('http://localhost', '');

      this.loading = true;
      axios
        .get(url_string)
        .then((response) => {
          this.items = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getTipoPersonas(){
      this.loadingTipoPersonas = true;
      axios
        .get('/Catalogos/ListarTipoPersonas')
        .then(response => {
          this.tiposPersonas = response.data;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loadingTipoPersonas = false;
        })
    },
    getRegimenFiscales(){
      axios
        .get('/Catalogos/ListarRegimenFiscales')
        .then(response => {
          this.regimenFiscales = response.data;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loadingRegimenFiscales = false;
        })
    },
    getEstados(){
      this.loadingEstados = true;
      axios
        .get('/Catalogos/ListarEstados')
        .then(response => {
          this.estados = response.data;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loadingEstados = false;
        })
    },
    getMunicipios(){
      if(this.editedItemFiscal.id_estado == null) return;
      this.loadingMunicipios = true;
      axios
        .get(`/Catalogos/ListarMunicipios/${this.editedItemFiscal.id_estado}`)
        .then(response => {
          this.municipios = response.data;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loadingMunicipios = false;
        })
    },
    getMunicipiosSucursal(){
      if(this.editedItem.id_estado_sucursal == null) return;
      this.loadingMunicipios = true;
      axios
        .get(`/Catalogos/ListarMunicipios/${this.editedItem.id_estado_sucursal}`)
        .then(response => {
          this.municipiosSucursal = response.data;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loadingMunicipios = false;
        })
    },
    getPaises(){
      this.loadingPaises = true;
      axios
        .get('/Catalogos/ListarPaises')
        .then(response => {
          this.paises = response.data;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loadingPaises = false;
        })
    },
    openDialogAddEdit(editItem = null) {
      this.panelsAddEdit = null;
      if (editItem != null) {
        this.editedItem = Object.assign({}, editItem);
        if(editItem.datos_fiscales != null){
          this.editedItemFiscal = editItem.datos_fiscales;
          this.getMunicipios();
        }
        this.getMunicipiosSucursal();
      }
      else {
        this.editedItem = Object.assign({}, this.defaultEditedItem);
        this.editedItemFiscal = Object.assign({}, this.defaultEditedItemFiscal);
      }
    
      this.dialogAddEdit = true;

      setTimeout(() => {
        if (this.$refs.nacionales && this.$refs.nacionales.load) this.$refs.nacionales.load();
        if (this.$refs.internacionales && this.$refs.internacionales.load) this.$refs.internacionales.load();
      }, 500);

      
    },
    closeDialogAddEdit() {
      this.panelsAddEdit = null;
      //this.tabOpcion = "generales";
      this.step = 1;
      this.panel = 0;
      if (this.$refs.nacionales && this.$refs.nacionales.reset) this.$refs.nacionales.reset();
      if (this.$refs.internacionales && this.$refs.internacionales.reset) this.$refs.internacionales.reset();
      this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.editedItemFiscal = Object.assign({}, this.defaultEditedItemFiscal);
      this.dialogAddEdit = false;
    },
    openDialogView(editItem = null) {
      this.panelsView = []; 
      if (editItem != null) {
        this.editedItem = Object.assign({}, editItem);
        if(editItem.datos_fiscales != null){
          this.editedItemFiscal = editItem.datos_fiscales;
          this.getMunicipios();
        }
        else{
          this.editedItemFiscal = Object.assign({}, this.editedItemFiscal);
          this.editedItem.datos_fiscales = Object.assign({}, this.editedItemFiscal);
        }

        setTimeout(() => {
          if (this.$refs.nacionales_view && this.$refs.nacionales_view.load) this.$refs.nacionales_view.load();
          if (this.$refs.internacionales_view && this.$refs.internacionales_view.load) this.$refs.internacionales_view.load();
        }, 500);
      }
      else this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.dialogView = true;
    },
    closeDialogView() {
      this.panelsView = []; 
      this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.editedItemFiscal = Object.assign({}, this.defaultEditedItemFiscal);
      this.dialogView = false;
    },
    validateForm() {
      if (
        this.editedItem.id_tipo_proveedor == null ||
        this.editedItem.id_tipo_proveedor.length === 0
      ) {
        this.$refs.alert.setMessage(
          "",
          "Debe de Ingresar el Tipo de Proveedor"
        );
        this.$refs.alert.open();
        return false;
      }

      if (
        this.editedItem.id_tipo_persona == null ||
        this.editedItem.id_tipo_persona.length === 0
      ) {
        this.$refs.alert.setMessage("", "Debe de Ingresar el Tipo de Persona");
        this.$refs.alert.open();
        return false;
      }

      if (
        this.editedItem.nombre_corto == null ||
        this.editedItem.nombre_corto.length === 0 ||
        this.editedItem.nombre_corto.length > 256
      ) {
        this.$refs.alert.setMessage(
          "",
          "Debe de Ingresar un Nombre corto del Proveedor"
        );
        this.$refs.alert.open();
        return false;
      }

      if (
        this.editedItem.nombre_completo == null ||
        this.editedItem.nombre_completo.length === 0 ||
        this.editedItem.nombre_completo.length > 256
      ) {
        this.$refs.alert.setMessage(
          "",
          "Debe de Ingresar un Nombre completo del Proveedor"
        );
        this.$refs.alert.open();
        return false;
      }

      

      if (
        this.editedItem.nombre_contacto == null ||
        this.editedItem.nombre_contacto.length === 0
      ) {
        this.$refs.alert.setMessage(
          "",
          "Debe de Ingresar el Nombre de Contacto"
        );
        this.$refs.alert.open();
        return false;
      }

      const phoneRegex = /^\d{10}$/;

      if (
        this.editedItem.telefono_contacto == null ||
        this.editedItem.telefono_contacto.length === 0
      ) {
        this.$refs.alert.setMessage("", "Debe de Ingresar el Teléfono de Contacto");
        this.$refs.alert.open();
        return false;
      } else if (!phoneRegex.test(this.editedItem.telefono_contacto)) {
        this.$refs.alert.setMessage(
          "",
          "Ingrese un formato de Teléfono válido (10 dígitos numéricos)"
        );
        this.$refs.alert.open();
        return false;
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (
        this.editedItem.correo_electronico == null ||
        this.editedItem.correo_electronico.length === 0
      ) {
        this.$refs.alert.setMessage(
          "",
          "Debe de Ingresar el Correo Electrónico"
        );
        this.$refs.alert.open();
        return false;
      } else if (!emailRegex.test(this.editedItem.correo_electronico)) {
        this.$refs.alert.setMessage(
          "",
          "Ingrese un formato de Correo Electrónico válido"
        );
        this.$refs.alert.open();
        return false;
      }

      return true;
    },
    saveDialogAddEdit() {
      if (!this.validateForm()) return;

      this.loadingSave = true;

      // Definir un arreglo con las propiedades que deseas excluir
      const excludedProperties = ["id_proveedor"];

      // Crear el objeto FormData y agregar todos los datos de editedItem dinámicamente, excluyendo las propiedades del arreglo
      const formData = new FormData();
      for (const key in this.editedItem) {
        if (!excludedProperties.includes(key) && this.editedItem[key] !== null && this.editedItem[key] !== undefined) {
          formData.append(key, this.editedItem[key]);
        }
      }

      axios({
        method: this.editedItem.id_proveedor === null ? "POST" : "PUT",
        url:
          this.editedItem.id_proveedor === null
            ? "/Pagos/CrearProveedor"
            : `/Pagos/EditarProveedor/${this.editedItem.id_proveedor}`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(response => {
          //this.getItems();
          if(this.editedItem.id_proveedor === null){
            this.editedItem.id_proveedor = response.data;
            //this.tabOpcion = "fiscales";
          }
          //else this.closeDialogAddEdit();
          this.step = 2;

        })
        .catch((error) => {
          if (error.response.status == 409) {
            this.$refs.alert.setMessage("", error.response.data.msg.replace('Error : ', ''));
            this.$refs.alert.open();
            return;
          }
          console.log(error)
        })
        .finally(() => {
          this.loadingSave = false;
        });
    },
    openDialogLock(editItem) {
      this.editedItem = Object.assign({}, editItem);
      this.dialogLock = true;
      this.motivo_bloqueo = null;
    },
    closeDialogLock() {
      this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.dialogLock = false;
      this.motivo_bloqueo = null;
    },
    saveDialogLock() {
      this.loadingLock = true;
      axios
        .post(`/Pagos/BloquearProveedor`,{
          id_proveedor: this.editedItem.id_proveedor,
          motivo_bloqueo: this.motivo_bloqueo
        })
        .then(() => {
          this.closeDialogLock();
          this.getItems();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingLock = false;
        });
    },
    openDialogDatosFiscales() {
      // Reiniciar los campos al abrir el diálogo
      this.dialogDatosFiscales = true;
      this.direccionDomicilio = "";
      this.nombreRazonSocial = "";
      this.rfc = "";
      this.estado = "";
      this.municipio = "";
      this.tipoPersona = "";
      this.regimenFiscal = "";
      this.codigoPostal = "";
      this.direccion = "";
      this.colonia = "";
      this.telefono = "";
      this.correo = "";
    },
    closeDialogDatosFiscales() {
      this.dialogAddEdit = false;
      this.editedItemFiscal = Object.assign({}, this.defaultEditedItemFiscal);
      
    },
    validateDatosFiscales() {
      // Validación para proveedores nacionales
      if (this.editedItem.id_tipo_proveedor === '34a6560f-61c4-4bd0-a778-b7e641818fd0') {
        if (!this.editedItemFiscal.id_regimen_fiscal) {
          this.$refs.alert.setMessage("", "Selecciona un Régimen Fiscal.");
          this.$refs.alert.open();
          return false;
        }

        if (!this.editedItemFiscal.razon_social || this.editedItemFiscal.razon_social.length > 256) {
          this.$refs.alert.setMessage("", "Ingresa un Nombre/Razón Social válido (hasta 256 caracteres).");
          this.$refs.alert.open();
          return false;
        }

        if (!this.editedItemFiscal.rfc || this.editedItemFiscal.rfc.length < 12 || this.editedItemFiscal.rfc.length > 13) {
          this.$refs.alert.setMessage("", "Ingresa un RFC válido (12-13 caracteres).");
          this.$refs.alert.open();
          return false;
        }
      }

      // Validación para proveedores internacionales
      if (this.editedItem.id_tipo_proveedor === '4ae22cb8-3564-41e9-a726-302cb6762e02') {
        if (!this.editedItemFiscal.razon_social || this.editedItemFiscal.razon_social.length > 256) {
          this.$refs.alert.setMessage("", "Ingresa un Nombre/Razón Social válido (hasta 256 caracteres).");
          this.$refs.alert.open();
          return false;
        }

        if (!this.editedItemFiscal.numero_fiscal || this.editedItemFiscal.numero_fiscal.length > 256) {
          this.$refs.alert.setMessage("", "Ingresa un Número de Identificación Fiscal válido (hasta 256 caracteres).");
          this.$refs.alert.open();
          return false;
        }
      }

      // Validación común para todos los proveedores
      if (!this.editedItemFiscal.calle || this.editedItemFiscal.calle.length > 256) {
        this.$refs.alert.setMessage("", "Ingresa una Calle válida (hasta 256 caracteres).");
        this.$refs.alert.open();
        return false;
      }

      if (!this.editedItemFiscal.num_ext) {
        this.$refs.alert.setMessage("", "Ingresa el Número Exterior.");
        this.$refs.alert.open();
        return false;
      }

      if (this.editedItem.id_tipo_proveedor === '34a6560f-61c4-4bd0-a778-b7e641818fd0' && !this.editedItemFiscal.colonia) {
        this.$refs.alert.setMessage("", "Ingresa una Colonia.");
        this.$refs.alert.open();
        return false;
      }

      if (!this.editedItemFiscal.codigo_postal || this.editedItemFiscal.codigo_postal.length < 5) {
        this.$refs.alert.setMessage("", "Ingresa un Código Postal válido (5 caracteres).");
        this.$refs.alert.open();
        return false;
      }

      if (this.editedItem.id_tipo_proveedor === '34a6560f-61c4-4bd0-a778-b7e641818fd0') {
        if (!this.editedItemFiscal.id_estado) {
          this.$refs.alert.setMessage("", "Selecciona un Estado.");
          this.$refs.alert.open();
          return false;
        }

        if (!this.editedItemFiscal.id_municipio) {
          this.$refs.alert.setMessage("", "Selecciona un Municipio.");
          this.$refs.alert.open();
          return false;
        }
      } else {
        if (!this.editedItemFiscal.ciudad || this.editedItemFiscal.ciudad.length > 256) {
          this.$refs.alert.setMessage("", "Ingresa una Ciudad válida (hasta 256 caracteres).");
          this.$refs.alert.open();
          return false;
        }

        if (!this.editedItemFiscal.region || this.editedItemFiscal.region.length > 256) {
          this.$refs.alert.setMessage("", "Ingresa una Región/Estado/Provincia válida (hasta 256 caracteres).");
          this.$refs.alert.open();
          return false;
        }

        if (!this.editedItemFiscal.id_pais) {
          this.$refs.alert.setMessage("", "Selecciona un País.");
          this.$refs.alert.open();
          return false;
        }
      }

      const phoneRegex = /^\d{10}$/;
      if (!this.editedItemFiscal.telefono || !phoneRegex.test(this.editedItemFiscal.telefono)) {
        this.$refs.alert.setMessage("", "Ingresa un Teléfono Empresarial/Sucursal válido (10 dígitos numéricos).");
        this.$refs.alert.open();
        return false;
      }

      return true;
    },
    saveDialogDatosFiscales() {
      if (!this.validateDatosFiscales()) {
        return;
      }

      this.loadingSaveDatosFiscales = true;

      axios
        .post(`/Pagos/ActualizarProveedorDatosFiscales/${this.editedItem.id_proveedor}`, {
          id_regimen_fiscal: this.editedItemFiscal.id_regimen_fiscal,
          razon_social: this.editedItemFiscal.razon_social,
          rfc: this.editedItemFiscal.rfc,
          numero_fiscal: this.editedItemFiscal.numero_fiscal,
          calle: this.editedItemFiscal.calle,
          num_ext: this.editedItemFiscal.num_ext,
          num_int: this.editedItemFiscal.num_int,
          id_estado: this.editedItemFiscal.id_estado,
          id_municipio: this.editedItemFiscal.id_municipio,
          colonia: this.editedItemFiscal.colonia,
          codigo_postal: this.editedItemFiscal.codigo_postal,
          ciudad: this.editedItemFiscal.ciudad,
          region: this.editedItemFiscal.region,
          id_pais: this.editedItemFiscal.id_pais,
          telefono: this.editedItemFiscal.telefono,
        })
        .then(() => {
          //this.closeDialogAddEdit();
          this.getItems();
          this.step = 3;
        })
        .catch(error => {
          if (error.response && error.response.status === 400) {
              this.$refs.alert.setMessage("", error.response.data.msg.replace(/^Error :/, ""));
              this.$refs.alert.open();
          }
          if (error.response.status == 409) {
            this.$refs.alert.setMessage("", error.response.data.msg.replace('Error : ', ''));
            this.$refs.alert.open();
            return;
          }
          else {
            console.log(error);
          }
        })
        .finally(() => {
          this.loadingSaveDatosFiscales = false;
        });
    },
    downloadFile(fichero){
      var url = `/api/Proveedores/DescargarDocumento/${fichero}?jwt=${this.$store.state.token}`;
      window.open(url, '_blank');
    },
    getTipoProveedorNacional(id_tipo_proveedor_nacional){
      var idx = this.tiposProveedorNacional.findIndex(x => x.id_tipo_proveedor_nacional == id_tipo_proveedor_nacional);
      if(idx < 0) return "";
      else return this.tiposProveedorNacional[idx].nombre;
    },
    getRowClass(item) {
      return (item.motivo_bloqueo != null && item.motivo_bloqueo.length > 0) ? 'red-row' : '';
    },
    validateRFC(){
        if(!this.editedItemFiscal.rfc || this.editedItemFiscal.rfc.length != (this.editedItem.id_tipo_persona == '689a6d20-3aff-4a09-b5b4-52105ad212f0') ? 12 : 13) {
          this.editedItemFiscal.bloquear_rfc = false;
          return;
        }
        
        axios
        .get(`/Proveedores/ValidarRFC?rfc=${this.editedItemFiscal.rfc}`)
        .then(response => {
            if(response.data.bloqueado == true){
              this.editedItemFiscal.bloquear_rfc = true;
              this.$refs.alert.setMessage("","El RFC proporcionado se encuentra en el listado del Artículo 69-B del Código Fiscal de la Federación.");
              this.$refs.alert.open();
            }
            else this.editedItemFiscal.bloquear_rfc = false;
        })
        .catch(error => {
          console.log(error);
        })
    }
  },
};
</script>


<style scoped>
.red-row {
  background-color: red !important;
  color: white;
}
</style>