<template>
  <iframe
    ref="fingerFrame"
    src="/finger-reader/index.html"
    width="0"
    height="0"
    style="border: none;"
  ></iframe>
</template>

<script>
export default {
  name: 'FingerReaderRaw',
  data() {
    return {
      fingerprints: [],
      trustedOrigins: [
        'http://localhost:8080',
        'https://project11.masttl.com',
        'https://mi.ubga.org.mx'
      ]
    };
  },
  mounted() {
    // Escuchamos los mensajes que vienen del iframe
    window.addEventListener('message', this.handleMessage);
  },
  beforeUnmount() {
    window.removeEventListener('message', this.handleMessage);
  },
  methods: {
    handleMessage(event) {
      // Validar que el origen del mensaje esté en la lista de confiables
      if (!this.trustedOrigins.includes(event.origin)) {
        console.warn(`Mensaje rechazado por origen no confiable: ${event.origin}`);
        return; // Ignorar mensajes de orígenes no confiables
      }
      const { data } = event;
      // Solo procesamos si el objeto data viene con el tipo esperado
      if (!data || !data.type || data.type !== 'FINGERPRINT_READ'){
        //console.warn('Mensaje recibido no válido:', data);
        return;
      }
      
      this.$emit('change', data.data);
    }
  }
}
</script>