<template>
  <v-container class="face-capture" fluid>
    <v-row justify="center" align="center">
      <v-col cols="12" md="12" class="text-center">
        <h2>{{title}}</h2>

        <!-- Selector de cámaras -->
        <v-select
          v-if="videoDevices.length > 1"
          v-model="selectedDeviceId"
          :items="videoDevices"
          label="Seleccionar cámara"
          item-value="deviceId"
          item-text="label"
          class="mb-4"
          @change="switchCamera"
        ></v-select>

        <!-- Contenedor circular cuadrado (500x500) -->
        <div class="video-container mx-auto">
          <!-- Progreso, detrás -->
          <v-progress-circular
            :value="(capturedImages.length / numImages) * 100"
            :size="500"
            width="6"
            color="primary"
            class="progress-overlay"
            style="z-index: 5 !important;"
          ></v-progress-circular>

          <!-- Video (por encima) -->
          <video
            ref="video"
            autoplay
            muted
            playsinline
            class="video-feed"
          ></video>

          <!-- Canvas de landmarks (aún más arriba) -->
          <canvas ref="canvas" class="overlay"></canvas>
          
          <v-img
            src="/img/scanner.gif"
            class="overlay"
          />
        </div>

        <!-- Mensaje de estado -->
        <p class="mt-4">{{ message }}</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as faceapi from 'face-api.js';

export default {
  props: {
    numImages: {
      type: Number,
      default: 14,
    },
    title: {
      type: String,
      default: 'Capturando imágenes del rostro en diferentes ángulos',
    },
  },
  data() {
    return {
      message: "Iniciando la cámara...",
      capturedImages: [],
      yawBuffer: [],
      pitchBuffer: [],
      bufferSize: 5,        // Tamaño del buffer para suavizar
      captureThreshold: 10, // Diferencia mínima para capturar
      lastCapturedYaw: null,
      lastCapturedPitch: null,
      interval: null,       // Variable para almacenar el intervalo
      videoDevices: [],     // Lista de cámaras disponibles
      selectedDeviceId: null, // ID de la cámara seleccionada
    };
  },
  mounted() {
    this.initCameras();
  },
  beforeDestroy() {
    // Detener el stream de la cámara
    const stream = this.$refs.video?.srcObject;
    if (stream && stream.getTracks) {
      stream.getTracks().forEach((track) => track.stop());
    }
    this.$refs.video.srcObject = null;
    // Limpiar cualquier intervalo en ejecución
    clearInterval(this.interval);

    // Limpiar el buffer y el estado relacionado
    this.yawBuffer = [];
    this.pitchBuffer = [];
    this.capturedImages = [];
    this.lastCapturedYaw = null;
    this.lastCapturedPitch = null;
    this.message = "Iniciando la cámara...";
  },
  methods: {
    async initCameras() {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        this.videoDevices = devices
          .filter((device) => device.kind === "videoinput")
          .map((device) => ({
            label: device.label || `Cámara ${this.videoDevices.length + 1}`,
            deviceId: device.deviceId,
          }));

        // Priorizar la cámara que contenga 'HD Pro Webcam'
        const preferredDevice = this.videoDevices.find((device) =>
          device.label.includes("HD Pro Webcam")
        );

        this.selectedDeviceId = preferredDevice
          ? preferredDevice.deviceId
          : this.videoDevices[0]?.deviceId;

        this.startCamera();
      } catch (error) {
        console.error("Error al inicializar cámaras:", error);
        this.message = "No se pudo acceder a las cámaras.";
      }
    },
    async startCamera() {
      try {
        const constraints = {
          video: { deviceId: this.selectedDeviceId ? { exact: this.selectedDeviceId } : undefined },
        };

        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        this.$refs.video.srcObject = stream;
        await this.loadModels();
        this.resizeCanvas();
        this.message = "Capturando imágenes...";
        this.detectFace();
      } catch (error) {
        console.error("Error al iniciar la cámara:", error);
        this.message = "No se pudo acceder a la cámara.";
      }
    },

    async switchCamera() {
      const stream = this.$refs.video?.srcObject;
      if (stream && stream.getTracks) {
        stream.getTracks().forEach((track) => track.stop());
      }
      this.startCamera();
    },

    async loadModels() {
      console.log("Cargando modelos face-api desde /models...");
      await faceapi.nets.ssdMobilenetv1.loadFromUri('/models');
      await faceapi.nets.faceLandmark68Net.loadFromUri('/models');
    },

    resizeCanvas() {
      const video = this.$refs.video;
      const canvas = this.$refs.canvas;
      const container = this.$refs.video.parentElement;
      const size = Math.min(container.offsetWidth, container.offsetHeight);

      canvas.width = size;
      canvas.height = size;
      video.style.width = `${size}px`;
      video.style.height = `${size}px`;
      video.style.objectFit = "cover";
      video.style.borderRadius = "50%";
    },

    async detectFace() {
      const video = this.$refs.video;
      const canvas = this.$refs.canvas;
      const displaySize = { width: canvas.width, height: canvas.height };
      faceapi.matchDimensions(canvas, displaySize);

      this.interval = setInterval(async () => {
        const detections = await faceapi.detectSingleFace(video).withFaceLandmarks();
        if (detections) {
          const landmarks = detections.landmarks;

          const nose = landmarks.getNose();
          const currentYaw = nose[3]._x - nose[0]._x;
          const currentPitch = nose[3]._y - nose[0]._y;

          this.yawBuffer.push(currentYaw);
          this.pitchBuffer.push(currentPitch);
          if (this.yawBuffer.length > this.bufferSize) this.yawBuffer.shift();
          if (this.pitchBuffer.length > this.bufferSize) this.pitchBuffer.shift();

          const avgYaw = this.yawBuffer.reduce((a, b) => a + b, 0) / this.yawBuffer.length;
          const avgPitch = this.pitchBuffer.reduce((a, b) => a + b, 0) / this.pitchBuffer.length;

          const ctx = canvas.getContext('2d');
          ctx.clearRect(0, 0, canvas.width, canvas.height);

          // Condición para capturar imágenes en diferentes ángulos
          if (
            (this.lastCapturedYaw === null || Math.abs(avgYaw - this.lastCapturedYaw) > this.captureThreshold ||
              Math.abs(avgPitch - this.lastCapturedPitch) > this.captureThreshold) &&
            this.capturedImages.length < this.numImages
          ) {
            this.captureImage(video, avgYaw, avgPitch, detections.detection.box);
            this.lastCapturedYaw = avgYaw;
            this.lastCapturedPitch = avgPitch;
          }

          if (this.capturedImages.length >= this.numImages) {
            clearInterval(this.interval);
            this.message = "Captura completada.";
            this.$emit("change", this.capturedImages);
          }
        }
      }, 200);
    },

    captureImage(video, yaw, pitch, box) {
      const margin = 15; // Margen en píxeles

      // Ajustar las dimensiones del box con el margen
      const adjustedBox = {
        x: Math.max(box.x - margin, 0), // Asegurarse de no salir del límite del video
        y: Math.max(box.y - margin, 0),
        width: box.width + margin * 2,
        height: box.height + margin * 2
      };

      // Crear el canvas con las nuevas dimensiones
      const tempCanvas = document.createElement("canvas");
      tempCanvas.width = adjustedBox.width;
      tempCanvas.height = adjustedBox.height;

      const ctx = tempCanvas.getContext("2d");
      ctx.drawImage(
        video,
        adjustedBox.x,
        adjustedBox.y,
        adjustedBox.width,
        adjustedBox.height,
        0,
        0,
        adjustedBox.width,
        adjustedBox.height
      );

      const faceImage = tempCanvas.toDataURL("image/png");
      this.capturedImages.push(faceImage);

      console.log(`Imagen capturada. Total acumulado: ${this.capturedImages.length}`);
    }
  },
};
</script>

<style scoped>
.face-capture {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.video-container {
  position: relative;
  width: 500px;
  height: 500px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
}

.progress-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.video-feed {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  background-color: #000;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  pointer-events: none;
  border-radius: 50%;
}

.image-preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
}
</style>
