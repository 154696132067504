<template>
  <v-container
    class="px-5"
    style="width: 100%; max-width: 1920px; position: relative; margin: 0 auto"
  >
    <v-alert-dialog
      ref="alert"
    >
    </v-alert-dialog>
    <v-dialog
      v-model="dialog"
      max-width="85%"
      persistent
    >
      <v-card style="overflow-x: hidden;">
        <v-card-title>
          Validar Proveedor
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12" class="text-center">
              <h2>Datos Generales</h2>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <VSelectError
                label="Tipo de Proveedor *"
                :items="tiposProveedor"
                item-text="nombre"
                item-value="id_tipo_proveedor"
                :value="editedItem.idTipoProveedor"
                :error-state="editedItem.errorIdTipoProveedor"
                @update:value="updateField('idTipoProveedor', $event)"
                @update:errorState="updateField('errorIdTipoProveedor', $event)"
                @update:textareaModel="updateField('anomaliaIdTipoProveedor', $event)"
              />
            </v-col>

            <v-col cols="12" md="6">
              <VAutocompleteError
                :items="tiposPersonas"
                label="Tipo de Persona *"
                item-value="id_tipo_persona"
                item-text="nombre"
                :value="editedItem.idTipoPersona"
                :error-state="editedItem.errorIdTipoPersona"
                @update:value="updateField('idTipoPersona', $event)"
                @update:errorState="updateField('errorIdTipoPersona', $event)"
                @update:textareaModel="updateField('anomaliaIdTipoPersona', $event)"
              />
            </v-col>

            <v-col cols="12" md="6">
              <VTextFieldError
                label="Nombre Completo del Proveedor *"
                :value="editedItem.nombreCompleto"
                :error-state="editedItem.errorNombreCompleto"
                @update:value="updateField('nombreCompleto', $event)"
                @update:errorState="updateField('errorNombreCompleto', $event)"
                @update:textareaModel="updateField('anomaliaNombreCompleto', $event)"
              />
            </v-col>

            <v-col cols="12" md="6">
              <VTextFieldError
                label="Nombre del Contacto *"
                :value="editedItem.nombreContacto"
                :error-state="editedItem.errorNombreContacto"
                @update:value="updateField('nombreContacto', $event)"
                @update:errorState="updateField('errorNombreContacto', $event)"
                @update:textareaModel="updateField('anomaliaNombreContacto', $event)"
              />
            </v-col>

            <v-col cols="12" md="6">
              <VTextFieldError
                label="Teléfono de Contacto *"
                :value="editedItem.telefonoContacto"
                :error-state="editedItem.errorTelefonoContacto"
                @update:value="updateField('telefonoContacto', $event)"
                @update:errorState="updateField('errorTelefonoContacto', $event)"
                @update:textareaModel="updateField('anomaliaTelefonoContacto', $event)"
              />
            </v-col>

            <v-col cols="12" md="6">
              <VTextFieldError
                label="Correo Electrónico *"
                :value="editedItem.correoContacto"
                :error-state="editedItem.errorCorreoContacto"
                @update:value="updateField('correoContacto', $event)"
                @update:errorState="updateField('errorCorreoContacto', $event)"
                @update:textareaModel="updateField('anomaliaCorreoContacto', $event)"
              />
            </v-col>
          </v-row>

          <br />
          <v-divider></v-divider>
          <br />
          
          <!-- Documentos para Proveedor Nacional / Persona Moral -->
          <template>
            <v-row>
              <v-col cols="12" class="text-center">
                <h2>Datos Geográficos</h2>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <!--
                <google-maps-url-input
                  :value="editedItem.direccionGoogleMaps"
                  label="URL de la ubicación en Google Maps"
                  dense
                  hide-details
                  outlined
                  :error-state="editedItem.errorDireccionGoogleMaps"
                  @update:value="updateField('direccionGoogleMaps', $event)"
                  @update:errorState="updateField('errorDireccionGoogleMaps', $event)"
                  @update:textareaModel="updateField('anomaliaDireccionGoogleMaps', $event)"
                />
                -->
                <VBtnError
                  label="URL de la ubicación en Google Maps"
                  :value="editedItem.direccionGoogleMaps"
                  outlined
                  :error-state="editedItem.errorDireccionGoogleMaps"
                  @update:value="updateField('direccionGoogleMaps', $event)"
                  @update:errorState="updateField('errorDireccionGoogleMaps', $event)"
                  @update:textareaModel="updateField('anomaliaDireccionGoogleMaps', $event)"
                >
                </VBtnError>
              </v-col>

              <v-col cols="6" class="py-1">
                <VFileInputError
                  downloadUrl="/api/Proveedores/DescargarDocumento"
                  :token="token"
                  accept=".jpg,.jpeg,.png,.pdf"
                  label="Fotografía de la fachada de la empresa 1 *"
                  :value="editedItem.fachadaEmpresa1"
                  :error-state="editedItem.errorFachadaEmpresa1"
                  @update:value="updateField('fachadaEmpresa1', $event)"
                  @update:errorState="updateField('errorFachadaEmpresa1', $event)"
                  @update:textareaModel="updateField('anomaliaFachadaEmpresa1', $event)"
                />
              </v-col>

              <v-col cols="6" class="py-1">
                <VFileInputError
                  downloadUrl="/api/Proveedores/DescargarDocumento"
                  :token="token"
                  accept=".jpg,.jpeg,.png,.pdf"
                  label="Fotografía de la fachada de la empresa 2 *"
                  :value="editedItem.fachadaEmpresa2"
                  :error-state="editedItem.errorFachadaEmpresa2"
                  @update:value="updateField('fachadaEmpresa2', $event)"
                  @update:errorState="updateField('errorFachadaEmpresa2', $event)"
                  @update:textareaModel="updateField('anomaliaFachadaEmpresa2', $event)"
                />
              </v-col>
            </v-row>

            <br />
            <v-divider></v-divider>
            <br />
          </template>

          <v-row>
            <v-col cols="12" class="text-center">
              <h2>Datos Fiscales</h2>
            </v-col>
          </v-row>

          <v-row>
            <!-- Datos Fiscales Nacionales -->
            <template v-if="editedItem.idTipoProveedor == 1">
              <v-col cols="12" md="6">
                <VSelectError
                  label="Tipo de Proveedor Nacional *"
                  :items="tiposProveedorNacional"
                  item-text="nombre"
                  item-value="id_tipo_proveedor_nacional"
                  :value="editedItem.tipoProveedorNacional"
                  :error-state="editedItem.errortipoProveedorNacional"
                  @update:value="updateField('tipoProveedorNacional', $event)"
                  @update:errorState="updateField('errortipoProveedorNacional', $event)"
                  @update:textareaModel="updateField('anomaliatipoProveedorNacional', $event)"
                />
              </v-col>

              <v-col cols="12" md="6">
                <VAutocompleteError
                  label="Régimen Fiscal *"
                  :value="editedItem.idRegimenFiscal"
                  :items="regimenFiscales"
                  item-value="id_regimen_fiscal"
                  item-text="nombre"
                  :error-state="editedItem.errorIdRegimenFiscal"
                  @update:value="updateField('idRegimenFiscal', $event)"
                  @update:errorState="updateField('errorIdRegimenFiscal', $event)"
                  @update:textareaModel="updateField('anomaliaIdRegimenFiscal', $event)"
                />
              </v-col>

              <v-col cols="12" md="6">
                <VTextFieldError
                  label="Nombre/Razón Social *"
                  :value="editedItem.razonSocial"
                  :error-state="editedItem.errorRazonSocial"
                  @update:value="updateField('razonSocial', $event)"
                  @update:errorState="updateField('errorRazonSocial', $event)"
                  @update:textareaModel="updateField('anomaliaRazonSocial', $event)"
                />
              </v-col>

              <v-col cols="12" md="6">
                <VTextFieldError
                  label="RFC *"
                  :value="editedItem.rfc"
                  counter
                  :maxlength="(editedItem.idTipoPersona == 2) ? 12 : 13"
                  :error-state="editedItem.errorRfc"
                  @update:value="updateField('rfc', $event)"
                  @update:errorState="updateField('errorRfc', $event)"
                  @update:textareaModel="updateField('anomaliaRfc', $event)"
                />
              </v-col>

              <v-col cols="6">
                <VTextFieldError
                  label="Calle *"
                  :value="editedItem.calleFiscal"
                  :error-state="editedItem.errorCalleFiscal"
                  @update:value="updateField('calleFiscal', $event)"
                  @update:errorState="updateField('errorCalleFiscal', $event)"
                  @update:textareaModel="updateField('anomaliaCalleFiscal', $event)"
                />
              </v-col>

              <v-col cols="3">
                <VTextFieldError
                  label="Núm Exterior *"
                  :value="editedItem.numExtFiscal"
                  :error-state="editedItem.errorNumExtFiscal"
                  @update:value="updateField('numExtFiscal', $event)"
                  @update:errorState="updateField('errorNumExtFiscal', $event)"
                  @update:textareaModel="updateField('anomaliaNumExtFiscal', $event)"
                />
              </v-col>

              <v-col cols="3">
                <VTextFieldError
                  label="Núm Interior"
                  :value="editedItem.numIntFiscal"
                  :error-state="editedItem.errorNumIntFiscal"
                  @update:value="updateField('numIntFiscal', $event)"
                  @update:errorState="updateField('errorNumIntFiscal', $event)"
                  @update:textareaModel="updateField('anomaliaNumIntFiscal', $event)"
                />
              </v-col>

              <v-col cols="12" md="6">
                <VAutocompleteError
                  :items="estados"
                  label="Estado *"
                  item-value="id_estado"
                  item-text="nombre"
                  @change="getMunicipios"
                  :value="editedItem.idEstado"
                  :error-state="editedItem.errorIdEstado"
                  @update:value="updateField('idEstado', $event)"
                  @update:errorState="updateField('errorIdEstado', $event)"
                  @update:textareaModel="updateField('anomaliaIdEstado', $event)"
                />
              </v-col>

              <v-col cols="12" md="6">
                <VAutocompleteError
                  :items="municipios"
                  item-value="id_municipio"
                  item-text="nombre"
                  label="Municipio *"
                  :loading="loadingMunicipios"
                  :value="editedItem.idMunicipio"
                  :error-state="editedItem.errorIdMunicipio"
                  @update:value="updateField('idMunicipio', $event)"
                  @update:errorState="updateField('errorIdMunicipio', $event)"
                  @update:textareaModel="updateField('anomaliaIdMunicipio', $event)"
                />
              </v-col>

              <v-col cols="12" md="6">
                <VTextFieldError
                  label="Colonia *"
                  :value="editedItem.colonia"
                  :error-state="editedItem.errorColonia"
                  @update:value="updateField('colonia', $event)"
                  @update:errorState="updateField('errorColonia', $event)"
                  @update:textareaModel="updateField('anomaliaColonia', $event)"
                />
              </v-col>

              <v-col cols="12" md="6">
                <VTextFieldError
                  label="Código Postal *"
                  :value="editedItem.codigoPostal"
                  :error-state="editedItem.errorCodigoPostal"
                  @update:value="updateField('codigoPostal', $event)"
                  @update:errorState="updateField('errorCodigoPostal', $event)"
                  @update:textareaModel="updateField('anomaliaCodigoPostal', $event)"
                />
              </v-col>
            </template>

            <!-- Datos Fiscales Internacionales -->
            <template v-if="editedItem.idTipoProveedor == 2">
              <v-col cols="12" md="6">
                <VTextFieldError
                  label="Nombre/Razón Social *"
                  :value="editedItem.razonSocial"
                  :error-state="editedItem.errorRazonSocial"
                  @update:value="updateField('razonSocial', $event)"
                  @update:errorState="updateField('errorRazonSocial', $event)"
                  @update:textareaModel="updateField('anomaliaRazonSocial', $event)"
                />
              </v-col>

              <v-col cols="12" md="6">
                <VTextFieldError
                  label="Número de Identificación Fiscal *"
                  :value="editedItem.numeroFiscal"
                  :error-state="editedItem.errorNumeroFiscal"
                  @update:value="updateField('numeroFiscal', $event)"
                  @update:errorState="updateField('errorNumeroFiscal', $event)"
                  @update:textareaModel="updateField('anomaliaNumeroFiscal', $event)"
                />
              </v-col>

              <v-col cols="6">
                <VTextFieldError
                  label="Calle *"
                  :value="editedItem.calleFiscal"
                  :error-state="editedItem.errorCalleFiscal"
                  @update:value="updateField('calleFiscal', $event)"
                  @update:errorState="updateField('errorCalleFiscal', $event)"
                  @update:textareaModel="updateField('anomaliaCalleFiscal', $event)"
                />
              </v-col>

              <v-col cols="3">
                <VTextFieldError
                  label="Núm Exterior *"
                  :value="editedItem.numExtFiscal"
                  :error-state="editedItem.errorNumExtFiscal"
                  @update:value="updateField('numExtFiscal', $event)"
                  @update:errorState="updateField('errorNumExtFiscal', $event)"
                  @update:textareaModel="updateField('anomaliaNumExtFiscal', $event)"
                />
              </v-col>

              <v-col cols="3">
                <VTextFieldError
                  label="Núm Interior"
                  :value="editedItem.numIntFiscal"
                  :error-state="editedItem.errorNumIntFiscal"
                  @update:value="updateField('numIntFiscal', $event)"
                  @update:errorState="updateField('errorNumIntFiscal', $event)"
                  @update:textareaModel="updateField('anomaliaNumIntFiscal', $event)"
                />
              </v-col>

              <v-col cols="12" md="6">
                <VTextFieldError
                  label="Código Postal *"
                  :value="editedItem.codigoPostal"
                  :error-state="editedItem.errorCodigoPostal"
                  @update:value="updateField('codigoPostal', $event)"
                  @update:errorState="updateField('errorCodigoPostal', $event)"
                  @update:textareaModel="updateField('anomaliaCodigoPostal', $event)"
                />
              </v-col>

              <v-col cols="12" md="6">
                <VTextFieldError
                  label="Ciudad *"
                  :value="editedItem.ciudad"
                  :error-state="editedItem.errorCiudad"
                  @update:value="updateField('ciudad', $event)"
                  @update:errorState="updateField('errorCiudad', $event)"
                  @update:textareaModel="updateField('anomaliaCiudad', $event)"
                />
              </v-col>

              <v-col cols="12" md="6">
                <VTextFieldError
                  label="Región/Estado/Provincia *"
                  :value="editedItem.region"
                  :error-state="editedItem.errorRegion"
                  @update:value="updateField('region', $event)"
                  @update:errorState="updateField('errorRegion', $event)"
                  @update:textareaModel="updateField('anomaliaRegion', $event)"
                />
              </v-col>

              <v-col cols="12" md="6">
                <VAutocompleteError
                  :items="paises"
                  label="País *"
                  item-value="id_pais"
                  item-text="nombre"
                  :value="editedItem.idPais"
                  :error-state="editedItem.errorPais"
                  @update:value="updateField('idPais', $event)"
                  @update:errorState="updateField('errorIdPais', $event)"
                  @update:textareaModel="updateField('anomaliaIdPais', $event)"
                />
              </v-col>
            </template>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <VTextFieldError
                label="Teléfono *"
                :value="editedItem.telefonoFiscal"
                :error-state="editedItem.errorTelefonoFiscal"
                @update:value="updateField('telefonoFiscal', $event)"
                @update:errorState="updateField('errorTelefonoFiscal', $event)"
                @update:textareaModel="updateField('anomaliaTelefonoFiscal', $event)"
              />
            </v-col>
          </v-row>

          <br />
          <v-divider></v-divider>
          <br />

          <!-- Sucursal -->
          <v-row>
            <v-col cols="12" class="text-center">
              <h2>Datos de la Sucursal</h2>
              <div class="text-subtitle-1">(Si aplica)</div>
            </v-col>
          </v-row>

          <v-row>
            <template v-if="editedItem.idTipoProveedor == 1">
              <v-col cols="6">
                <VTextFieldError
                  label="Calle *"
                  :value="editedItem.calleSucursal"
                  :error-state="editedItem.errorCalleSucursal"
                  @update:value="updateField('calleSucursal', $event)"
                  @update:errorState="updateField('errorCalleSucursal', $event)"
                  @update:textareaModel="updateField('anomaliaCalleSucursal', $event)"
                />
              </v-col>

              <v-col cols="3">
                <VTextFieldError
                  label="Núm Exterior *"
                  :value="editedItem.numExtSucursal"
                  :error-state="editedItem.errorNumExtSucursal"
                  @update:value="updateField('numExtSucursal', $event)"
                  @update:errorState="updateField('errorNumExtSucursal', $event)"
                  @update:textareaModel="updateField('anomaliaNumExtSucursal', $event)"
                />
              </v-col>

              <v-col cols="3">
                <VTextFieldError
                  label="Núm Interior"
                  :value="editedItem.numIntSucursal"
                  :error-state="editedItem.errorNumIntSucursal"
                  @update:value="updateField('numIntSucursal', $event)"
                  @update:errorState="updateField('errorNumIntSucursal', $event)"
                  @update:textareaModel="updateField('anomaliaNumIntSucursal', $event)"
                />
              </v-col>

              <v-col cols="12" md="6">
                <VAutocompleteError
                  :items="estados"
                  label="Estado *"
                  item-value="id_estado"
                  item-text="nombre"
                  @change="getMunicipiosSucursal"
                  :value="editedItem.idEstadoSucursal"
                  :error-state="editedItem.errorIdEstadoSucursal"
                  @update:value="updateField('idEstadoSucursal', $event)"
                  @update:errorState="updateField('errorIdEstadoSucursal', $event)"
                  @update:textareaModel="updateField('anomaliaIdEstadoSucursal', $event)"
                />
              </v-col>

              <v-col cols="12" md="6">
                <VAutocompleteError
                  :items="municipiosSucursal"
                  item-value="id_municipio"
                  item-text="nombre"
                  label="Municipio *"
                  :loading="loadingMunicipios"
                  :value="editedItem.idMunicipioSucursal"
                  :error-state="editedItem.errorIdMunicipioSucursal"
                  @update:value="updateField('idMunicipioSucursal', $event)"
                  @update:errorState="updateField('errorIdMunicipioSucursal', $event)"
                  @update:textareaModel="updateField('anomaliaIdMunicipioSucursal', $event)"
                />
              </v-col>

              <v-col cols="12" md="6">
                <VTextFieldError
                  label="Colonia *"
                  :value="editedItem.coloniaSucursal"
                  :error-state="editedItem.errorColoniaSucursal"
                  @update:value="updateField('coloniaSucursal', $event)"
                  @update:errorState="updateField('errorColoniaSucursal', $event)"
                  @update:textareaModel="updateField('anomaliaColoniaSucursal', $event)"
                />
              </v-col>

              <v-col cols="12" md="6">
                <VTextFieldError
                  label="Código Postal *"
                  :value="editedItem.codigoPostalSucursal"
                  :error-state="editedItem.errorCodigoPostalSucursal"
                  @update:value="updateField('codigoPostalSucursal', $event)"
                  @update:errorState="updateField('errorCodigoPostalSucursal', $event)"
                  @update:textareaModel="updateField('anomaliaCodigoPostalSucursal', $event)"
                />
              </v-col>
            </template>

            <template v-if="editedItem.idTipoProveedor == 2">
              <v-col cols="6">
                <VTextFieldError
                  label="Calle *"
                  :value="editedItem.calleSucursal"
                  :error-state="editedItem.errorCalleSucursal"
                  @update:value="updateField('calleSucursal', $event)"
                  @update:errorState="updateField('errorCalleSucursal', $event)"
                  @update:textareaModel="updateField('anomaliaCalleSucursal', $event)"
                />
              </v-col>

              <v-col cols="3">
                <VTextFieldError
                  label="Núm Exterior *"
                  :value="editedItem.numExtSucursal"
                  :error-state="editedItem.errorNumExtSucursal"
                  @update:value="updateField('numExtSucursal', $event)"
                  @update:errorState="updateField('errorNumExtSucursal', $event)"
                  @update:textareaModel="updateField('anomaliaNumExtSucursal', $event)"
                />
              </v-col>

              <v-col cols="3">
                <VTextFieldError
                  label="Núm Interior"
                  :value="editedItem.numIntSucursal"
                  :error-state="editedItem.errorNumIntSucursal"
                  @update:value="updateField('numIntSucursal', $event)"
                  @update:errorState="updateField('errorNumIntSucursal', $event)"
                  @update:textareaModel="updateField('anomaliaNumIntSucursal', $event)"
                />
              </v-col>

              <v-col cols="12" md="6">
                <VTextFieldError
                  label="Código Postal *"
                  :value="editedItem.codigoPostalSucursal"
                  :error-state="editedItem.errorCodigoPostalSucursal"
                  @update:value="updateField('codigoPostalSucursal', $event)"
                  @update:errorState="updateField('errorCodigoPostalSucursal', $event)"
                  @update:textareaModel="updateField('anomaliaCodigoPostalSucursal', $event)"
                />
              </v-col>

              <v-col cols="12" md="6">
                <VTextFieldError
                  label="Ciudad *"
                  :value="editedItem.ciudadSucursal"
                  :error-state="editedItem.errorCiudadSucursal"
                  @update:value="updateField('ciudadSucursal', $event)"
                  @update:errorState="updateField('errorCiudadSucursal', $event)"
                  @update:textareaModel="updateField('anomaliaCiudadSucursal', $event)"
                />
              </v-col>

              <v-col cols="12" md="6">
                <VTextFieldError
                  label="Región/Estado/Provincia *"
                  :value="editedItem.regionSucursal"
                  :error-state="editedItem.errorRegionSucursal"
                  @update:value="updateField('regionSucursal', $event)"
                  @update:errorState="updateField('errorRegionSucursal', $event)"
                  @update:textareaModel="updateField('anomaliaRegionSucursal', $event)"
                />
              </v-col>

              <v-col cols="12" md="6">
                <VAutocompleteError
                  :items="paises"
                  label="País *"
                  item-value="id_pais"
                  item-text="nombre"
                  :value="editedItem.idPaisSucursal"
                  :error-state="editedItem.errorPaisSucursal"
                  @update:value="updateField('idPaisSucursal', $event)"
                  @update:errorState="updateField('errorIdPaisSucursal', $event)"
                  @update:textareaModel="updateField('anomaliaIdPaisSucursal', $event)"
                />
              </v-col>
            </template>
          </v-row>

          <br />
          <v-divider></v-divider>
          <br />

          <!-- Cuenta Bancaria -->
          <v-row>
            <v-col cols="12" class="text-center">
              <h2>
                Cuenta Bancaria
                {{ editedItem.idTipoProveedor == 1
                  ? "Nacional"
                  : editedItem.idTipoProveedor == 2
                  ? "Internacional"
                  : "" }}
              </h2>
            </v-col>
          </v-row>

          <v-row>
            <!-- Cuenta Bancaria Nacional -->
            <template v-if="editedItem.idTipoProveedor == 1">
              <v-col cols="6">
                <VAutocompleteError
                  label="Banco *"
                  :items="bancos"
                  item-value="id_banco"
                  item-text="nombre"
                  :value="editedItem.idBanco"
                  :error-state="editedItem.errorIdBanco"
                  @update:value="updateField('idBanco', $event)"
                  @update:errorState="updateField('errorIdBanco', $event)"
                  @update:textareaModel="updateField('anomaliaIdBanco', $event)"
                />
              </v-col>

              <v-col cols="6">
                <VTextFieldError
                  label="Nombre del Beneficiario *"
                  :value="editedItem.nombreBeneficiario"
                  :error-state="editedItem.errorNombreBeneficiario"
                  @update:value="updateField('nombreBeneficiario', $event)"
                  @update:errorState="updateField('errorNombreBeneficiario', $event)"
                  @update:textareaModel="updateField('anomaliaNombreBeneficiario', $event)"
                />
              </v-col>

              <v-col cols="4">
                <VTextFieldError
                  label="CLABE Interbancaria *"
                  :value="editedItem.clabe"
                  counter
                  maxlength="18"
                  :error-state="editedItem.errorClabe"
                  @update:value="updateField('clabe', $event)"
                  @update:errorState="updateField('errorClabe', $event)"
                  @update:textareaModel="updateField('anomaliaClabe', $event)"
                />
              </v-col>

              <v-col cols="4">
                <VTextFieldError
                  label="Cuenta *"
                  :value="editedItem.cuenta"
                  counter
                  maxlength="10"
                  :error-state="editedItem.errorCuenta"
                  @update:value="updateField('cuenta', $event)"
                  @update:errorState="updateField('errorCuenta', $event)"
                  @update:textareaModel="updateField('anomaliaCuenta', $event)"
                />
              </v-col>

              <v-col cols="4">
                <VSelectError
                  label="Moneda *"
                  :items="monedas"
                  item-value="id_moneda"
                  item-text="nombre"
                  :value="editedItem.idMoneda"
                  :error-state="editedItem.errorIdMoneda"
                  @update:value="updateField('idMoneda', $event)"
                  @update:errorState="updateField('errorIdMoneda', $event)"
                  @update:textareaModel="updateField('anomaliaIdMoneda', $event)"
                />
              </v-col>

              <v-col cols="6">
                <VTextFieldError
                  label="CIE"
                  :value="editedItem.cie"
                  :error-state="editedItem.errorCie"
                  @update:value="updateField('cie', $event)"
                  @update:errorState="updateField('errorCie', $event)"
                  @update:textareaModel="updateField('anomaliaCie', $event)"
                />
              </v-col>

              <v-col cols="6">
                <VTextFieldError
                  label="Referencia"
                  :value="editedItem.referencia"
                  :error-state="editedItem.errorReferencia"
                  @update:value="updateField('referencia', $event)"
                  @update:errorState="updateField('errorReferencia', $event)"
                  @update:textareaModel="updateField('anomaliaReferencia', $event)"
                />
              </v-col>
            </template>

            <!-- Cuenta Bancaria Internacional -->
            <template v-if="editedItem.idTipoProveedor == 2">
              <v-col cols="6">
                <VTextFieldError
                  label="Banco *"
                  :value="editedItem.banco"
                  :error-state="editedItem.errorBanco"
                  @update:value="updateField('banco', $event)"
                  @update:errorState="updateField('errorBanco', $event)"
                  @update:textareaModel="updateField('anomaliaBanco', $event)"
                />
              </v-col>

              <v-col cols="6">
                <VTextFieldError
                  label="Nombre del Beneficiario *"
                  :value="editedItem.nombreBeneficiario"
                  :error-state="editedItem.errorNombreBeneficiario"
                  @update:value="updateField('nombreBeneficiario', $event)"
                  @update:errorState="updateField('errorNombreBeneficiario', $event)"
                  @update:textareaModel="updateField('anomaliaNombreBeneficiario', $event)"
                />
              </v-col>

              <v-col cols="5">
                <VTextFieldError
                  label="Clave Internacional *"
                  :value="editedItem.claveInt"
                  :error-state="editedItem.errorClaveInt"
                  @update:value="updateField('claveInt', $event)"
                  @update:errorState="updateField('errorClaveInt', $event)"
                  @update:textareaModel="updateField('anomaliaClaveInt', $event)"
                />
              </v-col>

              <v-col cols="5">
                <VTextFieldError
                  label="Cuenta *"
                  :value="editedItem.cuenta"
                  :error-state="editedItem.errorCuenta"
                  @update:value="updateField('cuenta', $event)"
                  @update:errorState="updateField('errorCuenta', $event)"
                  @update:textareaModel="updateField('anomaliaCuenta', $event)"
                />
              </v-col>

              <v-col cols="2">
                <VSelectError
                  label="Moneda *"
                  :items="monedas"
                  item-value="valor"
                  item-text="nombre"
                  :value="editedItem.idMoneda"
                  :error-state="editedItem.errorIdMoneda"
                  @update:value="updateField('idMoneda', $event)"
                  @update:errorState="updateField('errorIdMoneda', $event)"
                  @update:textareaModel="updateField('anomaliaIdMoneda', $event)"
                />
              </v-col>

              <v-col cols="6">
                <VTextFieldError
                  label="SWIFT"
                  :value="editedItem.swift"
                  :error-state="editedItem.errorSwift"
                  @update:value="updateField('swift', $event)"
                  @update:errorState="updateField('errorSwift', $event)"
                  @update:textareaModel="updateField('anomaliaSwift', $event)"
                />
              </v-col>

              <v-col cols="6">
                <VTextFieldError
                  label="ISBN"
                  :value="editedItem.isbn"
                  :error-state="editedItem.errorIsbn"
                  @update:value="updateField('isbn', $event)"
                  @update:errorState="updateField('errorIsbn', $event)"
                  @update:textareaModel="updateField('anomaliaIsbn', $event)"
                />
              </v-col>
            </template>
          </v-row>

          <br />
          <v-divider></v-divider>
          <br />

          <!-- Documentos Adjuntos -->
          <v-row>
            <v-col cols="12" class="text-center">
              <h2>Documentos Adjuntos</h2>
            </v-col>
          </v-row>

          <v-row>
            <!-- Documentos para Proveedor Nacional / Persona Física -->
            <template v-if="editedItem.idTipoProveedor == 1 && editedItem.idTipoPersona == 1">
              <v-col cols="6">
                <v-row class="pa-0 ma-0">
                  <v-col cols="12" class="ma-0 pa-0">
                    <VFileInputError
                      downloadUrl="/api/Proveedores/DescargarDocumento"
                      :token="token"
                      accept=".jpg,.jpeg,.png,.pdf"
                      label="Currículum Vitae empresarial *"
                      :value="editedItem.curriculumVitaeEmpresarial"
                      :error-state="editedItem.errorCurriculumVitaeEmpresarial"
                      @update:value="updateField('curriculumVitaeEmpresarial', $event)"
                      @update:errorState="updateField('errorCurriculumVitaeEmpresarial', $event)"
                      @update:textareaModel="updateField('anomaliaCurriculumVitaeEmpresarial', $event)"
                    />
                  </v-col>

                  <v-col cols="12" class="ma-0 pa-0">
                    <VFileInputError
                      downloadUrl="/api/Proveedores/DescargarDocumento"
                      :token="token"
                      accept=".jpg,.jpeg,.png,.pdf"
                      label="Constancia de situación fiscal *"
                      :value="editedItem.situacionFiscal"
                      :error-state="editedItem.errorSituacionFiscal"
                      @update:value="updateField('situacionFiscal', $event)"
                      @update:errorState="updateField('errorSituacionFiscal', $event)"
                      @update:textareaModel="updateField('anomaliaSituacionFiscal', $event)"
                    />
                    <div class="text-caption note-style">Nota: Emitida en el mes que solicita el registro.</div>
                  </v-col>

                  <v-col cols="12" class="ma-0 pa-0">
                    <VFileInputError
                      downloadUrl="/api/Proveedores/DescargarDocumento"
                      :token="token"
                      accept=".jpg,.jpeg,.png,.pdf"
                      label="Opinión de cumplimiento positiva de obligaciones fiscales *"
                      :value="editedItem.opinionCumplimiento"
                      :error-state="editedItem.errorOpinionCumplimiento"
                      @update:value="updateField('opinionCumplimiento', $event)"
                      @update:errorState="updateField('errorOpinionCumplimiento', $event)"
                      @update:textareaModel="updateField('anomaliaOpinionCumplimiento', $event)"
                    />
                    <div class="text-caption note-style">Nota: Emitida en el mes que solicita el registro.</div>
                  </v-col>

                  <v-col cols="12" class="ma-0 pa-0">
                    <VFileInputError
                      downloadUrl="/api/Proveedores/DescargarDocumento"
                      :token="token"
                      accept=".jpg,.jpeg,.png,.pdf"
                      label="Copia de la carátula del estado de cuenta *"
                      :value="editedItem.caratulaEstadoCuenta"
                      :error-state="editedItem.errorCaratulaEstadoCuenta"
                      @update:value="updateField('caratulaEstadoCuenta', $event)"
                      @update:errorState="updateField('errorCaratulaEstadoCuenta', $event)"
                      @update:textareaModel="updateField('anomaliaCaratulaEstadoCuenta', $event)"
                    />
                    <div class="text-caption note-style">
                      En la portada, se debe incluir la siguiente información: nombre del titular de la cuenta, número de cuenta, CLABE interbancaria, moneda, sucursal, plaza e institución bancaria, y/o contrato de apertura. Además, debe incluirse la leyenda al margen: "Ratifico ser el titular de esta cuenta y me responsabilizo de la información proporcionada", junto con la firma correspondiente.
                    </div>
                  </v-col>

                  <v-col cols="12" class="ma-0 pa-0" v-if="editedItem.certificado1 != null">
                    <VFileInputError
                      downloadUrl="/api/Proveedores/DescargarDocumento"
                      :token="token"
                      accept=".jpg,.jpeg,.png,.pdf"
                      label="Certificado 1"
                      :value="editedItem.certificado1"
                      :error-state="editedItem.errorCertificado1"
                      @update:value="updateField('certificado1', $event)"
                      @update:errorState="updateField('errorCertificado1', $event)"
                      @update:textareaModel="updateField('anomaliaCertificado1', $event)"
                    />
                  </v-col>

                  <v-col cols="12" class="ma-0 pa-0" v-if="editedItem.certificado2 != null">
                    <VFileInputError
                      downloadUrl="/api/Proveedores/DescargarDocumento"
                      :token="token"
                      accept=".jpg,.jpeg,.png,.pdf"
                      label="Certificado 2"
                      :value="editedItem.certificado2"
                      :error-state="editedItem.errorCertificado2"
                      @update:value="updateField('certificado2', $event)"
                      @update:errorState="updateField('errorCertificado2', $event)"
                      @update:textareaModel="updateField('anomaliaCertificado2', $event)"
                    />
                  </v-col>

                  <v-col cols="12" class="ma-0 pa-0" v-if="editedItem.certificado3 != null">
                    <VFileInputError
                      downloadUrl="/api/Proveedores/DescargarDocumento"
                      :token="token"
                      accept=".jpg,.jpeg,.png,.pdf"
                      label="Certificado 3"
                      :value="editedItem.certificado3"
                      :error-state="editedItem.errorCertificado3"
                      @update:value="updateField('certificado3', $event)"
                      @update:errorState="updateField('errorCertificado3', $event)"
                      @update:textareaModel="updateField('anomaliaCertificado3', $event)"
                    />
                  </v-col>

                  <template v-if="editedItem.tipoProveedorNacional == 2">
                    <v-col cols="12" class="ma-0 pa-0">
                      <VFileInputError
                        downloadUrl="/api/Proveedores/DescargarDocumento"
                        :token="token"
                        accept=".jpg,.jpeg,.png,.pdf"
                        label="Póliza de responsabilidad civil medica *"
                        :value="editedItem.polizaResponsabilidadCivilMedica"
                        :error-state="editedItem.errorPolizaResponsabilidadCivilMedica"
                        @update:value="updateField('polizaResponsabilidadCivilMedica', $event)"
                        @update:errorState="updateField('errorPolizaResponsabilidadCivilMedica', $event)"
                        @update:textareaModel="updateField('anomaliaPolizaResponsabilidadCivilMedica', $event)"
                      />
                    </v-col>

                    <v-col cols="12" class="ma-0 pa-0">
                      <VFileInputError
                        downloadUrl="/api/Proveedores/DescargarDocumento"
                        :token="token"
                        accept=".jpg,.jpeg,.png,.pdf"
                        label="Copia de título de Medico *"
                        :value="editedItem.copiaTituloMedico"
                        :error-state="editedItem.errorCopiaTituloMedico"
                        @update:value="updateField('copiaTituloMedico', $event)"
                        @update:errorState="updateField('errorCopiaTituloMedico', $event)"
                        @update:textareaModel="updateField('anomaliaCopiaTituloMedico', $event)"
                      />
                    </v-col>

                    <v-col cols="12" class="ma-0 pa-0">
                      <VFileInputError
                        downloadUrl="/api/Proveedores/DescargarDocumento"
                        :token="token"
                        accept=".jpg,.jpeg,.png,.pdf"
                        label="Copia de Cedula profesional como Medico *"
                        :value="editedItem.cedulaProfesionalMedico"
                        :error-state="editedItem.errorCedulaProfesionalMedico"
                        @update:value="updateField('cedulaProfesionalMedico', $event)"
                        @update:errorState="updateField('errorCedulaProfesionalMedico', $event)"
                        @update:textareaModel="updateField('anomaliaCedulaProfesionalMedico', $event)"
                      />
                    </v-col>

                    <v-col cols="12" class="ma-0 pa-0">
                      <VFileInputError
                        downloadUrl="/api/Proveedores/DescargarDocumento"
                        :token="token"
                        accept=".jpg,.jpeg,.png,.pdf"
                        label="Copia del diploma e especialidad *"
                        :value="editedItem.diplomaEspecialidad"
                        :error-state="editedItem.errorDiplomaEspecialidad"
                        @update:value="updateField('diplomaEspecialidad', $event)"
                        @update:errorState="updateField('errorDiplomaEspecialidad', $event)"
                        @update:textareaModel="updateField('anomaliaDiplomaEspecialidad', $event)"
                      />
                    </v-col>

                    <v-col cols="12" class="ma-0 pa-0">
                      <VFileInputError
                        downloadUrl="/api/Proveedores/DescargarDocumento"
                        :token="token"
                        accept=".jpg,.jpeg,.png,.pdf"
                        label="Copia de la cedula de radiología *"
                        :value="editedItem.cedulaRadiologia"
                        :error-state="editedItem.errorCedulaRadiologia"
                        @update:value="updateField('cedulaRadiologia', $event)"
                        @update:errorState="updateField('errorCedulaRadiologia', $event)"
                        @update:textareaModel="updateField('anomaliaCedulaRadiologia', $event)"
                      />
                    </v-col>
                  </template>
                </v-row>
              </v-col>

              <v-col cols="6">
                <v-row class="ma-0 pa-0">
                  <v-col cols="12" class="ma-0 pa-0">
                    <VFileInputError
                      downloadUrl="/api/Proveedores/DescargarDocumento"
                      :token="token"
                      accept=".jpg,.jpeg,.png,.pdf"
                      label="Copia simple de identificación oficial *"
                      :value="editedItem.identificacionOficial"
                      :error-state="editedItem.errorIdentificacionOficial"
                      @update:value="updateField('identificacionOficial', $event)"
                      @update:errorState="updateField('errorIdentificacionOficial', $event)"
                      @update:textareaModel="updateField('anomaliaIdentificacionOficial', $event)"
                    />
                  </v-col>

                  <v-col cols="12" class="ma-0 pa-0">
                    <VFileInputError
                      downloadUrl="/api/Proveedores/DescargarDocumento"
                      :token="token"
                      accept=".jpg,.jpeg,.png,.pdf"
                      label="Copia simple de comprobante domicilio *"
                      :value="editedItem.comprobanteDomicilio"
                      :error-state="editedItem.errorComprobanteDomicilio"
                      @update:value="updateField('comprobanteDomicilio', $event)"
                      @update:errorState="updateField('errorComprobanteDomicilio', $event)"
                      @update:textareaModel="updateField('anomaliaComprobanteDomicilio', $event)"
                    />
                    <div class="text-caption note-style">Nota: No mayor a 3 meses.</div>
                  </v-col>

                  <v-col cols="12" class="ma-0 pa-0">
                    <VFileInputError
                      downloadUrl="/api/Proveedores/DescargarDocumento"
                      :token="token"
                      accept=".jpg,.jpeg,.png,.pdf"
                      label="Carta de referencia comercial 1 *"
                      :value="editedItem.referenciaComercial1"
                      :error-state="editedItem.errorReferenciaComercial1"
                      @update:value="updateField('referenciaComercial1', $event)"
                      @update:errorState="updateField('errorReferenciaComercial1', $event)"
                      @update:textareaModel="updateField('anomaliaReferenciaComercial1', $event)"
                    />
                    <div class="text-caption note-style">Nota: En papel membretado (no mayor a 3 meses).</div>
                  </v-col>

                  <v-col cols="12" class="ma-0 pa-0">
                    <VFileInputError
                      downloadUrl="/api/Proveedores/DescargarDocumento"
                      :token="token"
                      accept=".jpg,.jpeg,.png,.pdf"
                      label="Carta de referencia comercial 2 *"
                      :value="editedItem.referenciaComercial2"
                      :error-state="editedItem.errorReferenciaComercial2"
                      @update:value="updateField('referenciaComercial2', $event)"
                      @update:errorState="updateField('errorReferenciaComercial2', $event)"
                      @update:textareaModel="updateField('anomaliaReferenciaComercial2', $event)"
                    />
                    <div class="text-caption note-style">Nota: En papel membretado (no mayor a 3 meses).</div>
                  </v-col>

                  <v-col cols="12" class="ma-0 pa-0">
                    <VFileInputError
                      downloadUrl="/api/Proveedores/DescargarDocumento"
                      :token="token"
                      accept=".jpg,.jpeg,.png,.pdf"
                      label="Copia de la certificación, licencia o permiso *"
                      :value="editedItem.certificacion"
                      :error-state="editedItem.errorCertificacion"
                      @update:value="updateField('certificacion', $event)"
                      @update:errorState="updateField('errorCertificacion', $event)"
                      @update:textareaModel="updateField('anomaliaCertificacion', $event)"
                    />
                    <div class="text-caption note-style">Nota: Asegúrate que el documento esté vigente.</div>
                  </v-col>

                  <v-col cols="12" class="ma-0 pa-0">
                    <VFileInputError
                      downloadUrl="/api/Proveedores/DescargarDocumento"
                      :token="token"
                      accept=".jpg,.jpeg,.png,.pdf"
                      label="Fotografía del producto o servicio 1 *"
                      :value="editedItem.fotografiaProducto1"
                      :error-state="editedItem.errorFotografiaProducto1"
                      @update:value="updateField('fotografiaProducto1', $event)"
                      @update:errorState="updateField('errorFotografiaProducto1', $event)"
                      @update:textareaModel="updateField('anomaliaFotografiaProducto1', $event)"
                    />
                  </v-col>

                  <v-col cols="12" class="ma-0 pa-0">
                    <VFileInputError
                      downloadUrl="/api/Proveedores/DescargarDocumento"
                      :token="token"
                      accept=".jpg,.jpeg,.png,.pdf"
                      label="Fotografía del producto o servicio 2 *"
                      :value="editedItem.fotografiaProducto2"
                      :error-state="editedItem.errorFotografiaProducto2"
                      @update:value="updateField('fotografiaProducto2', $event)"
                      @update:errorState="updateField('errorFotografiaProducto2', $event)"
                      @update:textareaModel="updateField('anomaliaFotografiaProducto2', $event)"
                    />
                  </v-col>

                  <v-col cols="12" class="ma-0 pa-0">
                    <VFileInputError
                      downloadUrl="/api/Proveedores/DescargarDocumento"
                      :token="token"
                      accept=".jpg,.jpeg,.png,.pdf"
                      label="Fotografía del producto o servicio 3 *"
                      :value="editedItem.fotografiaProducto3"
                      :error-state="editedItem.errorFotografiaProducto3"
                      @update:value="updateField('fotografiaProducto3', $event)"
                      @update:errorState="updateField('errorFotografiaProducto3', $event)"
                      @update:textareaModel="updateField('anomaliaFotografiaProducto3', $event)"
                    />
                  </v-col>

                  <v-col cols="12" class="ma-0 pa-0">
                    <VFileInputError
                      downloadUrl="/api/Proveedores/DescargarDocumento"
                      :token="token"
                      accept=".jpg,.jpeg,.png,.pdf"
                      label="Fotografía del producto o servicio 4 *"
                      :value="editedItem.fotografiaProducto4"
                      :error-state="editedItem.errorFotografiaProducto4"
                      @update:value="updateField('fotografiaProducto4', $event)"
                      @update:errorState="updateField('errorFotografiaProducto4', $event)"
                      @update:textareaModel="updateField('anomaliaFotografiaProducto4', $event)"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </template>

            <!-- Documentos para Proveedor Nacional / Persona Moral -->
            <template v-else-if="editedItem.idTipoProveedor == 1 && editedItem.idTipoPersona == 2">
              <v-col cols="6">
                <v-row class="ma-0 pa-0">
                  <v-col cols="12" class="ma-0 pa-0">
                    <VFileInputError
                      downloadUrl="/api/Proveedores/DescargarDocumento"
                      :token="token"
                      accept=".jpg,.jpeg,.png,.pdf"
                      label="Currículum de la empresa *"
                      :value="editedItem.curriculumEmpresa"
                      :error-state="editedItem.errorCurriculumEmpresa"
                      @update:value="updateField('curriculumEmpresa', $event)"
                      @update:errorState="updateField('errorCurriculumEmpresa', $event)"
                      @update:textareaModel="updateField('anomaliaCurriculumEmpresa', $event)"
                    />
                  </v-col>

                  <v-col cols="12" class="ma-0 pa-0">
                    <VFileInputError
                      downloadUrl="/api/Proveedores/DescargarDocumento"
                      :token="token"
                      accept=".jpg,.jpeg,.png,.pdf"
                      label="Copia simple del acta constitutiva y actas modificatorias *"
                      :value="editedItem.actaConstitutiva"
                      :error-state="editedItem.errorActaConstitutiva"
                      @update:value="updateField('actaConstitutiva', $event)"
                      @update:errorState="updateField('errorActaConstitutiva', $event)"
                      @update:textareaModel="updateField('anomaliaActaConstitutiva', $event)"
                    />
                  </v-col>

                  <v-col cols="12" class="ma-0 pa-0">
                    <VFileInputError
                      downloadUrl="/api/Proveedores/DescargarDocumento"
                      :token="token"
                      accept=".jpg,.jpeg,.png,.pdf"
                      label="Copia simple del poder notariado del representante legal *"
                      :value="editedItem.poderRepresentante"
                      :error-state="editedItem.errorPoderRepresentante"
                      @update:value="updateField('poderRepresentante', $event)"
                      @update:errorState="updateField('errorPoderRepresentante', $event)"
                      @update:textareaModel="updateField('anomaliaPoderRepresentante', $event)"
                    />
                  </v-col>

                  <v-col cols="12" class="ma-0 pa-0">
                    <VFileInputError
                      downloadUrl="/api/Proveedores/DescargarDocumento"
                      :token="token"
                      accept=".jpg,.jpeg,.png,.pdf"
                      label="Copia de la carátula del estado de cuenta *"
                      :value="editedItem.caratulaEstadoCuenta"
                      :error-state="editedItem.errorCaratulaEstadoCuenta"
                      @update:value="updateField('caratulaEstadoCuenta', $event)"
                      @update:errorState="updateField('errorCaratulaEstadoCuenta', $event)"
                      @update:textareaModel="updateField('anomaliaCaratulaEstadoCuenta', $event)"
                    />
                    <div class="text-caption note-style">
                      En la portada, se debe incluir la siguiente información: nombre del titular de la cuenta, número de cuenta, CLABE interbancaria, moneda, sucursal, plaza e institución bancaria, y/o contrato de apertura. Además, debe incluirse la leyenda al margen: "Ratifico ser el titular de esta cuenta y me responsabilizo de la información proporcionada", junto con la firma correspondiente.
                    </div>
                  </v-col>

                  <v-col cols="12" class="ma-0 pa-0">
                    <VFileInputError
                      downloadUrl="/api/Proveedores/DescargarDocumento"
                      :token="token"
                      accept=".jpg,.jpeg,.png,.pdf"
                      label="Constancia de situación fiscal *"
                      :value="editedItem.situacionFiscal"
                      :error-state="editedItem.errorSituacionFiscal"
                      @update:value="updateField('situacionFiscal', $event)"
                      @update:errorState="updateField('errorSituacionFiscal', $event)"
                      @update:textareaModel="updateField('anomaliaSituacionFiscal', $event)"
                    />
                    <div class="text-caption note-style">Nota: Emitida en el mes que solicita el registro.</div>
                  </v-col>

                  <v-col cols="12" class="ma-0 pa-0">
                    <VFileInputError
                      downloadUrl="/api/Proveedores/DescargarDocumento"
                      :token="token"
                      accept=".jpg,.jpeg,.png,.pdf"
                      label="Opinión de cumplimiento positiva de obligaciones fiscales *"
                      :value="editedItem.opinionCumplimiento"
                      :error-state="editedItem.errorOpinionCumplimiento"
                      @update:value="updateField('opinionCumplimiento', $event)"
                      @update:errorState="updateField('errorOpinionCumplimiento', $event)"
                      @update:textareaModel="updateField('anomaliaOpinionCumplimiento', $event)"
                    />
                    <div class="text-caption note-style">Nota: Emitida en el mes que solicita el registro.</div>
                  </v-col>

                  <v-col cols="12" class="ma-0 pa-0" v-if="editedItem.certificado1 != null">
                    <VFileInputError
                      downloadUrl="/api/Proveedores/DescargarDocumento"
                      :token="token"
                      accept=".jpg,.jpeg,.png,.pdf"
                      label="Certificado 1"
                      :value="editedItem.certificado1"
                      :error-state="editedItem.errorCertificado1"
                      @update:value="updateField('certificado1', $event)"
                      @update:errorState="updateField('errorCertificado1', $event)"
                      @update:textareaModel="updateField('anomaliaCertificado1', $event)"
                    />
                  </v-col>

                  <v-col cols="12" class="ma-0 pa-0" v-if="editedItem.certificado2 != null">
                    <VFileInputError
                      downloadUrl="/api/Proveedores/DescargarDocumento"
                      :token="token"
                      accept=".jpg,.jpeg,.png,.pdf"
                      label="Certificado 2"
                      :value="editedItem.certificado2"
                      :error-state="editedItem.errorCertificado2"
                      @update:value="updateField('certificado2', $event)"
                      @update:errorState="updateField('errorCertificado2', $event)"
                      @update:textareaModel="updateField('anomaliaCertificado2', $event)"
                    />
                  </v-col>

                  <v-col cols="12" class="ma-0 pa-0" v-if="editedItem.certificado3 != null">
                    <VFileInputError
                      downloadUrl="/api/Proveedores/DescargarDocumento"
                      :token="token"
                      accept=".jpg,.jpeg,.png,.pdf"
                      label="Certificado 3"
                      :value="editedItem.certificado3"
                      :error-state="editedItem.errorCertificado3"
                      @update:value="updateField('certificado3', $event)"
                      @update:errorState="updateField('errorCertificado3', $event)"
                      @update:textareaModel="updateField('anomaliaCertificado3', $event)"
                    />
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="6">
                <v-row class="ma-0 pa-0">
                  <v-col cols="12" class="ma-0 pa-0">
                    <VFileInputError
                      downloadUrl="/api/Proveedores/DescargarDocumento"
                      :token="token"
                      accept=".jpg,.jpeg,.png,.pdf"
                      label="Copia simple de identificación oficial del representante legal *"
                      :value="editedItem.identificacionRepresentante"
                      :error-state="editedItem.errorIdentificacionRepresentante"
                      @update:value="updateField('identificacionRepresentante', $event)"
                      @update:errorState="updateField('errorIdentificacionRepresentante', $event)"
                      @update:textareaModel="updateField('anomaliaIdentificacionRepresentante', $event)"
                    />
                  </v-col>

                  <v-col cols="12" class="ma-0 pa-0">
                    <VFileInputError
                      downloadUrl="/api/Proveedores/DescargarDocumento"
                     :token="token"
                      accept=".jpg,.jpeg,.png,.pdf"
                      label="Copia de la certificación, licencia o permiso *"
                      :value="editedItem.certificacion"
                      :error-state="editedItem.errorCertificacion"
                      @update:value="updateField('certificacion', $event)"
                      @update:errorState="updateField('errorCertificacion', $event)"
                      @update:textareaModel="updateField('anomaliaCertificacion', $event)"
                    />
                    <div class="text-caption note-style">Nota: Asegúrate que el documento esté vigente.</div>
                  </v-col>

                  <v-col cols="12" class="ma-0 pa-0">
                    <VFileInputError
                      downloadUrl="/api/Proveedores/DescargarDocumento"
                      :token="token"
                      accept=".jpg,.jpeg,.png,.pdf"
                      label="Copia simple de comprobante domicilio *"
                      :value="editedItem.comprobanteDomicilio"
                      :error-state="editedItem.errorComprobanteDomicilio"
                      @update:value="updateField('comprobanteDomicilio', $event)"
                      @update:errorState="updateField('errorComprobanteDomicilio', $event)"
                      @update:textareaModel="updateField('anomaliaComprobanteDomicilio', $event)"
                    />
                    <div class="text-caption note-style">Nota: No mayor a 3 meses.</div>
                  </v-col>

                  <v-col cols="12" class="ma-0 pa-0">
                    <VFileInputError
                      downloadUrl="/api/Proveedores/DescargarDocumento"
                      :token="token"
                      accept=".jpg,.jpeg,.png,.pdf"
                      label="Carta de referencia comercial 1 *"
                      :value="editedItem.referenciaComercial1"
                      :error-state="editedItem.errorReferenciaComercial1"
                      @update:value="updateField('referenciaComercial1', $event)"
                      @update:errorState="updateField('errorReferenciaComercial1', $event)"
                      @update:textareaModel="updateField('anomaliaReferenciaComercial1', $event)"
                    />
                    <div class="text-caption note-style">Nota: En papel membretado (no mayor a 3 meses).</div>
                  </v-col>

                  <v-col cols="12" class="ma-0 pa-0">
                    <VFileInputError
                      downloadUrl="/api/Proveedores/DescargarDocumento"
                      :token="token"
                      accept=".jpg,.jpeg,.png,.pdf"
                      label="Carta de referencia comercial 2 *"
                      :value="editedItem.referenciaComercial2"
                      :error-state="editedItem.errorReferenciaComercial2"
                      @update:value="updateField('referenciaComercial2', $event)"
                      @update:errorState="updateField('errorReferenciaComercial2', $event)"
                      @update:textareaModel="updateField('anomaliaReferenciaComercial2', $event)"
                    />
                    <div class="text-caption note-style">Nota: En papel membretado (no mayor a 3 meses).</div>
                  </v-col>

                  <template v-if="editedItem.tipoProveedorNacional == 2">
                    <v-col cols="12" class="ma-0 pa-0">
                      <VFileInputError
                        downloadUrl="/api/Proveedores/DescargarDocumento"
                        :token="token"
                        accept=".jpg,.jpeg,.png,.pdf"
                        label="Póliza de responsabilidad civil medica *"
                        :value="editedItem.polizaResponsabilidadCivilMedica"
                        :error-state="editedItem.errorPolizaResponsabilidadCivilMedica"
                        @update:value="updateField('polizaResponsabilidadCivilMedica', $event)"
                        @update:errorState="updateField('errorPolizaResponsabilidadCivilMedica', $event)"
                        @update:textareaModel="updateField('anomaliaPolizaResponsabilidadCivilMedica', $event)"
                      />
                    </v-col>

                    <v-col cols="12" class="ma-0 pa-0">
                      <VFileInputError
                        downloadUrl="/api/Proveedores/DescargarDocumento"
                        :token="token"
                        accept=".jpg,.jpeg,.png,.pdf"
                        label="Copia de título de Medico *"
                        :value="editedItem.copiaTituloMedico"
                        :error-state="editedItem.errorCopiaTituloMedico"
                        @update:value="updateField('copiaTituloMedico', $event)"
                        @update:errorState="updateField('errorCopiaTituloMedico', $event)"
                        @update:textareaModel="updateField('anomaliaCopiaTituloMedico', $event)"
                      />
                    </v-col>

                    <v-col cols="12" class="ma-0 pa-0">
                      <VFileInputError
                        downloadUrl="/api/Proveedores/DescargarDocumento"
                        :token="token"
                        accept=".jpg,.jpeg,.png,.pdf"
                        label="Copia de Cedula profesional como Medico *"
                        :value="editedItem.cedulaProfesionalMedico"
                        :error-state="editedItem.errorCedulaProfesionalMedico"
                        @update:value="updateField('cedulaProfesionalMedico', $event)"
                        @update:errorState="updateField('errorCedulaProfesionalMedico', $event)"
                        @update:textareaModel="updateField('anomaliaCedulaProfesionalMedico', $event)"
                      />
                    </v-col>

                    <v-col cols="12" class="ma-0 pa-0">
                      <VFileInputError
                        downloadUrl="/api/Proveedores/DescargarDocumento"
                        :token="token"
                        accept=".jpg,.jpeg,.png,.pdf"
                        label="Copia del diploma e especialidad *"
                        :value="editedItem.diplomaEspecialidad"
                        :error-state="editedItem.errorDiplomaEspecialidad"
                        @update:value="updateField('diplomaEspecialidad', $event)"
                        @update:errorState="updateField('errorDiplomaEspecialidad', $event)"
                        @update:textareaModel="updateField('anomaliaDiplomaEspecialidad', $event)"
                      />
                    </v-col>

                    <v-col cols="12" class="ma-0 pa-0">
                      <VFileInputError
                        downloadUrl="/api/Proveedores/DescargarDocumento"
                        :token="token"
                        accept=".jpg,.jpeg,.png,.pdf"
                        label="Copia de la cedula de radiología *"
                        :value="editedItem.cedulaRadiologia"
                        :error-state="editedItem.errorCedulaRadiologia"
                        @update:value="updateField('cedulaRadiologia', $event)"
                        @update:errorState="updateField('errorCedulaRadiologia', $event)"
                        @update:textareaModel="updateField('anomaliaCedulaRadiologia', $event)"
                      />
                    </v-col>
                  </template>
                </v-row>
              </v-col>
            </template>

            <!-- Documentos para Proveedor Internacional / Persona Física -->
            <template v-else-if="editedItem.idTipoProveedor == 2 && editedItem.idTipoPersona == 1">
              <v-col cols="6">
                <v-row class="ma-0 pa-0">
                  <v-col cols="12" class="ma-0 pa-0">
                    <VFileInputError
                      downloadUrl="/api/Proveedores/DescargarDocumento"
                      :token="token"
                      accept=".jpg,.jpeg,.png,.pdf"
                      label="Cédula de identificación fiscal *"
                      :value="editedItem.cedulaIdentificacionFiscal"
                      :error-state="editedItem.errorCedulaIdentificacionFiscal"
                      @update:value="updateField('cedulaIdentificacionFiscal', $event)"
                      @update:errorState="updateField('errorCedulaIdentificacionFiscal', $event)"
                      @update:textareaModel="updateField('anomaliaCedulaIdentificacionFiscal', $event)"
                    />
                  </v-col>

                  <v-col cols="12" class="ma-0 pa-0">
                    <VFileInputError
                      downloadUrl="/api/Proveedores/DescargarDocumento"
                      :token="token"
                      accept=".jpg,.jpeg,.png,.pdf"
                      label="Copia de la carátula del estado de cuenta *"
                      :value="editedItem.caratulaEstadoCuenta"
                      :error-state="editedItem.errorCaratulaEstadoCuenta"
                      @update:value="updateField('caratulaEstadoCuenta', $event)"
                      @update:errorState="updateField('errorCaratulaEstadoCuenta', $event)"
                      @update:textareaModel="updateField('anomaliaCaratulaEstadoCuenta', $event)"
                    />
                    <div class="text-caption note-style">
                      En la portada, se debe incluir la siguiente información: nombre del titular de la cuenta, número de cuenta, CLABE interbancaria, moneda, sucursal, plaza e institución bancaria, y/o contrato de apertura. Además, debe incluirse la leyenda al margen: "Ratifico ser el titular de esta cuenta y me responsabilizo de la información proporcionada", junto con la firma correspondiente.
                    </div>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="6">
                <v-row class="ma-0 pa-0">
                  <v-col cols="12" class="ma-0 pa-0">
                    <VFileInputError
                      downloadUrl="/api/Proveedores/DescargarDocumento"
                      :token="token"
                      accept=".jpg,.jpeg,.png,.pdf"
                      label="Identificación oficial con fotografía del país de origen *"
                      :value="editedItem.identificacionOficial"
                      :error-state="editedItem.errorIdentificacionOficial"
                      @update:value="updateField('identificacionOficial', $event)"
                      @update:errorState="updateField('errorIdentificacionOficial', $event)"
                      @update:textareaModel="updateField('anomaliaIdentificacionOficial', $event)"
                    />
                  </v-col>

                  <v-col cols="12" class="ma-0 pa-0">
                    <VFileInputError
                      downloadUrl="/api/Proveedores/DescargarDocumento"
                      :token="token"
                      accept=".jpg,.jpeg,.png,.pdf"
                      label="Clave única de registro de población *"
                      :value="editedItem.curp"
                      :error-state="editedItem.errorCurp"
                      @update:value="updateField('curp', $event)"
                      @update:errorState="updateField('errorCurp', $event)"
                      @update:textareaModel="updateField('anomaliaCurp', $event)"
                    />
                    <div class="text-caption note-style">Nota: Si existe en el país de origen.</div>
                  </v-col>

                  <v-col cols="12" class="ma-0 pa-0">
                    <VFileInputError
                      downloadUrl="/api/Proveedores/DescargarDocumento"
                      :token="token"
                      accept=".jpg,.jpeg,.png,.pdf"
                      label="Solicitud de certificación del medio de identificación electrónica para personas físicas extranjeras *"
                      :value="editedItem.solicitudCertificacion"
                      :error-state="editedItem.errorSolicitudCertificacion"
                      @update:value="updateField('solicitudCertificacion', $event)"
                      @update:errorState="updateField('errorSolicitudCertificacion', $event)"
                      @update:textareaModel="updateField('anomaliaSolicitudCertificacion', $event)"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </template>

            <!-- Documentos para Proveedor Internacional / Persona Moral -->
            <template v-else-if="editedItem.idTipoProveedor == 2 && editedItem.idTipoPersona == 2">
              <v-col cols="6">
                <v-row class="ma-0 pa-0">
                  <v-col cols="12" class="ma-0 pa-0">
                    <VFileInputError
                      downloadUrl="/api/Proveedores/DescargarDocumento"
                      :token="token"
                      accept=".jpg,.jpeg,.png,.pdf"
                      label="Cédula de identificación fiscal de la persona moral *"
                      :value="editedItem.cedulaFiscalMoral"
                      :error-state="editedItem.errorCedulaFiscalMoral"
                      @update:value="updateField('cedulaFiscalMoral', $event)"
                      @update:errorState="updateField('errorCedulaFiscalMoral', $event)"
                      @update:textareaModel="updateField('anomaliaCedulaFiscalMoral', $event)"
                    />
                  </v-col>

                  <v-col cols="12" class="ma-0 pa-0">
                    <VFileInputError
                      downloadUrl="/api/Proveedores/DescargarDocumento"
                      :token="token"
                      accept=".jpg,.jpeg,.png,.pdf"
                      label="Testimonio de la escritura pública *"
                      :value="editedItem.testimonioEscritura"
                      :error-state="editedItem.errorTestimonioEscritura"
                      @update:value="updateField('testimonioEscritura', $event)"
                      @update:errorState="updateField('errorTestimonioEscritura', $event)"
                      @update:textareaModel="updateField('anomaliaTestimonioEscritura', $event)"
                    />
                    <div class="text-caption note-style">
                      Nota: Debe presentarse debidamente apostillada o legalizada y en el idioma español.
                    </div>
                  </v-col>

                  <v-col cols="12" class="ma-0 pa-0">
                    <VFileInputError
                      downloadUrl="/api/Proveedores/DescargarDocumento"
                      :token="token"
                      accept=".jpg,.jpeg,.png,.pdf"
                      label="Copia de la carátula del estado de cuenta *"
                      :value="editedItem.caratulaEstadoCuenta"
                      :error-state="editedItem.errorCaratulaEstadoCuenta"
                      @update:value="updateField('caratulaEstadoCuenta', $event)"
                      @update:errorState="updateField('errorCaratulaEstadoCuenta', $event)"
                      @update:textareaModel="updateField('anomaliaCaratulaEstadoCuenta', $event)"
                    />
                    <div class="text-caption note-style">
                      En la portada, se debe incluir la siguiente información: nombre del titular de la cuenta, número de cuenta, CLABE interbancaria, moneda, sucursal, plaza e institución bancaria, y/o contrato de apertura. Además, debe incluirse la leyenda al margen: "Ratifico ser el titular de esta cuenta y me responsabilizo de la información proporcionada", junto con la firma correspondiente.
                    </div>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="6">
                <v-row class="ma-0 pa-0">
                  <v-col cols="12" class="ma-0 pa-0">
                    <VFileInputError
                      downloadUrl="/api/Proveedores/DescargarDocumento"
                      :token="token"
                      accept=".jpg,.jpeg,.png,.pdf"
                      label="Solicitud de certificación del medio de identificación electrónica para personas morales extranjeras *"
                      :value="editedItem.solicitudCertificacionMorales"
                      :error-state="editedItem.errorSolicitudCertificacionMorales"
                      @update:value="updateField('solicitudCertificacionMorales', $event)"
                      @update:errorState="updateField('errorSolicitudCertificacionMorales', $event)"
                      @update:textareaModel="updateField('anomaliaSolicitudCertificacionMorales', $event)"
                    />
                  </v-col>

                  <v-col cols="12" class="ma-0 pa-0">
                    <VFileInputError
                      downloadUrl="/api/Proveedores/DescargarDocumento"
                      :token="token"
                      accept=".jpg,.jpeg,.png,.pdf"
                      label="Clave única de registro de población (CURP) del representante legal o apoderado *"
                      :value="editedItem.curpRepresentante"
                      :error-state="editedItem.errorCurpRepresentante"
                      @update:value="updateField('curpRepresentante', $event)"
                      @update:errorState="updateField('errorCurpRepresentante', $event)"
                      @update:textareaModel="updateField('anomaliaCurpRepresentante', $event)"
                    />
                  </v-col>

                  <v-col cols="12" class="ma-0 pa-0">
                    <VFileInputError
                      downloadUrl="/api/Proveedores/DescargarDocumento"
                      :token="token"
                      accept=".jpg,.jpeg,.png,.pdf"
                      label="Identificación oficial con fotografía del representante legal *"
                      :value="editedItem.identificacionRepresentante"
                      :error-state="editedItem.errorIdentificacionRepresentante"
                      @update:value="updateField('identificacionRepresentante', $event)"
                      @update:errorState="updateField('errorIdentificacionRepresentante', $event)"
                      @update:textareaModel="updateField('anomaliaIdentificacionRepresentante', $event)"
                    />
                    <div class="text-caption note-style">Nota: Ejemplo pasaporte vigente.</div>
                  </v-col>
                </v-row>
              </v-col>
            </template>
          </v-row>

          <v-dialog
            v-model="dialogRechazar"
            max-width="50%"
          >
            <v-card>
              <v-card-title>
                Rechazar Proveedor
              </v-card-title>
              <v-card-text>
                <v-card-text>
                  ¿Desea Rechazar el Proveedor {{editedItem.nombreCompleto}}?
                  <br>
                  <br>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="motivo_rechazo"
                        outlined
                        dense
                        hide-details
                        label="Motivo del Rechazo"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="green" @click="closeDialogRechazar">Cancelar</v-btn>
                <v-btn text color="red" @click="saveDialogRechazo" :loading="saving">Rechazar Proveedor</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

        </v-card-text>

        <v-card-actions>
          <v-btn text color="green" @click="closeDialogValidate" :loading="saving">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="red" @click="save('5440e0ac-affe-444b-ada3-3ab02028e264')" :loading="saving">Solicitar Revisión</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="red" @click="openDialogRechazar">Rechazar Proveedor</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="red" @click="save('b82371dd-76a9-4a06-a568-314e776cad74')" :loading="saving">Aceptar Proveedor</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogEmail" max-width="60%" persistent>
      <v-card
        v-if="dialogEmail"
      >
        <v-card-title>
          Solicitar Datos del Proveedor
        </v-card-title>
        <v-card-text>
          <v-row>
            <!--
            <v-col cols="6">
              <v-text-field
                label="Nombre del Contacto"
                dense
                outlined
                hide-details
              ></v-text-field>
            </v-col>
            -->
            <v-col cols="12">
              <v-text-field
                v-model="emailItem.email"
                label="Correo Electrónico"
                dense
                outlined
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="green" @click="closeDialogEmail" :loading="loadingEmail">Cancelar</v-btn>
          <v-btn text color="red" @click="sendEmail" :loading="loadingEmail">Enviar Solicitud</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogPasswd"
      persistent
      max-width="60%"
    >
      <v-card>
        <v-card-title>
          Cambio de Contraseña y Extensión de Tiempo de Reporte
        </v-card-title>
        <v-card-text>
          ¿Desea autorizar el cambio de contraseña para el proveedor {{editedItem.email}} y extender el acceso del usuario a 7 días?
          <br>
          Una vez autorizado, la nueva contraseña se enviará al Proveedor al correo electrónico registrado.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            text
            @click="closeDialogPassword"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="red"
            text
            :loading="loadingChangePassword"
            @click="saveChangePasswd"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogDelete"
      persistent
      max-width="60%"
    >
      <v-card>
        <v-card-title>
          Desactivar Proveedor
        </v-card-title>
        <v-card-text>
          ¿Desea desactivar el proveedor {{editedItem.email}}?
          <br>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            text
            @click="closeDialogDelete"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="red"
            text
            @click="closeDialogDelete"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-row>
      <v-col cols="12">
        <v-btn color="primary" @click="dialogEmail = true">
          Solicitar Datos a Proveedor
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="ma-0 pa-0">
      <v-col cols="4">
        <v-autocomplete
          item-value="id"
          item-text="nombre"
          :items="estadosProveedor"
          label="Estado"
          dense
          outlined
          hide-details
          clearable
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="4">
        <v-text-field
          label="Correo Electrónico"
          dense
          outlined
          hide-details
          clearable
        >
        </v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          label="RFC"
          dense
          outlined
          hide-details
          clearable
        >
        </v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          label="# Proveedor"
          dense
          outlined
          hide-details
          clearable
        >
        </v-text-field>
      </v-col>
      <v-col cols="4">
        <v-autocomplete
          item-value="id"
          item-text="nombre"
          :items="statusProveedor"
          label="Activo"
          dense
          outlined
          hide-details
          clearable
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="4">
        <v-btn block color="primary" text outlined @click="getItems">
          <v-icon>mdi-magnify</v-icon>
          Buscar
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="ma-0 pa-0">
      <v-col cols="12">
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="items"
          :item-class="getRowClass"
        >
          <template v-slot:[`item.estado`]="{ item }">
            <template>
              <template v-if="!item.activo && (item.motivo_rechazo == null || item.motivo_rechazo.length <= 0)">
                <v-icon
                  color="red"
                >
                  mdi-cancel
                </v-icon>
              </template>
              <template v-else-if="item.motivo_rechazo == null || item.motivo_rechazo.length <= 0">
                <v-icon
                  v-if="item.estado_carga_externa == 'Pendiente'"
                  color="grey darken-2"
                >
                  mdi-timer-sand
                </v-icon>
                <v-icon
                  v-if="item.estado_carga_externa == 'Espera Revisión'"
                  color="grey darken-2"
                >
                  mdi-eye-outline
                </v-icon>
                <v-icon
                  v-if="item.estado_carga_externa == 'Aceptado'"
                  color="green"
                >
                  mdi-check
                </v-icon>
                <v-icon
                  v-if="item.estado_carga_externa == 'Rechazado'"
                  color="red"
                >
                  mdi-close
                </v-icon>
                <v-icon
                  v-if="item.estado_carga_externa == 'Corrección'"
                  color="orange"
                >
                  mdi-arrow-u-left-bottom
                </v-icon>
              </template>
              <VTooltipIconV2
                v-else
                color="white"
                icon="mdi-alert"
                :tooltip-text="`${item.motivo_rechazo} - ${item.usuario_revisor} desde ${item.ultima_actualizacion}`"
              >
              </VTooltipIconV2>
            </template>
          </template>
          <template v-slot:[`item.activo`]="{ item }">
            {{item.activo ? 'Si' : 'No'}}
          </template>
          <template v-slot:[`item.cambiar_password`]="{ item }">
            {{item.cambiar_password ? 'Si' : 'No'}}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              v-if="item.estado_carga_externa != 'Pendiente' && item.estado_carga_externa != 'Rechazado' && item.estado_carga_externa != 'Desactivado'"
              :color="(item.motivo_rechazo == null || item.motivo_rechazo.length < 0) ? 'gray' : 'white'"
              small
              @click="openDialogValidate(item)"
              class="mr-2"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              v-if="item.estado_carga_externa != 'Rechazado'"
              class="mr-2"
              small
              @click="openDialogPassword(item)"
            >
              mdi-key
            </v-icon>
            <v-icon
              class="mr-2"
              small
              @click="openDialogDelete(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import VAlertDialog from '@/components/VAlertDialog.vue'
import VAutocompleteError from './Elementos/VAutocompleteError.vue'
import VFileInputError from './Elementos/VFileInputError.vue'
import VSelectError from './Elementos/VSelectError.vue'
import VTextFieldError from './Elementos/VTextFieldError.vue'
import VBtnError from './Elementos/VBtnError.vue'
import VTooltipIconV2 from '@/components/VTooltipIconV2.vue';

export default {
  components: {
    VAlertDialog,
    VAutocompleteError,
    VFileInputError,
    VSelectError,
    VTextFieldError,
    VBtnError,
    VTooltipIconV2
  },
  data() {
    return {
      dialog: false,
      loading: false,
      dialogEmail: false,
      dialogRechazar: false,
      motivo_rechazo: null,
      headers: [
        {
          text: "Estado",
          value: "estado",
          align: "center",
        },
        {
          text: "Estado del Proveedor",
          value: "estado_carga_externa",
          align: "center",
        },
        {
          text: "Correo Electrónico de Contacto",
          value: "email",
          align: "center",
        },
        {
          text: "RFC",
          value: "rfc",
          align: "center",
        },
        {
          text: "# Proveedor",
          value: "num_proveedor",
          align: "center",
        },
        {
          text: "Activo",
          value: "activo",
          align: "center",
        },
        {
          text: "¿Requiere Cambiar Contraseña?",
          value: "cambiar_password",
          align: "center",
        },
        {
          text: "Acceso Valido Hasta",
          value: "acceso_valido_hasta",
          align: "center",
        },
        {
          text: "Ultimo Inicio de Sesión",
          value: "ultimo_login",
          align: "center",
        },
        {
          text: "Acciones",
          value: "actions",
          sortable: false,
          align: "center",
        },
      ],
      emailItem: {
        email: null,
      },
      defaultEmailItem: {
        email: null,
      },
      loadingEmail: false,
      items: [],
      
      editedItem: {
        actaConstitutiva: null,
        banco: null,
        calleFiscal: null,
        calleSucursal: null,
        caratulaEstadoCuenta: null,
        cedulaFiscalMoral: null,
        cedulaIdentificacionFiscal: null,
        certificacion: null,
        certificado1: null,
        certificado2: null,
        certificado3: null,
        cie: null,
        ciudad: null,
        ciudadSucursal: null,
        clabe: null,
        claveInt: null,
        codigoPostal: null,
        codigoPostalSucursal: null,
        colonia: null,
        coloniaSucursal: null,
        comprobanteDomicilio: null,
        correoContacto: null,
        cuenta: null,
        curp: null,
        curpRepresentante: null,
        curriculumEmpresa: null,
        curriculumVitaeEmpresarial: null,
        direccionGoogleMaps: null,
        fachadaEmpresa1: null,
        fachadaEmpresa2: null,
        fotografiaProducto1: null,
        fotografiaProducto2: null,
        fotografiaProducto3: null,
        fotografiaProducto4: null,
        idBanco: null,
        identificacionOficial: null,
        identificacionRepresentante: null,
        idEstado: null,
        idEstadoSucursal: null,
        idMoneda: null,
        idMunicipio: null,
        idMunicipioSucursal: null,
        idPais: null,
        idPaisSucursal: null,
        idRegimenFiscal: null,
        idTipoPersona: null,
        idTipoProveedor: null,
        isbn: null,
        nombreBeneficiario: null,
        nombreCompleto: null,
        nombreContacto: null,
        numeroFiscal: null,
        numExtFiscal: null,
        numExtSucursal: null,
        numIntFiscal: null,
        numIntSucursal: null,
        opinionCumplimiento: null,
        poderRepresentante: null,
        razonSocial: null,
        referencia: null,
        referenciaComercial1: null,
        referenciaComercial2: null,
        region: null,
        regionSucursal: null,
        rfc: null,
        situacionFiscal: null,
        solicitudCertificacion: null,
        solicitudCertificacionMorales: null,
        swift: null,
        telefonoContacto: null,
        telefonoFiscal: null,
        testimonioEscritura: null,
        tipoProveedorNacional: null,
        polizaResponsabilidadCivilMedica: null,
        copiaTituloMedico: null,
        cedulaProfesionalMedico: null,
        diplomaEspecialidad: null,
        cedulaRadiologia: null,

        errorActaConstitutiva: null,
        errorBanco: null,
        errorCalleFiscal: null,
        errorCalleSucursal: null,
        errorCaratulaEstadoCuenta: null,
        errorCedulaFiscalMoral: null,
        errorCedulaIdentificacionFiscal: null,
        errorCertificacion: null,
        errorCertificado1: null,
        errorCertificado2: null,
        errorCertificado3: null,
        errorCie: null,
        errorCiudad: null,
        errorCiudadSucursal: null,
        errorClabe: null,
        errorClaveInt: null,
        errorCodigoPostal: null,
        errorCodigoPostalSucursal: null,
        errorColonia: null,
        errorColoniaSucursal: null,
        errorComprobanteDomicilio: null,
        errorCorreoContacto: null,
        errorCuenta: null,
        errorCurp: null,
        errorCurpRepresentante: null,
        errorCurriculumEmpresa: null,
        errorCurriculumVitaeEmpresarial: null,
        errorDireccionGoogleMaps: null,
        errorFachadaEmpresa1: null,
        errorFachadaEmpresa2: null,
        errorFotografiaProducto1: null,
        errorFotografiaProducto2: null,
        errorFotografiaProducto3: null,
        errorFotografiaProducto4: null,
        errorIdBanco: null,
        errorIdentificacionOficial: null,
        errorIdentificacionRepresentante: null,
        errorIdEstado: null,
        errorIdEstadoSucursal: null,
        errorIdMoneda: null,
        errorIdMunicipio: null,
        errorIdMunicipioSucursal: null,
        errorIdPais: null,
        errorIdPaisSucursal: null,
        errorIdRegimenFiscal: null,
        errorIdTipoPersona: null,
        errorIdTipoProveedor: null,
        errorIsbn: null,
        errorNombreBeneficiario: null,
        errorNombreCompleto: null,
        errorNombreContacto: null,
        errorNumeroFiscal: null,
        errorNumExtFiscal: null,
        errorNumExtSucursal: null,
        errorNumIntFiscal: null,
        errorNumIntSucursal: null,
        errorOpinionCumplimiento: null,
        errorPoderRepresentante: null,
        errorRazonSocial: null,
        errorReferencia: null,
        errorReferenciaComercial1: null,
        errorReferenciaComercial2: null,
        errorRegion: null,
        errorRegionSucursal: null,
        errorRfc: null,
        errorSituacionFiscal: null,
        errorSolicitudCertificacion: null,
        errorSolicitudCertificacionMorales: null,
        errorSwift: null,
        errorTelefonoContacto: null,
        errorTelefonoFiscal: null,
        errorTestimonioEscritura: null,
        errortipoProveedorNacional: null,
        errorPolizaResponsabilidadCivilMedica: null,
        errorCopiaTituloMedico: null,
        errorCedulaProfesionalMedico: null,
        errorDiplomaEspecialidad: null,
        errorCedulaRadiologia: null,

        anomaliaActaConstitutiva: null,
        anomaliaBanco: null,
        anomaliaCalleFiscal: null,
        anomaliaCalleSucursal: null,
        anomaliaCaratulaEstadoCuenta: null,
        anomaliaCedulaFiscalMoral: null,
        anomaliaCedulaIdentificacionFiscal: null,
        anomaliaCertificacion: null,
        anomaliaCertificado1: null,
        anomaliaCertificado2: null,
        anomaliaCertificado3: null,
        anomaliaCie: null,
        anomaliaCiudad: null,
        anomaliaCiudadSucursal: null,
        anomaliaClabe: null,
        anomaliaClaveInt: null,
        anomaliaCodigoPostal: null,
        anomaliaCodigoPostalSucursal: null,
        anomaliaColonia: null,
        anomaliaColoniaSucursal: null,
        anomaliaComprobanteDomicilio: null,
        anomaliaCorreoContacto: null,
        anomaliaCuenta: null,
        anomaliaCurp: null,
        anomaliaCurpRepresentante: null,
        anomaliaCurriculumEmpresa: null,
        anomaliaCurriculumVitaeEmpresarial: null,
        anomaliaDireccionGoogleMaps: null,
        anomaliaFachadaEmpresa1: null,
        anomaliaFachadaEmpresa2: null,
        anomaliaFotografiaProducto1: null,
        anomaliaFotografiaProducto2: null,
        anomaliaFotografiaProducto3: null,
        anomaliaFotografiaProducto4: null,
        anomaliaIdBanco: null,
        anomaliaIdentificacionOficial: null,
        anomaliaIdentificacionRepresentante: null,
        anomaliaIdEstado: null,
        anomaliaIdEstadoSucursal: null,
        anomaliaIdMoneda: null,
        anomaliaIdMunicipio: null,
        anomaliaIdMunicipioSucursal: null,
        anomaliaIdPais: null,
        anomaliaIdPaisSucursal: null,
        anomaliaIdRegimenFiscal: null,
        anomaliaIdTipoPersona: null,
        anomaliaIdTipoProveedor: null,
        anomaliaIsbn: null,
        anomaliaNombreBeneficiario: null,
        anomaliaNombreCompleto: null,
        anomaliaNombreContacto: null,
        anomaliaNumeroFiscal: null,
        anomaliaNumExtFiscal: null,
        anomaliaNumExtSucursal: null,
        anomaliaNumIntFiscal: null,
        anomaliaNumIntSucursal: null,
        anomaliaOpinionCumplimiento: null,
        anomaliaPoderRepresentante: null,
        anomaliaRazonSocial: null,
        anomaliaReferencia: null,
        anomaliaReferenciaComercial1: null,
        anomaliaReferenciaComercial2: null,
        anomaliaRegion: null,
        anomaliaRegionSucursal: null,
        anomaliaRfc: null,
        anomaliaSituacionFiscal: null,
        anomaliaSolicitudCertificacion: null,
        anomaliaSolicitudCertificacionMorales: null,
        anomaliaSwift: null,
        anomaliaTelefonoContacto: null,
        anomaliaTelefonoFiscal: null,
        anomaliaTestimonioEscritura: null,
        anomaliatipoProveedorNacional: null,
        anomaliaPolizaResponsabilidadCivilMedica: null,
        anomaliaCopiaTituloMedico: null,
        anomaliaCedulaProfesionalMedico: null,
        anomaliaDiplomaEspecialidad: null,
        anomaliaCedulaRadiologia: null,
      },

      defaultEditedItem: {
        actaConstitutiva: null,
        banco: null,
        calleFiscal: null,
        calleSucursal: null,
        caratulaEstadoCuenta: null,
        cedulaFiscalMoral: null,
        cedulaIdentificacionFiscal: null,
        certificacion: null,
        certificado1: null,
        certificado2: null,
        certificado3: null,
        cie: null,
        ciudad: null,
        ciudadSucursal: null,
        clabe: null,
        claveInt: null,
        codigoPostal: null,
        codigoPostalSucursal: null,
        colonia: null,
        coloniaSucursal: null,
        comprobanteDomicilio: null,
        correoContacto: null,
        cuenta: null,
        curp: null,
        curpRepresentante: null,
        curriculumEmpresa: null,
        curriculumVitaeEmpresarial: null,
        direccionGoogleMaps: null,
        fachadaEmpresa1: null,
        fachadaEmpresa2: null,
        fotografiaProducto1: null,
        fotografiaProducto2: null,
        fotografiaProducto3: null,
        fotografiaProducto4: null,
        idBanco: null,
        identificacionOficial: null,
        identificacionRepresentante: null,
        idEstado: null,
        idEstadoSucursal: null,
        idMoneda: null,
        idMunicipio: null,
        idMunicipioSucursal: null,
        idPais: null,
        idPaisSucursal: null,
        idRegimenFiscal: null,
        idTipoPersona: null,
        idTipoProveedor: null,
        isbn: null,
        nombreBeneficiario: null,
        nombreCompleto: null,
        nombreContacto: null,
        numeroFiscal: null,
        numExtFiscal: null,
        numExtSucursal: null,
        numIntFiscal: null,
        numIntSucursal: null,
        opinionCumplimiento: null,
        poderRepresentante: null,
        razonSocial: null,
        referencia: null,
        referenciaComercial1: null,
        referenciaComercial2: null,
        region: null,
        regionSucursal: null,
        rfc: null,
        situacionFiscal: null,
        solicitudCertificacion: null,
        solicitudCertificacionMorales: null,
        swift: null,
        telefonoContacto: null,
        telefonoFiscal: null,
        testimonioEscritura: null,
        tipoProveedorNacional: null,
        polizaResponsabilidadCivilMedica: null,
        copiaTituloMedico: null,
        cedulaProfesionalMedico: null,
        diplomaEspecialidad: null,
        cedulaRadiologia: null,

        errorActaConstitutiva: null,
        errorBanco: null,
        errorCalleFiscal: null,
        errorCalleSucursal: null,
        errorCaratulaEstadoCuenta: null,
        errorCedulaFiscalMoral: null,
        errorCedulaIdentificacionFiscal: null,
        errorCertificacion: null,
        errorCertificado1: null,
        errorCertificado2: null,
        errorCertificado3: null,
        errorCie: null,
        errorCiudad: null,
        errorCiudadSucursal: null,
        errorClabe: null,
        errorClaveInt: null,
        errorCodigoPostal: null,
        errorCodigoPostalSucursal: null,
        errorColonia: null,
        errorColoniaSucursal: null,
        errorComprobanteDomicilio: null,
        errorCorreoContacto: null,
        errorCuenta: null,
        errorCurp: null,
        errorCurpRepresentante: null,
        errorCurriculumEmpresa: null,
        errorCurriculumVitaeEmpresarial: null,
        errorDireccionGoogleMaps: null,
        errorFachadaEmpresa1: null,
        errorFachadaEmpresa2: null,
        errorFotografiaProducto1: null,
        errorFotografiaProducto2: null,
        errorFotografiaProducto3: null,
        errorFotografiaProducto4: null,
        errorIdBanco: null,
        errorIdentificacionOficial: null,
        errorIdentificacionRepresentante: null,
        errorIdEstado: null,
        errorIdEstadoSucursal: null,
        errorIdMoneda: null,
        errorIdMunicipio: null,
        errorIdMunicipioSucursal: null,
        errorIdPais: null,
        errorIdPaisSucursal: null,
        errorIdRegimenFiscal: null,
        errorIdTipoPersona: null,
        errorIdTipoProveedor: null,
        errorIsbn: null,
        errorNombreBeneficiario: null,
        errorNombreCompleto: null,
        errorNombreContacto: null,
        errorNumeroFiscal: null,
        errorNumExtFiscal: null,
        errorNumExtSucursal: null,
        errorNumIntFiscal: null,
        errorNumIntSucursal: null,
        errorOpinionCumplimiento: null,
        errorPoderRepresentante: null,
        errorRazonSocial: null,
        errorReferencia: null,
        errorReferenciaComercial1: null,
        errorReferenciaComercial2: null,
        errorRegion: null,
        errorRegionSucursal: null,
        errorRfc: null,
        errorSituacionFiscal: null,
        errorSolicitudCertificacion: null,
        errorSolicitudCertificacionMorales: null,
        errorSwift: null,
        errorTelefonoContacto: null,
        errorTelefonoFiscal: null,
        errorTestimonioEscritura: null,
        errortipoProveedorNacional: null,
        errorPolizaResponsabilidadCivilMedica: null,
        errorCopiaTituloMedico: null,
        errorCedulaProfesionalMedico: null,
        errorDiplomaEspecialidad: null,
        errorCedulaRadiologia: null,

        anomaliaActaConstitutiva: null,
        anomaliaBanco: null,
        anomaliaCalleFiscal: null,
        anomaliaCalleSucursal: null,
        anomaliaCaratulaEstadoCuenta: null,
        anomaliaCedulaFiscalMoral: null,
        anomaliaCedulaIdentificacionFiscal: null,
        anomaliaCertificacion: null,
        anomaliaCertificado1: null,
        anomaliaCertificado2: null,
        anomaliaCertificado3: null,
        anomaliaCie: null,
        anomaliaCiudad: null,
        anomaliaCiudadSucursal: null,
        anomaliaClabe: null,
        anomaliaClaveInt: null,
        anomaliaCodigoPostal: null,
        anomaliaCodigoPostalSucursal: null,
        anomaliaColonia: null,
        anomaliaColoniaSucursal: null,
        anomaliaComprobanteDomicilio: null,
        anomaliaCorreoContacto: null,
        anomaliaCuenta: null,
        anomaliaCurp: null,
        anomaliaCurpRepresentante: null,
        anomaliaCurriculumEmpresa: null,
        anomaliaCurriculumVitaeEmpresarial: null,
        anomaliaDireccionGoogleMaps: null,
        anomaliaFachadaEmpresa1: null,
        anomaliaFachadaEmpresa2: null,
        anomaliaFotografiaProducto1: null,
        anomaliaFotografiaProducto2: null,
        anomaliaFotografiaProducto3: null,
        anomaliaFotografiaProducto4: null,
        anomaliaIdBanco: null,
        anomaliaIdentificacionOficial: null,
        anomaliaIdentificacionRepresentante: null,
        anomaliaIdEstado: null,
        anomaliaIdEstadoSucursal: null,
        anomaliaIdMoneda: null,
        anomaliaIdMunicipio: null,
        anomaliaIdMunicipioSucursal: null,
        anomaliaIdPais: null,
        anomaliaIdPaisSucursal: null,
        anomaliaIdRegimenFiscal: null,
        anomaliaIdTipoPersona: null,
        anomaliaIdTipoProveedor: null,
        anomaliaIsbn: null,
        anomaliaNombreBeneficiario: null,
        anomaliaNombreCompleto: null,
        anomaliaNombreContacto: null,
        anomaliaNumeroFiscal: null,
        anomaliaNumExtFiscal: null,
        anomaliaNumExtSucursal: null,
        anomaliaNumIntFiscal: null,
        anomaliaNumIntSucursal: null,
        anomaliaOpinionCumplimiento: null,
        anomaliaPoderRepresentante: null,
        anomaliaRazonSocial: null,
        anomaliaReferencia: null,
        anomaliaReferenciaComercial1: null,
        anomaliaReferenciaComercial2: null,
        anomaliaRegion: null,
        anomaliaRegionSucursal: null,
        anomaliaRfc: null,
        anomaliaSituacionFiscal: null,
        anomaliaSolicitudCertificacion: null,
        anomaliaSolicitudCertificacionMorales: null,
        anomaliaSwift: null,
        anomaliaTelefonoContacto: null,
        anomaliaTelefonoFiscal: null,
        anomaliaTestimonioEscritura: null,
        anomaliatipoProveedorNacional: null,
        anomaliaPolizaResponsabilidadCivilMedica: null,
        anomaliaCopiaTituloMedico: null,
        anomaliaCedulaProfesionalMedico: null,
        anomaliaDiplomaEspecialidad: null,
        anomaliaCedulaRadiologia: null,
      },

      loadingPaises: false,
      paises: [],

      tiposPersonas: [
        { id_tipo_persona: 1, nombre: "Física" },
        { id_tipo_persona: 2, nombre: "Moral" },
      ],
      tiposProveedor: [
        {
          id_tipo_proveedor: 1,
          nombre: "Proveedor Nacional",
        },
        {
          id_tipo_proveedor: 2,
          nombre: "Proveedor Internacional",
        },
      ],

      estados: [],
      loadingEstados: false,
      
      municipios: [],
      municipiosSucursal: [],
      loadingMunicipios: false,
      
      regimenFiscales: [],
      loadingRegimenFiscales: false,

      tiposProveedorNacional: [
        {
          id_tipo_proveedor_nacional: 1,
          nombre: "Venta de Bienes y Servicios"
        },
        {
          id_tipo_proveedor_nacional: 2,
          nombre: "Servicio Profesional en Area Médica"
        },
        {
          id_tipo_proveedor_nacional: 3,
          nombre: "Servicios profesionales con acreditacion"
        }
      ],

      bancos: [],
      loadingBancos: false,
      
      monedas: [],
      loadingMonedas: false,
      
      saving: false,

      dialogPasswd: false,
      loadingChangePassword: false,

      estadosProveedor: [
        { id: 1, nombre:'Pendiente'},
        { id: 2, nombre:'Espera Revisión'},
        { id: 3, nombre:'Aceptado'},
        { id: 4, nombre:'Rechazado'},
        { id: 5, nombre:'Corrección'},
      ],
      statusProveedor: [
        { id: 1, nombre:'Si'},
        { id: 2, nombre:'No'},
      ]
    };
  },
  computed: {
    ...mapState(['token']),
  },
  mounted() {
    this.getInvitaciones();
    this.getEstados();
    this.getMonedas();
    this.getBancos();
    this.getPaises();
    this.getRegimenesFiscales();
  },
  methods: {
    getItems(){
      this.getInvitaciones();
    },
    nuevaPestana(url) {
      window.open(url, '_blank');
    },
    openDialogEmail(){
      this.emailItem = Object.assign({}, this.defaultEmailItem);
      this.dialogEmail = true;
    },
    closeDialogEmail(){
      this.emailItem = Object.assign({}, this.defaultEmailItem);
      this.dialogEmail = false;
    },
    sendEmail(){
      this.loadingEmail = true;

      axios
      .post('/Proveedores/CrearInvitacion',{
        email: this.emailItem.email
      })
      .then(() => {
        this.closeDialogEmail();
        this.getItems();
      })
      .catch(error => {
        if (error.response.status == 409) {
          this.$refs.alert.setMessage("", error.response.data.msg.replace('Error : ', ''));
          this.$refs.alert.open();
          return;
        }
        console.log(error);
      })
      .finally(() => {
        this.loadingEmail = false;
      })
    },
    getInvitaciones(){
      this.loading = true;
      axios
      .get('/Proveedores/ListarProveedorUsuarios')
      .then(response => {
        this.items = response.data;
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() =>{
        this.loading = false;
      })
    },
    getMonedas(){
      this.loadingMonedas = true;
      axios
        .get('/Catalogos/ListarMonedas')
        .then(response => {
          this.monedas = response.data;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loadingMonedas = false;
        })
    },
    getEstados(){
      this.loadingEstados = true;
      axios
        .get('/Catalogos/ListarEstados')
        .then(response => {
          this.estados = response.data;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loadingEstados = false;
        })
    },
    getMunicipios(){
      if(this.editedItem.idEstado == null) return;
      this.loadingMunicipios = true;
      axios
        .get(`/Catalogos/ListarMunicipios/${this.editedItem.idEstado}`)
        .then(response => {
          this.municipios = response.data;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loadingMunicipios = false;
        })
    },
    getMunicipiosSucursal(){
      if(this.editedItem.idEstadoSucursal == null) return;
      this.loadingMunicipios = true;
      axios
        .get(`/Catalogos/ListarMunicipios/${this.editedItem.idEstadoSucursal}`)
        .then(response => {
          this.municipiosSucursal = response.data;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loadingMunicipios = false;
        })
    },
    getBancos(){
      this.loadingBancos = true;
      axios
        .get('/Catalogos/ListarBancos')
        .then(response => {
          this.bancos = response.data;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loadingBancos = false;
        })
    },
    getPaises(){
      this.loadingPaises = true;
      axios
        .get('/Catalogos/ListarPaises')
        .then(response => {
          this.paises = response.data;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loadingPaises = false;
        })
    },
    getRegimenesFiscales(){
      this.loadingRegimenFiscales = true;
      axios
        .get('/Catalogos/ListarRegimenFiscales')
        .then(response => {
          this.regimenFiscales = response.data;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loadingRegimenFiscales = false;
        })
    },
    openDialogValidate(item){
      this.dialog = true;

      axios
      .get(`/Proveedores/ObtenerInformacionRevision/${item.id_proveedor_usuario}`)
      .then(respose => {
        var obj = Object.assign({}, this.defaultEditedItem);
        this.editedItem = Object.assign(obj, respose.data);

        this.getMunicipios();
        this.getMunicipiosSucursal();
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {

      })
    },
    closeDialogValidate(){
      this.dialog = false;
      this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.getInvitaciones();
    },
    validate() {
      console.log(this.editedItem);
      const fieldsToValidate = [
        {
          field: 'nombreCompleto',
          errorField: 'errorNombreCompleto',
          anomalyField: 'anomaliaNombreCompleto',
          fieldName: 'Nombre Completo',
          required: () => true,
        },
        {
          field: 'nombreContacto',
          errorField: 'errorNombreContacto',
          anomalyField: 'anomaliaNombreContacto',
          fieldName: 'Nombre del Contacto',
          required: () => true,
        },
        {
          field: 'telefonoContacto',
          errorField: 'errorTelefonoContacto',
          anomalyField: 'anomaliaTelefonoContacto',
          fieldName: 'Teléfono de Contacto',
          required: () => true,
        },
        {
          field: 'correoContacto',
          errorField: 'errorCorreoContacto',
          anomalyField: 'anomaliaCorreoContacto',
          fieldName: 'Correo Electrónico',
          required: () => true,
        },
        {
          field: 'idTipoProveedor',
          errorField: 'errorIdTipoProveedor',
          anomalyField: 'anomaliaidTipoProveedor',
          fieldName: 'Tipo de Proveedor',
          required: () => true,
        },
        {
          field: 'idTipoPersona',
          errorField: 'errorIdTipoPersona',
          anomalyField: 'anomaliaidTipoPersona',
          fieldName: 'Tipo de Persona',
          required: () => true,
        },
        {
          field: 'razonSocial',
          errorField: 'errorRazonSocial',
          anomalyField: 'anomaliaRazonSocial',
          fieldName: 'Razón Social',
          required: () => this.editedItem.idTipoProveedor === 1 || this.editedItem.idTipoProveedor === 2,
        },
        {
          field: 'rfc',
          errorField: 'errorRfc',
          anomalyField: 'anomaliaRfc',
          fieldName: 'RFC',
          required: () => this.editedItem.idTipoProveedor === 1,
        },
        {
          field: 'id_estado',
          errorField: 'errorIdEstado',
          anomalyField: 'anomaliaIdEstado',
          fieldName: 'Estado',
          required: () => this.editedItem.idTipoProveedor === 1,
        },
        {
          field: 'id_municipio',
          errorField: 'errorIdMunicipio',
          anomalyField: 'anomaliaIdMunicipio',
          fieldName: 'Municipio',
          required: () => this.editedItem.idTipoProveedor === 1,
        },
        {
          field: 'ìdRegimenFiscal',
          errorField: 'errorIdRegimenFiscal',
          anomalyField: 'anomaliaIdRegimenFiscal',
          fieldName: 'Régimen Fiscal',
          required: () => this.editedItem.idTipoProveedor === 1,
        },
        {
          field: 'codigoPostal',
          errorField: 'errorCodigoPostal',
          anomalyField: 'anomaliaCodigoPostal',
          fieldName: 'Código Postal',
          required: () => true,
        },
        {
          field: 'colonia',
          errorField: 'errorColonia',
          anomalyField: 'anomaliaColonia',
          fieldName: 'Colonia',
          required: () => this.editedItem.idTipoProveedor === 1,
        },
        {
          field: 'numeroFiscal',
          errorField: 'errorNumeroFiscal',
          anomalyField: 'anomaliaNumeroFiscal',
          fieldName: 'Número Fiscal',
          required: () => this.editedItem.idTipoProveedor === 2,
        },
        {
          field: 'calleFiscal',
          errorField: 'errorCalleFiscal',
          anomalyField: 'anomaliaCalleFiscal',
          fieldName: 'Calle',
          required: () => true,
        },
        {
          field: 'numExtFiscal',
          errorField: 'errorNumExtFiscal',
          anomalyField: 'anomaliaNumExtFiscal',
          fieldName: 'Número Exterior',
          required: () => true,
        },
        {
          field: 'ciudad',
          errorField: 'errorCiudad',
          anomalyField: 'anomaliaCiudad',
          fieldName: 'Ciudad',
          required: () => this.editedItem.idTipoProveedor === 2,
        },
        {
          field: 'region',
          errorField: 'errorRegion',
          anomalyField: 'anomaliaRegion',
          fieldName: 'Región/Estado/Provincia',
          required: () => this.editedItem.idTipoProveedor === 2,
        },
        {
          field: 'pais',
          errorField: 'errorPais',
          anomalyField: 'anomaliaPais',
          fieldName: 'País',
          required: () => this.editedItem.idTipoProveedor === 2,
        },
        {
          field: 'telefonoFiscal',
          errorField: 'errorTelefonoFiscal',
          anomalyField: 'anomaliaTelefonoFiscal',
          fieldName: 'Teléfono Fiscal',
          required: () => true,
        },
        {
          field: 'id_banco',
          errorField: 'errorIdBanco',
          anomalyField: 'anomaliaIdBanco',
          fieldName: 'Banco',
          required: () => this.editedItem.idTipoProveedor === 1,
        },
        {
          field: 'nombreBeneficiario',
          errorField: 'errorNombreBeneficiario',
          anomalyField: 'anomaliaNombreBeneficiario',
          fieldName: 'Nombre del Beneficiario',
          required: () => !!this.editedItem.idTipoProveedor,
        },
        {
          field: 'clabe',
          errorField: 'errorClabe',
          anomalyField: 'anomaliaClabe',
          fieldName: 'CLABE Interbancaria',
          required: () => this.editedItem.idTipoProveedor === 1,
        },
        {
          field: 'claveInt',
          errorField: 'errorClaveInt',
          anomalyField: 'anomaliaClaveInt',
          fieldName: 'Clave Internacional',
          required: () => this.editedItem.idTipoProveedor === 2,
        },
        {
          field: 'cuenta',
          errorField: 'errorCuenta',
          anomalyField: 'anomaliaCuenta',
          fieldName: 'Cuenta',
          required: () => !!this.editedItem.idTipoProveedor,
        },
        {
          field: 'cie',
          errorField: 'errorCie',
          anomalyField: 'anomaliaCie',
          fieldName: 'CIE',
          required: () => false,
        },
        {
          field: 'referencia',
          errorField: 'errorReferencia',
          anomalyField: 'anomaliaReferencia',
          fieldName: 'Referencia',
          required: () => false,
        },
        {
          field: 'id_moneda',
          errorField: 'errorIdMoneda',
          anomalyField: 'anomaliaIdMoneda',
          fieldName: 'Moneda',
          required: () => !!this.editedItem.idTipoProveedor,
        },
        {
          field: 'banco',
          errorField: 'errorBanco',
          anomalyField: 'anomaliaBanco',
          fieldName: 'Banco (Internacional)',
          required: () => this.editedItem.idTipoProveedor === 2,
        },
        {
          field: 'swift',
          errorField: 'errorSwift',
          anomalyField: 'anomaliaSwift',
          fieldName: 'SWIFT',
          required: () => this.editedItem.idTipoProveedor === 2,
        },
        {
          field: 'isbn',
          errorField: 'errorIsbn',
          anomalyField: 'anomaliaIsbn',
          fieldName: 'ISBN',
          required: () => this.editedItem.idTipoProveedor === 2,
        },
        {
          field: 'curriculumVitaeEmpresarial',
          errorField: 'errorCurriculumVitaeEmpresarial',
          anomalyField: 'anomaliaCurriculumVitaeEmpresarial',
          fieldName: 'Currículum Vitae empresarial',
          required: () => this.editedItem.idTipoProveedor === 1 && this.editedItem.idTipoPersona === 1,
        },
        {
          field: 'identificacionOficial',
          errorField: 'errorIdentificacionOficial',
          anomalyField: 'anomaliaIdentificacionOficial',
          fieldName: 'Copia de Identificación Oficial',
          required: () => this.editedItem.idTipoProveedor === 1 && this.editedItem.idTipoPersona === 1,
        },
        {
          field: 'comprobanteDomicilio',
          errorField: 'errorComprobanteDomicilio',
          anomalyField: 'anomaliaComprobanteDomicilio',
          fieldName: 'Comprobante de Domicilio',
          required: () => this.editedItem.idTipoProveedor === 1,
        },
        {
          field: 'caratulaEstadoCuenta',
          errorField: 'errorCaratulaEstadoCuenta',
          anomalyField: 'anomaliaCaratulaEstadoCuenta',
          fieldName: 'Carátula del Estado de Cuenta',
          required: () => true,
        },
        {
          field: 'situacionFiscal',
          errorField: 'errorSituacionFiscal',
          anomalyField: 'anomaliaSituacionFiscal',
          fieldName: 'Constancia de Situación Fiscal',
          required: () => this.editedItem.idTipoProveedor === 1,
        },
        {
          field: 'opinionCumplimiento',
          errorField: 'errorOpinionCumplimiento',
          anomalyField: 'anomaliaOpinionCumplimiento',
          fieldName: 'Opinión de Cumplimiento',
          required: () => this.editedItem.idTipoProveedor === 1,
        },
        {
          field: 'referenciaComercial1',
          errorField: 'errorReferenciaComercial1',
          anomalyField: 'anomaliaReferenciaComercial1',
          fieldName: 'Carta de Referencia Comercial 1',
          required: () => this.editedItem.idTipoProveedor === 1,
        },
        {
          field: 'referenciaComercial2',
          errorField: 'errorReferenciaComercial2',
          anomalyField: 'anomaliaReferenciaComercial2',
          fieldName: 'Carta de Referencia Comercial 2',
          required: () => this.editedItem.idTipoProveedor === 1,
        },
        {
          field: 'certificacion',
          errorField: 'errorCertificacion',
          anomalyField: 'anomaliaCertificacion',
          fieldName: 'Certificación/Licencia',
          required: () => !!this.editedItem.idTipoProveedor,
        },
        {
          field: 'fotografiaProducto1',
          errorField: 'errorFotografiaProducto1',
          anomalyField: 'anomaliaFotografiaProducto1',
          fieldName: 'Fotografía del Producto 1',
          required: () => this.editedItem.idTipoProveedor === 1 && this.editedItem.idTipoPersona === 1,
        },
        {
          field: 'fotografiaProducto2',
          errorField: 'errorFotografiaProducto2',
          anomalyField: 'anomaliaFotografiaProducto2',
          fieldName: 'Fotografía del Producto 2',
          required: () => this.editedItem.idTipoProveedor === 1 && this.editedItem.idTipoPersona === 1,
        },
        {
          field: 'fotografiaProducto3',
          errorField: 'errorFotografiaProducto3',
          anomalyField: 'anomaliaFotografiaProducto3',
          fieldName: 'Fotografía del Producto 3',
          required: () => this.editedItem.idTipoProveedor === 1 && this.editedItem.idTipoPersona === 1,
        },
        {
          field: 'fotografiaProducto4',
          errorField: 'errorFotografiaProducto4',
          anomalyField: 'anomaliaFotografiaProducto4',
          fieldName: 'Fotografía del Producto 4',
          required: () => this.editedItem.idTipoProveedor === 1 && this.editedItem.idTipoPersona === 1,
        },
        {
          field: 'curriculumEmpresa',
          errorField: 'errorCurriculumEmpresa',
          anomalyField: 'anomaliaCurriculumEmpresa',
          fieldName: 'Currículum de la Empresa',
          required: () => this.editedItem.idTipoProveedor === 1 && this.editedItem.idTipoPersona === 2,
        },
        {
          field: 'actaConstitutiva',
          errorField: 'errorActaConstitutiva',
          anomalyField: 'anomaliaActaConstitutiva',
          fieldName: 'Copia del Acta Constitutiva',
          required: () => this.editedItem.idTipoProveedor === 1 && this.editedItem.idTipoPersona === 2,
        },
        {
          field: 'poderRepresentante',
          errorField: 'errorPoderRepresentante',
          anomalyField: 'anomaliaPoderRepresentante',
          fieldName: 'Poder del Representante Legal',
          required: () => this.editedItem.idTipoProveedor === 1 && this.editedItem.idTipoPersona === 2,
        },
        {
          field: 'identificacionRepresentante',
          errorField: 'errorIdentificacionRepresentante',
          anomalyField: 'anomaliaIdentificacionRepresentante',
          fieldName: 'Identificación del Representante Legal',
          required: () => this.editedItem.idTipoProveedor === 1 && this.editedItem.idTipoPersona === 2,
        },
        {
          field: 'direccionGoogleMaps',
          errorField: 'errorDireccionGoogleMaps',
          anomalyField: 'anomaliaDireccionGoogleMaps',
          fieldName: 'URL de la ubicación en Google Maps',
          required: () => this.editedItem.idTipoProveedor === 1 && this.editedItem.idTipoPersona === 2,
        },
        {
          field: 'fachadaEmpresa1',
          errorField: 'errorFachadaEmpresa1',
          anomalyField: 'anomaliaFachadaEmpresa1',
          fieldName: 'Fotografía de la Fachada Empresa 1',
          required: () => this.editedItem.idTipoProveedor === 1 && this.editedItem.idTipoPersona === 2,
        },
        {
          field: 'fachadaEmpresa2',
          errorField: 'errorFachadaEmpresa2',
          anomalyField: 'anomaliaFachadaEmpresa2',
          fieldName: 'Fotografía de la Fachada Empresa 2',
          required: () => this.editedItem.idTipoProveedor === 1 && this.editedItem.idTipoPersona === 2,
        },
        {
          field: 'cedulaIdentificacionFiscal',
          errorField: 'errorCedulaIdentificacionFiscal',
          anomalyField: 'anomaliaCedulaIdentificacionFiscal',
          fieldName: 'Cédula de Identificación Fiscal',
          required: () => this.editedItem.idTipoProveedor === 2 && this.editedItem.idTipoPersona === 1,
        },
        {
          field: 'curp',
          errorField: 'errorCurp',
          anomalyField: 'anomaliaCurp',
          fieldName: 'CURP',
          required: () => this.editedItem.idTipoProveedor === 2 && this.editedItem.idTipoPersona === 1,
        },
        {
          field: 'solicitudCertificacion',
          errorField: 'errorSolicitudCertificacion',
          anomalyField: 'anomaliaSolicitudCertificacion',
          fieldName: 'Solicitud de Certificación',
          required: () => this.editedItem.idTipoProveedor === 2 && this.editedItem.idTipoPersona === 1,
        },
        {
          field: 'solicitudCertificacionMorales',
          errorField: 'errorSolicitudCertificacionMorales',
          anomalyField: 'anomaliaSolicitudCertificacionMorales',
          fieldName: 'Solicitud de Certificación para Personas Morales',
          required: () => this.editedItem.idTipoProveedor === 2 && this.editedItem.idTipoPersona === 2,
        },
        {
          field: 'curpRepresentante',
          errorField: 'errorCurpRepresentante',
          anomalyField: 'anomaliaCurpRepresentante',
          fieldName: 'CURP del Representante',
          required: () => this.editedItem.idTipoProveedor === 2 && this.editedItem.idTipoPersona === 2,
        },
        {
          field: 'cedulaFiscalMoral',
          errorField: 'errorCedulaFiscalMoral',
          anomalyField: 'anomaliaCedulaFiscalMoral',
          fieldName: 'Cédula Fiscal Moral',
          required: () => this.editedItem.idTipoProveedor === 2 && this.editedItem.idTipoPersona === 2,
        },
        {
          field: 'testimonioEscritura',
          errorField: 'errorTestimonioEscritura',
          anomalyField: 'anomaliaTestimonioEscritura',
          fieldName: 'Testimonio de la Escritura Pública',
          required: () => this.editedItem.idTipoProveedor === 2 && this.editedItem.idTipoPersona === 2,
        },
        {
          field: 'certificado1',
          errorField: 'errorCertificado1',
          anomalyField: 'anomaliaCertificado1',
          fieldName: 'Certificado 1',
          required: () => this.editedItem.tipoProveedorNacional === 2,
        },
        {
          field: 'certificado2',
          errorField: 'errorCertificado2',
          anomalyField: 'anomaliaCertificado2',
          fieldName: 'Certificado 2',
          required: () => false,
        },
        {
          field: 'certificado3',
          errorField: 'errorCertificado3',
          anomalyField: 'anomaliaCertificado3',
          fieldName: 'Certificado 3',
          required: () => false,
        },
        {
          field: 'polizaResponsabilidadCivilMedica',
          errorField: 'errorPolizaResponsabilidadCivilMedica',
          anomalyField: 'anomaliaPolizaResponsabilidadCivilMedica',
          fieldName: 'Póliza de responsabilidad civil medica',
          required: () => this.editedItem.tipoProveedorNacional === 2,
        },
        {
          field: 'copiaTituloMedico',
          errorField: 'errorCopiaTituloMedico',
          anomalyField: 'anomaliaCopiaTituloMedico',
          fieldName: 'Copia de título de Medico',
          required: () => this.editedItem.tipoProveedorNacional === 2,
        },
        {
          field: 'cedulaProfesionalMedico',
          errorField: 'errorCedulaProfesionalMedico',
          anomalyField: 'anomaliaCedulaProfesionalMedico',
          fieldName: 'Copia de Cedula profesional como Medico',
          required: () => this.editedItem.tipoProveedorNacional === 2,
        },
        {
          field: 'diplomaEspecialidad',
          errorField: 'errorDiplomaEspecialidad',
          anomalyField: 'anomaliaDiplomaEspecialidad',
          fieldName: 'Copia del diploma e especialidad',
          required: () => this.editedItem.tipoProveedorNacional === 2,
        },
        {
          field: 'cedulaRadiologia',
          errorField: 'errorCedulaRadiologia',
          anomalyField: 'anomaliaCedulaRadiologia',
          fieldName: 'Copia de la cedula de radiología',
          required: () => this.editedItem.tipoProveedorNacional === 2,
        },
        {
          field: 'calleSucursal',
          errorField: 'errorCalleSucursal',
          anomalyField: 'anomaliaCalleSucursal',
          fieldName: 'Calle de la Sucursal',
          required: () => this.editedItem.idTipoProveedor === 1,
        },
        {
          field: 'numExtSucursal',
          errorField: 'errorNumExtSucursal',
          anomalyField: 'anomaliaNumExtSucursal',
          fieldName: 'Número Exterior de la Sucursal',
          required: () => this.editedItem.idTipoProveedor === 1,
        },
        {
          field: 'numIntSucursal',
          errorField: 'errorNumIntSucursal',
          anomalyField: 'anomaliaNumIntSucursal',
          fieldName: 'Número Interior de la Sucursal',
          required: () => false,
        },
        {
          field: 'id_estadoSucursal',
          errorField: 'errorIdEstadoSucursal',
          anomalyField: 'anomaliaIdEstadoSucursal',
          fieldName: 'Estado de la Sucursal',
          required: () => this.editedItem.idTipoProveedor === 1,
        },
        {
          field: 'id_municipioSucursal',
          errorField: 'errorIdMunicipioSucursal',
          anomalyField: 'anomaliaIdMunicipioSucursal',
          fieldName: 'Municipio de la Sucursal',
          required: () => this.editedItem.idTipoProveedor === 1,
        },
        {
          field: 'coloniaSucursal',
          errorField: 'errorColoniaSucursal',
          anomalyField: 'anomaliaColoniaSucursal',
          fieldName: 'Colonia de la Sucursal',
          required: () => this.editedItem.idTipoProveedor === 1,
        },
        {
          field: 'codigoPostalSucursal',
          errorField: 'errorCodigoPostalSucursal',
          anomalyField: 'anomaliaCodigoPostalSucursal',
          fieldName: 'Código Postal de la Sucursal',
          required: () => this.editedItem.idTipoProveedor === 1,
        },
        {
          field: 'ciudadSucursal',
          errorField: 'errorCiudadSucursal',
          anomalyField: 'anomaliaCiudadSucursal',
          fieldName: 'Ciudad de la Sucursal',
          required: () => this.editedItem.idTipoProveedor === 2,
        },
        {
          field: 'regionSucursal',
          errorField: 'errorRegionSucursal',
          anomalyField: 'anomaliaRegionSucursal',
          fieldName: 'Región/Estado/Provincia de la Sucursal',
          required: () => this.editedItem.idTipoProveedor === 2,
        },
        {
          field: 'idPaisSucursal',
          errorField: 'errorIdPaisSucursal',
          anomalyField: 'anomaliaIdPaisSucursal',
          fieldName: 'País de la Sucursal',
          required: () => this.editedItem.idTipoProveedor === 2,
        },
      ];

      for (const field of fieldsToValidate) {
        if (!field.required()) {
          continue; // Si no es requerido, omite la validación
        }

        if (this.editedItem[field.errorField] === false) {
          if (
            !this.editedItem[field.anomalyField] ||
            this.editedItem[field.anomalyField].length < 5
          ) {
            this.$refs.alert.setMessage(
              '',
              `Debe de indicar la anomalía del campo ${field.fieldName}`
            );
            this.$refs.alert.open();
            return false;
          }
        }
        else if(this.editedItem[field.errorField] === null || typeof this.editedItem[field.errorField] === 'undefined'){
          this.$refs.alert.setMessage(
              '',
              `Debe de indicar si el campo ${field.fieldName} es correcto o incorrecto ${this.editedItem[field.errorField]}`
            );
            this.$refs.alert.open();
            return false;
        }
      }
      return true;
    },
    save(id_estado_carga){
      if(id_estado_carga != 'b0eb6081-7d49-4af5-af7f-dd6be81179f5' && !this.validate()) return;

      this.saving = true;
      axios
      .post('/Proveedores/GuardarEstadoRevision',{
          id_proveedor_carga_externa: this.editedItem.id_proveedor_carga_externa,
          id_estado_carga_externa: id_estado_carga,
          errorIdTipoProveedor: !this.editedItem.errorIdTipoProveedor ? this.editedItem.anomaliaIdTipoProveedor : null,
          errorIdTipoPersona: !this.editedItem.errorIdTipoPersona ? this.editedItem.anomaliaIdTipoPersona : null,
          errorNombreCompleto: !this.editedItem.errorNombreCompleto ? this.editedItem.anomaliaNombreCompleto : null,
          errorNombreContacto: !this.editedItem.errorNombreContacto ? this.editedItem.anomaliaNombreContacto : null,
          errorTelefonoContacto: !this.editedItem.errorTelefonoContacto ? this.editedItem.anomaliaTelefonoContacto : null,
          errorCorreoContacto: !this.editedItem.errorCorreoContacto ? this.editedItem.anomaliaCorreoContacto : null,
          errorTipoProveedorNacional: !this.editedItem.errorTipoProveedorNacional ? this.editedItem.anomaliaTipoProveedorNacional : null,
          errorRazonSocial: !this.editedItem.errorRazonSocial ? this.editedItem.anomaliaRazonSocial : null,
          errorRFC: !this.editedItem.errorRFC ? this.editedItem.anomaliaRFC : null,
          errorIdEstado: !this.editedItem.errorIdEstado ? this.editedItem.anomaliaIdEstado : null,
          errorIdMunicipio: !this.editedItem.errorIdMunicipio ? this.editedItem.anomaliaIdMunicipio : null,
          errorIdRegimenFiscal: !this.editedItem.errorIdRegimenFiscal ? this.editedItem.anomaliaIdRegimenFiscal : null,
          errorCodigoPostal: !this.editedItem.errorCodigoPostal ? this.editedItem.anomaliaCodigoPostal : null,
          errorColonia: !this.editedItem.errorColonia ? this.editedItem.anomaliaColonia : null,
          errorNumeroFiscal: !this.editedItem.errorNumeroFiscal ? this.editedItem.anomaliaNumeroFiscal : null,
          errorCalleFiscal: !this.editedItem.errorCalleFiscal ? this.editedItem.anomaliaCalleFiscal : null,
          errorNumExtFiscal: !this.editedItem.errorNumExtFiscal ? this.editedItem.anomaliaNumExtFiscal : null,
          errorNumIntFiscal: !this.editedItem.errorNumIntFiscal ? this.editedItem.anomaliaNumIntFiscal : null,
          errorCiudad: !this.editedItem.errorCiudad ? this.editedItem.anomaliaCiudad : null,
          errorRegion: !this.editedItem.errorRegion ? this.editedItem.anomaliaRegion : null,
          errorIdPais: !this.editedItem.errorIdPais ? this.editedItem.anomaliaIdPais : null,
          errorTelefonoFiscal: !this.editedItem.errorTelefonoFiscal ? this.editedItem.anomaliaTelefonoFiscal : null,
          errorIdBanco: !this.editedItem.errorIdBanco ? this.editedItem.anomaliaIdBanco : null,
          errorNombreBeneficiario: !this.editedItem.errorNombreBeneficiario ? this.editedItem.anomaliaNombreBeneficiario : null,
          errorClabe: !this.editedItem.errorClabe ? this.editedItem.anomaliaClabe : null,
          errorCuenta: !this.editedItem.errorCuenta ? this.editedItem.anomaliaCuenta : null,
          errorIdMoneda: !this.editedItem.errorIdMoneda ? this.editedItem.anomaliaIdMoneda : null,
          errorCie: !this.editedItem.errorCie ? this.editedItem.anomaliaCie : null,
          errorReferencia: !this.editedItem.errorReferencia ? this.editedItem.anomaliaReferencia : null,
          errorBanco: !this.editedItem.errorBanco ? this.editedItem.anomaliaBanco : null,
          errorClaveInternacional: !this.editedItem.errorClaveInternacional ? this.editedItem.anomaliaClaveInternacional : null,
          errorSwift: !this.editedItem.errorSwift ? this.editedItem.anomaliaSwift : null,
          errorIsbn: !this.editedItem.errorIsbn ? this.editedItem.anomaliaIsbn : null,
          errorAceptoTerminos: !this.editedItem.errorAceptoTerminos ? this.editedItem.anomaliaAceptoTerminos : null,
          errorCurriculumVitaeEmpresarial: !this.editedItem.errorCurriculumVitaeEmpresarial ? this.editedItem.anomaliaCurriculumVitaeEmpresarial : null,
          errorIdentificacionOficial: !this.editedItem.errorIdentificacionOficial ? this.editedItem.anomaliaIdentificacionOficial : null,
          errorComprobanteDomicilio: !this.editedItem.errorComprobanteDomicilio ? this.editedItem.anomaliaComprobanteDomicilio : null,
          errorCaratulaEstadoCuenta: !this.editedItem.errorCaratulaEstadoCuenta ? this.editedItem.anomaliaCaratulaEstadoCuenta : null,
          errorSituacionFiscal: !this.editedItem.errorSituacionFiscal ? this.editedItem.anomaliaSituacionFiscal : null,
          errorOpinionCumplimiento: !this.editedItem.errorOpinionCumplimiento ? this.editedItem.anomaliaOpinionCumplimiento : null,
          errorReferenciaComercial1: !this.editedItem.errorReferenciaComercial1 ? this.editedItem.anomaliaReferenciaComercial1 : null,
          errorReferenciaComercial2: !this.editedItem.errorReferenciaComercial2 ? this.editedItem.anomaliaReferenciaComercial2 : null,
          errorCertificacion: !this.editedItem.errorCertificacion ? this.editedItem.anomaliaCertificacion : null,
          errorFotografiaProducto1: !this.editedItem.errorFotografiaProducto1 ? this.editedItem.anomaliaFotografiaProducto1 : null,
          errorFotografiaProducto2: !this.editedItem.errorFotografiaProducto2 ? this.editedItem.anomaliaFotografiaProducto2 : null,
          errorFotografiaProducto3: !this.editedItem.errorFotografiaProducto3 ? this.editedItem.anomaliaFotografiaProducto3 : null,
          errorFotografiaProducto4: !this.editedItem.errorFotografiaProducto4 ? this.editedItem.anomaliaFotografiaProducto4 : null,
          errorCurriculumEmpresa: !this.editedItem.errorCurriculumEmpresa ? this.editedItem.anomaliaCurriculumEmpresa : null,
          errorActaConstitutiva: !this.editedItem.errorActaConstitutiva ? this.editedItem.anomaliaActaConstitutiva : null,
          errorPoderRepresentante: !this.editedItem.errorPoderRepresentante ? this.editedItem.anomaliaPoderRepresentante : null,
          errorIdentificacionRepresentante: !this.editedItem.errorIdentificacionRepresentante ? this.editedItem.anomaliaIdentificacionRepresentante : null,
          errorFachadaEmpresa1: !this.editedItem.errorFachadaEmpresa1 ? this.editedItem.anomaliaFachadaEmpresa1 : null,
          errorFachadaEmpresa2: !this.editedItem.errorFachadaEmpresa2 ? this.editedItem.anomaliaFachadaEmpresa2 : null,
          errorCedulaIdentificacionFiscal: !this.editedItem.errorCedulaIdentificacionFiscal ? this.editedItem.anomaliaCedulaIdentificacionFiscal : null,
          errorCurp: !this.editedItem.errorCurp ? this.editedItem.anomaliaCurp : null,
          errorSolicitudCertificacion: !this.editedItem.errorSolicitudCertificacion ? this.editedItem.anomaliaSolicitudCertificacion : null,
          errorSolicitudCertificacionMorales: !this.editedItem.errorSolicitudCertificacionMorales ? this.editedItem.anomaliaSolicitudCertificacionMorales : null,
          errorCurpRepresentante: !this.editedItem.errorCurpRepresentante ? this.editedItem.anomaliaCurpRepresentante : null,
          errorCedulaFiscalMoral: !this.editedItem.errorCedulaFiscalMoral ? this.editedItem.anomaliaCedulaFiscalMoral : null,
          errorTestimonioEscritura: !this.editedItem.errorTestimonioEscritura ? this.editedItem.anomaliaTestimonioEscritura : null,
          errorCertificado1: !this.editedItem.errorCertificado1 ? this.editedItem.anomaliaCertificado1 : null,
          errorCertificado2: !this.editedItem.errorCertificado2 ? this.editedItem.anomaliaCertificado2 : null,
          errorCertificado3: !this.editedItem.errorCertificado3 ? this.editedItem.anomaliaCertificado3 : null,
          errorDireccionGoogleMaps: !this.editedItem.errorDireccionGoogleMaps ? this.editedItem.anomaliaDireccionGoogleMaps : null,
          errorCalleSucursal: !this.editedItem.errorCalleSucursal ? this.editedItem.anomaliaCalleSucursal : null,
          errorNumExtSucursal: !this.editedItem.errorNumExtSucursal ? this.editedItem.anomaliaNumExtSucursal : null,
          errorNumIntSucursal: !this.editedItem.errorNumIntSucursal ? this.editedItem.anomaliaNumIntSucursal : null,
          errorIdEstadoSucursal: !this.editedItem.errorIdEstadoSucursal ? this.editedItem.anomaliaIdEstadoSucursal : null,
          errorIdMunicipioSucursal: !this.editedItem.errorIdMunicipioSucursal ? this.editedItem.anomaliaIdMunicipioSucursal : null,
          errorColoniaSucursal: !this.editedItem.errorColoniaSucursal ? this.editedItem.anomaliaColoniaSucursal : null,
          errorCodigoPostalSucursal: !this.editedItem.errorCodigoPostalSucursal ? this.editedItem.anomaliaCodigoPostalSucursal : null,
          errorCiudadSucursal: !this.editedItem.errorCiudadSucursal ? this.editedItem.anomaliaCiudadSucursal : null,
          errorRegionSucursal: !this.editedItem.errorRegionSucursal ? this.editedItem.anomaliaRegionSucursal : null,
          errorIdPaisSucursal: !this.editedItem.errorIdPaisSucursal ? this.editedItem.anomaliaIdPaisSucursal : null,
          errorPolizaResponsabilidadCivilMedica: !this.editedItem.errorPolizaResponsabilidadCivilMedica ? this.editedItem.anomaliaPolizaResponsabilidadCivilMedica : null,
          errorCopiaTituloMedico: !this.editedItem.errorCopiaTituloMedico ? this.editedItem.anomaliaCopiaTituloMedico : null,
          errorCedulaProfesionalMedico: !this.editedItem.errorCedulaProfesionalMedico ? this.editedItem.anomaliaCedulaProfesionalMedico : null,
          errorDiplomaEspecialidad: !this.editedItem.errorDiplomaEspecialidad ? this.editedItem.anomaliaDiplomaEspecialidad : null,
          errorCedulaRadiologia: !this.editedItem.errorCedulaRadiologia ? this.editedItem.anomaliaCedulaRadiologia : null,
          motivo_rechazo: this.motivo_rechazo
      })
      .then(() => {
        if(this.dialogRechazar) this.closeDialogRechazar();
        this.closeDialogValidate();
      })
      .finally(() => {
        this.saving = false;
      })
    },
    updateField(field, value) {
      //this.$set(this.editedItem, field, value);
      this.editedItem[field] = value;
    },
    getRowClass(item) {
      return (item.motivo_rechazo != null && item.motivo_rechazo.length > 0) ? 'red-row' : '';
    },
    openDialogRechazar(){
      this.dialogRechazar = true;
      this.motivo_rechazo = null;
    },
    closeDialogRechazar(){
      this.dialogRechazar = false;
      this.motivo_rechazo = null;
    },
    saveDialogRechazo(){
      if (
        !this.motivo_rechazo ||
        this.motivo_rechazo < 0
      ) {
        this.$refs.alert.setMessage(
          '',
          `Debe de indicar un Motivo de Rechazo del Proveedor`
        );
        this.$refs.alert.open();
        return;
      }

      this.save('b0eb6081-7d49-4af5-af7f-dd6be81179f5');
    },
    openDialogPassword(editItem){
      this.editedItem = Object.assign({}, editItem);
      this.dialogPasswd = true;
    },
    closeDialogPassword(){
      this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.dialogPasswd = false;
    },
    openDialogDelete(editedItem){
      this.editedItem = Object.assign({}, editedItem);
      this.dialogDelete = true;
    },
    closeDialogDelete(){
      this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.dialogDelete = false;
    },
    saveChangePasswd(){
      this.loadingChangePassword = false;
      axios
      .post('/Proveedores/CambiarPasswordProveedorUsuario/'+this.editedItem.id_proveedor_usuario)
      .then(() => {
        this.closeDialogPassword();
        this.getItems();
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loadingChangePassword = false;
      })
    }
  },
};
</script>

<style scoped>
.v-divider {
  margin: 10px 0;
}
.error-input {
  border-color: red !important;
}
</style>
