<template>
  <v-row class="ma-0 pa-0">
    <v-col cols="12" class="pa-0 ma-0">
      <v-tabs
        v-model="tab"
        centered
        grow
      >
        <v-tab
          href="#asistencia_dia"
          @click="actualizarItems('asistencia_dia')"
        >
          Asistencias del Día
        </v-tab>
        <v-tab
          v-if="mostrarVista(22)"
          href="#asistencias"
          @click="actualizarItems('asistencias')"
        >
          Registrar Evento
        </v-tab>
        <v-tab
          v-if="mostrarVista(23)"
          href="#enrolar"
          @click="actualizarItems('enrolar')"
        >
          Enrolar Colaborador
        </v-tab>
      </v-tabs>
          
      <v-tabs-items v-model="tab">
        <v-tab-item
          value="asistencia_dia"
        >
          <asistencia-dia
            ref="asistencia_dia"
          ></asistencia-dia>
        </v-tab-item>

        <v-tab-item
          v-if="mostrarVista(22)"
          value="asistencias"
        >
          <asistencias
            v-if="tab == 'asistencias'"
            ref="asistencias"
          ></asistencias>
        </v-tab-item>
        <v-tab-item
          v-if="mostrarVista(23)"
          value="enrolar"
        >
          <enrolar
            ref="enrolar"
          ></enrolar>
        </v-tab-item>
      </v-tabs-items>
    </v-col>
  </v-row>
</template>

<script>
import Asistencias from './ControlAsistencia/Asistencias.vue'
import Enrolar from './ControlAsistencia/Enrolar.vue'
import AsistenciaDia from './ControlAsistencia/AsistenciasDia.vue'
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['ids_vistas', 'vinculado'])
  },
  components:{
    Asistencias,
    Enrolar,
    AsistenciaDia
  },
  data: () => ({
    tab: 'asistencia_dia'
  }),
  methods: {
    mostrarVista(id_vista){
			if(this.ids_vistas == null || this.ids_vistas.length == 0) return false;
			return (this.ids_vistas.indexOf(id_vista) >= 0);
		},
    getItems(){
      this.actualizarItems(this.tab);
    },
    actualizarItems(refName) {
      if (this.$refs[refName] && this.$refs[refName].getItems) {
        this.$refs[refName].getItems();
      } else {
        console.warn(`No se encontró la referencia: ${refName}`);
      }
    },
    gotoenrolar(){
      this.tab = 'enrolar';
    }
  }
}
</script>