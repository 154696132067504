<template>
  <div>
    <v-alert-dialog
      ref="alert"
    >
    </v-alert-dialog>
    <v-dialog
      v-model="registroManualDialog"
      max-width="60%"
      persistent
    >
      <v-card>
        <v-card-title>
          Registro Manual
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-autocomplete-add
                v-model="editedItem.id_tipo_registro_actividad"
                label="Tipo de Registro"
                item-value="id_tipo_registro_actividad"
                item-text="nombre"
                url-get="/Catalogos/ListarTipoRegistroActividades"
                :hideAdd="true"
              >
              </v-autocomplete-add>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="editedItem.username"
                :rules="[rules.required, rules.min6]"
                label="Nombre de Usuario"
                hint="Al menos 6 caracteres"
                counter
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="editedItem.password"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min8]"
                :type="show1 ? 'text' : 'password'"
                label="Contraseña"
                hint="Al menos 8 caracteres"
                counter
                dense
                outlined
                @click:append="show1 = !show1"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-autocomplete-add
                v-model="editedItem.id_motivo_registro_actividad_manual"
                label="Motivo del Registro Manual"
                item-value="id_motivo_registro_actividad_manual"
                item-text="nombre"
                url-get="/Catalogos/ListarMotivoRegistroActividadManuales"
                :hideAdd="true"
              >
              </v-autocomplete-add>
            </v-col>
          </v-row>
          
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            text
            @click="cerrarRegistroManual"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="red"
            text
            @click="guardarRegistroManual"
            :disabled="saving"
            :loading="saving"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="removerDatosBiometricosDialog"
      max-width="40%"
      persistent
    >
      <v-card>
        <v-card-title>
          Remover Datos Biométricos - {{editedItem.nombre}} {{editedItem.apellido_paterno}} {{editedItem.apellido_materno}}
        </v-card-title>
        <v-card-text>
          ¿Esta seguro que desea eliminar los datos biométricos de {{editedItem.nombre}} {{editedItem.apellido_paterno}} {{editedItem.apellido_materno}}?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            text
            @click="cerrarRemoverDatosBiometricos"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="red"
            text
            @click="removerDatosBiometricos"
            :disabled="saving"
            :loading="saving"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="detallesDialog"
      max-width="50%"
      persistent
    >
      <v-card>
        <v-card-title>
          Mostrar Detalles del Día - {{editedItem.nombre}} {{editedItem.apellido_paterno}} {{editedItem.apellido_materno}}
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="detalles"
            :loading="loadingDetalles"
            no-data-text="No hay Reportes de Actividad en el Día"
            hide-default-footer
          >
            <template v-slot:[`item.manual`]="{ item }">
              {{(item.manual) ? 'Si' : 'No'}}
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            text
            @click="cerrarDetalles"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <br>
    <v-row
      v-if="!loading"
      class="px-6"
    >
      <v-col
        cols="1"
        md="2"
        sm="6"
        v-for="item in items"
        :key="item.id_usuario"
        class="ma-0 pa-0"
      >
        <v-card
          class="ma-2"
          height="330px"
        >
          <template v-if="item.fotografia == null">
            <v-img
              v-if="item.entrada != null"
              contain
              height="150"
              :src="'/img/persona.png'"
              class="grayscale-img is-enable"
            ></v-img>
            <v-img
              v-else
              contain
              height="150"
              :src="'/img/persona.png'"
              class="grayscale-img"
            ></v-img>
          </template>

          <template v-else>
            <v-img
              v-if="item.entrada != null"
              contain
              height="150"
              :src="`/api/archivos/pacientes/${item.fotografia}?jwt=${token}`"
            ></v-img>
            <v-img
              v-else
              contain
              height="150"
              :src="`/api/archivos/pacientes/${item.fotografia}?jwt=${token}`"
              class="grayscale-img"
            ></v-img>
          </template>

          <v-card-title
            style="height: 90px"
            class="text-subtitle-1 split-works text-center justify-content-center"
          >
            {{item.nombre}} {{item.apellido_paterno}} {{item.apellido_materno}}
          </v-card-title>
          <v-card-text
            class="my-0 py-0 text-center"
          >
            Entrada: {{item.entrada || 'N/D'}}
            <br>
            Salida: {{item.salida || 'N/D'}}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <VTooltipIcon
              v-if="item.entrada == null || item.salida == null"
              color="gray"
              icon="mdi-clock-plus-outline"
              :text="`Registro Manual`"
              @click="mostrarRegistroManual(item)"
            >
            </VTooltipIcon>
            <VTooltipIcon
              v-if="item.datos_biometricos"
              color="gray"
              icon="mdi-account-off"
              :text="`Remover Datos Biométricos`"
              @click="mostrarRemoverDatosBiometricos(item)"
            >
            </VTooltipIcon>
            <VTooltipIcon
              color="gray"
              icon="mdi-eye"
              :text="`Detalles`"
              @click="mostrarDetalles(item)"
            >
            </VTooltipIcon>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>  
      </v-col>
    </v-row>
    <div v-else
      class="text-center"
    >
      <v-progress-circular
        :size="200"
        color="primary"
        indeterminate
      ></v-progress-circular>
      <br />
      <br />
      <div class="text-h4">Cargando</div>
    </div>
    <br>
  </div>
</template>


<script>
import axios from 'axios'
import { mapState } from 'vuex'
import VTooltipIcon from '@/components/VTooltipIcon.vue';

export default {
  comments: {
    VTooltipIcon
  },
  computed: {
    ...mapState(['token'])
  },
  data: () => ({
    loading: false,
    items: [],
    show1: false,
    editedItem:{
      id_usuario: null,
      nombre: '',
      apellido_paterno: '',
      apellido_materno: '',
      fotografia: null,
      datos_biometricos: false,
      entrada: null,
      salida: null,
      id_tipo_registro_actividad: null,
      username: '',
      password: '',
      id_motivo_registro_actividad_manual: null
    },
    defaultEditedItem:{
      id_usuario: null,
      nombre: '',
      apellido_paterno: '',
      apellido_materno: '',
      fotografia: null,
      datos_biometricos: false,
      entrada: null,
      salida: null,
      id_tipo_registro_actividad: null,
      username: '',
      password: '',
      id_motivo_registro_actividad_manual: null
    },
    registroManualDialog: false,
    removerDatosBiometricosDialog: false,
    detallesDialog: false,
    rules: {
      required: value => !!value || 'Requerido.',
      min6: v => v.length >= 6 || 'Minimo 8 caracteres',
      min8: v => v.length >= 8 || 'Minimo 8 caracteres',
    },
    saving: false,
    detalles: [],
    loadingDetalles: false,
    headers: [
      {
        text: "Manual",
        value: "manual",
        align: "center",
      },
      {
        text: "Fecha y Hora",
        value: "realizado",
        align: "center"
      },
      {
        text: "Tipo",
        value: "nombre_tipo_registro_actividad",
        align: "center"
      },
      {
        text: "Unidad Médica",
        value: "nombre_unidad_medica",
        align: "center"
      },
      {
        text: "Operación Validada por",
        value: "nombre_usuario_valido",
        align: "center"
      },
      {
        text: "Motivo del Registro Manual",
        value: "nombre_motivo_registro_actividad_manual",
        align: "center"
      },
    ]
  }),
  mounted() {
    this.getItems();
  },
  methods: {
    getItems(){
      this.loading = true;
      
      axios
      .get('/ControlAsistencia/ListaAsistenciaDia')
      .then(response => {
        this.items = response.data.map(obj => ({
          ...obj,
          id_tipo_registro_actividad: null,
          username: '',
          password: '',
          id_motivo_registro_actividad_manual: null
        }));
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        this.loading = false;
      })
    },
    mostrarRegistroManual(item){
      this.registroManualDialog = true;
      this.editedItem = Object.assign({}, item);
    },
    cerrarRegistroManual(){
      this.registroManualDialog = false;
      this.editedItem = Object.assign({}, this.defaultEditedItem);
    },
    validarRegistroManual() {
      if (
        this.editedItem.id_tipo_registro_actividad == null ||
        this.editedItem.id_tipo_registro_actividad.length === 0
      ) {
        this.$refs.alert.setMessage(
          "",
          "Debe de Ingresar un Tipo de Registro de Actividad"
        );
        this.$refs.alert.open();
        return false;
      }

      if (
        this.editedItem.username == null ||
        this.editedItem.username.length === 0 ||
        this.editedItem.username <= 5
      ) {
        this.$refs.alert.setMessage(
          "",
          "El Nombre de Usuario debe tener al menos 6 caracteres"
        );
        this.$refs.alert.open();
        return false;
      }

      if (
        this.editedItem.username == null ||
        this.editedItem.username.length === 0 ||
        this.editedItem.username <= 7
      ) {
        this.$refs.alert.setMessage(
          "",
          "La contraseña debe de tener al menos 8 caracteres"
        );
        this.$refs.alert.open();
        return false;
      }

      if (
        this.editedItem.id_motivo_registro_actividad_manual == null ||
        this.editedItem.id_motivo_registro_actividad_manual.length === 0
      ) {
        this.$refs.alert.setMessage("", "Debe de Ingresar un Motivo de Registro Manual");
        this.$refs.alert.open();
        return false;
      }

      return true;
    },
    guardarRegistroManual(){
      if(!this.validarRegistroManual()) return;

      this.saving = true;
      axios
      .post('/ControlAsistencia/RegistrarActividadManual', {
        id_tipo_registro_actividad: this.editedItem.id_tipo_registro_actividad,
        id_usuario: this.editedItem.id_usuario,
        username: this.editedItem.username,
        password: this.editedItem.password,
        id_motivo_registro_actividad_manual: this.editedItem.id_motivo_registro_actividad_manual
      })
      .then(() => {
        this.cerrarRegistroManual();
        this.getItems();
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.saving = false;
      })
    },
    mostrarRemoverDatosBiometricos(item){
      this.removerDatosBiometricosDialog = true;
      this.editedItem = item;
    },
    cerrarRemoverDatosBiometricos(){
      this.removerDatosBiometricosDialog = false;
      this.editedItem = Object.assign({}, this.defaultEditedItem);
    },
    removerDatosBiometricos(){
      this.saving = true;
      axios
      .delete(`/ControlAsistencia/DesenrolarDatosBiometricosUsuario/${this.editedItem.id_usuario}`)
      .then(() => {
        this.cerrarRemoverDatosBiometricos();
        this.getItems();
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        this.saving = false;
      })
    },
    mostrarDetalles(item){
      this.detallesDialog = true;
      this.editedItem = item;
      this.loadingDetalles = true;
      axios
      .get(`/ControlAsistencia/ListaDetallesDia/${this.editedItem.id_usuario}`)
      .then(response => {
        this.detalles = response.data;
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        this.loadingDetalles = false;
      })
    },
    cerrarDetalles(){
      this.detallesDialog = false;
      this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.loadingDetalles = false;
      this.detalles = [];
    }
  }
}
</script>

<style>
.grayscale-img {
  filter: grayscale(100%);
}

.grayscale-img.is-enable {
  filter: sepia(1) hue-rotate(90deg) saturate(300%);
}

.split-works {
  word-break: break-word !important; /* Rompe en espacios */
  white-space: normal !important;    /* Permite múltiples líneas */
  overflow-wrap: break-word !important; /* Asegura que el contenido se ajuste */
}

</style>