<template>
  <v-stepper v-model="step">
    <v-stepper-header>
      <v-stepper-step
        :complete="step > 1"
        step="1"
      >
        Seleccionar Colaborador
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step
        :complete="step > 2"
        step="2"
      >
        Reconocimiento Facial
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step
        :complete="step > 3"
        step="3"
      >
        Huella Digital
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step
        :complete="step > 4"
        step="4"
      >
        Validación de Informacion
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <v-row class="ma-0 pa-0">
          <v-col cols="12" class="ma-0 pa-0">
            <v-data-table
              :loading="loading"
              :headers="headers"
              :items="items"
            >
              <template v-slot:[`item.imagen_perfil`]="{ item }">
                <v-avatar
                  size="100"
                  class="my-2">
                  <v-img :src="(item.fotografia != null) ? `/api/RecursosHumanos/DescargarDocumento/${item.fotografia}?jwt=${token}` : '/img/persona.jpg'"></v-img>
                </v-avatar>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-btn
                  block
                  text
                  color="red"
                  @click="enrolar(item)"
                >
                  <v-icon class="mr-2">
                    mdi-face-recognition
                  </v-icon>
                  Registrar Datos Biométricos
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-stepper-content>

      <v-stepper-content step="2">
        <div v-if="step == 2">
          <face-capture
            @change="capturaFotografias"
          ></face-capture>
        </div>

        <v-btn
          text
          color="green"
          @click="restablecer"
        >
          Cancelar
        </v-btn>
      </v-stepper-content>

      <v-stepper-content step="3">
        <div v-if="step == 3">
          <div class="text-center">
            <finger-reader
              @change="lecturaHuellas"
            >
            </finger-reader>
            <br>
            <div class="text-h6">
              Coloque su dedo índice en el lector de huellas digitales
            </div>
            <div class="text-body-1">
              Necesitaremos escanear su huella varias veces para configurar correctamente su perfil
            </div>
          </div>
        </div>

        <v-btn
          color="greeen"
          text
          @click="restablecer"
        >
          Reiniciar Proceso
        </v-btn>
      </v-stepper-content>

      <v-stepper-content step="4">
        <div class="py-6 px-4 bg-gray-50 rounded-lg shadow-md">
          <!-- Mensaje dinámico -->
          <div v-if="show" class="flex items-center space-x-4">
            <lottie v-if="modo === 3" :options="errorAnimation" :height="175" :width="175" />
            <lottie v-else-if="modo === 2" :options="checkAnimation" :height="175" :width="175" />
            <lottie v-else-if="modo === 1" :options="zoomAnimation" :height="175" :width="175" />
            <lottie v-else-if="modo === 0" :options="documentAnimation" :height="175" :width="175" />
            <p class="text-h6 text-center font-semibold text-gray-700">
              {{ mensajeModo }}
            </p>
          </div>

          <!-- Barra de progreso -->
          <v-progress-linear
            :value="progreso"
            :color="colorBarra"
            height="12"
            rounded
            class="mt-6"
          ></v-progress-linear>

          <!-- Detalle del proceso -->
          <div class="mt-8 bg-white p-6 rounded-lg shadow-sm">
            <p class="text-lg font-medium text-gray-600 mb-4">
              Validación de información biométrica
            </p>
            <p class="text-base text-gray-500 mb-4">
              Se están verificando los datos biométricos capturados para garantizar su autenticidad y calidad. 
            </p>
            <ul class="list-disc ml-6 text-gray-500 text-base">
              <li class="mb-2">Comprobando la identidad facial</li>
              <li>Verificando las huellas digitales con la base de datos</li>
            </ul>
            <p class="text-sm text-gray-400 mt-4">
              Este proceso puede tardar algunos segundos. Espere hasta que la validación se complete.
            </p>
          </div>
        </div>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>


<script>
import FaceCapture from '@/components/FaceCapture.vue'
import FingerReader from '@/components/FingerReader.vue'
import axios from 'axios'
import Lottie from 'vue-lottie';
import checkAnimationData from '@/assets/check.json';
import documentAnimationData from '@/assets/document.json';
import errorAnimationData from '@/assets/error.json';
import zoomAnimationData from '@/assets/zoom.json';


export default {
  components: {
    FaceCapture,
    FingerReader,
    'lottie': Lottie
  },
  computed: {
    mensajeModo() {
      switch (this.modo) {
        case 0:
          return "Preparando Información";
        case 1:
          return "Procesando Información";
        case 2:
          return "Éxito";
        case 3:
          return "Error";
        default:
          return "Estado desconocido";
      }
    },
    progreso() {
      switch (this.modo) {
        case 0:
          return 20;
        case 1:
          return 60;
        case 2:
        case 3:
          return 100;
        default:
          return 0;
      }
    },
    colorBarra() {
      return this.modo === 3 ? "red" : "primary";
    }
  },
  data: () => ({
    checkAnimation: {animationData: checkAnimationData, loop: false},
    documentAnimation: {animationData: documentAnimationData},
    errorAnimation: {animationData: errorAnimationData, loop: false},
    zoomAnimation: {animationData: zoomAnimationData},
    step: 1,
    headers: [
      {
        text: "Fotografia",
        value: "imagen_perfil",
        align: "center",
        sortable: false,
      },
      {
        text: "Nombre",
        value: "nombre",
        align: "center",
      },
      {
        text: "Apellido Paterno",
        value: "apellido_paterno",
        align: "center",
      },
      {
        text: "Apellido Materno",
        value: "apellido_materno",
        align: "center",
      },
      {
        text: "Acciones",
        value: "actions",
        sortable: false,
        align: "center",
      }
    ],
    items: [],
    loading: false,
    editedItem: {
      id_usuario: null,
      fotografias: [],
      huellas: []
    },
    defaultEditedItem: {
      id_usuario: null,
      fotografias: [],
      huellas: []
    },
    //0 Preparando Información
    //1 Procesando Información
    //2 Exito
    //3 Error
    modo: 0,
    show: true
  }),
  mounted() {
    this.getItems();
  },
  methods: {
    getItems(){
      this.step = 1;
      this.loading = true;
      axios
      .get('/ControlAsistencia/ListarEmpleados?datos_biometricos=false')
      .then(response => {
        this.items = response.data;
      })
      .catch(error =>{
        console.log(error);
      })
      .finally(() => {
        this.loading = false;
      })
    },
    enrolar(item){
      this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.editedItem.id_usuario = item.id_usuario;
      this.step = 2;
    },
    capturaFotografias(event){
      this.editedItem.fotografias = event;
      this.step = 3;
    },
    restablecer(){
      this.step = 1;
      this.editedItem = Object.assign({}, this.defaultEditedItem);
      //this.modo = 0;
      this.changeMode(0);
    },
    lecturaHuellas(evt){
      this.editedItem.huellas = evt;
      this.step = 4;

      setTimeout(() => {
        this.enviarInformacion().then(() => {})
      }, 250);
    },
    delay(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    },
    async changeMode(modo) {
      this.show = false;
      await this.delay(100); // Espera 250ms antes de continuar
      
      this.modo = modo;
      await this.delay(100); // Espera otros 250ms antes de continuar
      
      this.show = true;
    },

    async enviarInformacion(){
      //this.modo = 0;
      await this.changeMode(0);

      // Crear una instancia de FormData
      const formData = new FormData();

      // Agregar id_usuario al FormData
      formData.append("id_usuario", this.editedItem.id_usuario);

      // Agregar fotografias al FormData (mismo nombre repetido para cada valor)
      this.editedItem.fotografias.forEach(foto => {
        formData.append("fotografias", foto); // Nombre repetido
      });

      // Agregar huellas al FormData (mismo nombre repetido para cada valor)
      this.editedItem.huellas.forEach(huella => {
        formData.append("huellas", huella); // Nombre repetido
      });

      //this.modo = 1;
      await this.changeMode(1);

      // Hacer la solicitud con Axios
      axios
      .post("/ControlAsistencia/EnrolarDatosBiometricosUsuario", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(() => {
        //this.modo = 2;
        this.changeMode(2);
      })
      .catch(error => {
        console.log(error);
        //this.modo = 3;
        this.changeMode(3);
      })
    }
  }
}
</script>