<template>
  <v-container style="max-width: 1800px !important">
    <div class="pa-3 ma-3">
      <v-dialog
        v-model="dialogValidate"
        max-width="60%"
        persistent
      >
        <v-card>
          <v-card-title>
            Revisión de Proveedor
          </v-card-title>
          <v-card-text>
            ¿Desea Ddesactivar el Proveedor {{editedItem.nombre_completo}} con Clave {{editedItem.clave}}?
          </v-card-text>
          <v-card-actions>
            <v-btn
              text
              color="green"
              class="white--text"
              @click="closeDialogValidate"
            >
              Cancelar
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="blue"
              class="white--text"
              @click="closeDialogValidate"
            >
              Rechazar Proveedor
            </v-btn>
            <v-btn
              text
              color="red"
              class="white--text"
              @click="closeDialogValidate"
            >
              Aceptar Proveedor
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogDelete"
        max-width="65%"
        persistent
      >
        <v-card>
          <v-card-title>
            Desactivar Proveedor
          </v-card-title>
          <v-card-text>
            ¿Desea Ddesactivar el Proveedor {{editedItem.nombre_completo}} con Clave {{editedItem.clave}}?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="green"
              class="white--text"
              @click="closeDialogDelete"
            >
              Cancelar
            </v-btn>
            <v-btn
              text
              color="red"
              class="white--text"
              @click="closeDialogDelete"
            >
              Desactivar Proveedor
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-row class="ma-0 pa-0">
        <v-col cols="4">
          <v-autocomplete
            item-value="id"
            item-text="nombre"
            :items="estadosProveedor"
            label="Estado"
            dense
            outlined
            hide-details
            clearable
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="4">
          <v-text-field
            label="Correo Electrónico"
            dense
            outlined
            hide-details
            clearable
          >
          </v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            label="RFC"
            dense
            outlined
            hide-details
            clearable
          >
          </v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            label="# Proveedor"
            dense
            outlined
            hide-details
            clearable
          >
          </v-text-field>
        </v-col>
        <v-col cols="4">
          <v-autocomplete
            item-value="id"
            item-text="nombre"
            :items="statusProveedor"
            label="Activo"
            dense
            outlined
            hide-details
            clearable
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="4">
          <v-btn block color="primary" text outlined @click="getItems">
            <v-icon>mdi-magnify</v-icon>
            Buscar
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="items"
          >
           
            <template v-slot:[`item.actions`]="{ }">
              <v-icon
                small
                class="mr-2"
                @click="openDialogValidate"
              >
                mdi-check
              </v-icon>
              <v-icon
                class="mr-2"
                small
                @click="openDialogDelete"
              >
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import axios from 'axios'

export default {
  data: () => ({
    loading: false,
    items: [],
    estadosProveedor: [
      { id: 1, nombre:'Pendiente'},
      { id: 2, nombre:'Aceptado'},
      { id: 3, nombre:'Rechazado'},
    ],
    statusProveedor: [
      { id: 1, nombre:'Si'},
      { id: 2, nombre:'No'},
    ],
    headers: [
      {
        text: "Clave del Proveedor",
        value: "clave",
        align: "center",
      },
      {
        text: "Nombre Corto del Proveedor",
        value: "nombre_corto",
        align: "center",
      },
      {
        text: "Nombre Completo del Proveedor",
        value: "nombre_completo",
        align: "center",
      },
      {
        text: "Tipo de Persona",
        value: "tipo_persona",
        align: "center",
      },

      {
        text: "Tipo de Proveedor",
        value: "tipo_proveedor",
        align: "center",
      },
      {
        text: "Estado",
        value: "estado",
        align: "center",
      },
      {
        text: "Nombre del Contacto",
        value: "nombre_contacto",
        align: "center",
      },

      {
        text: "Teléfono de Contacto",
        value: "telefono_contacto",
        align: "center",
      },

      {
        text: "Correo Electrónico",
        value: "correo_electronico",
        align: "center",
      },

      {
        text: "Acciones",
        value: "actions",
        sortable: false,
        align: "center",
      },
    ],
    editedItem: {
      id_proveedor: null,
      clave: null,
      id_tipo_persona: null,
      tipo_persona: null,
      id_tipo_proveedor: null,
      tipo_proveedor: null,
      nombre_corto: null,
      nombre_completo: null,
      nombre_contacto: null,
      telefono_contacto: null,
      correo_electronico: null,
      id_tipo_proveedor_nacional: null,
      direccion_google_maps: null,
      calle_sucursal: null,
      num_ext_sucursal: null,
      num_int_sucursal: null,
      id_estado_sucursal: null,
      id_municipio_sucursal: null,
      colonia_sucursal: null,
      codigo_postal_sucursal: null,
      ciudad_sucursal: null,
      region_sucursal: null,
      pais_sucursal: null,
      curriculum_vitae_empresarial: null,
      identificacion_oficial: null,
      comprobante_domicilio: null,
      caratula_estado_cuenta: null,
      situacion_fiscal: null,
      opinion_cumplimiento: null,
      referencia_comercial_1: null,
      referencia_comercial_2: null,
      certificacion: null,
      fotografia_producto_1: null,
      fotografia_producto_2: null,
      fotografia_producto_3: null,
      fotografia_producto_4: null,
      curriculum_empresa: null,
      acta_constitutiva: null,
      poder_representante: null,
      identificacion_representante: null,
      fachada_empresa_1: null,
      fachada_empresa_2: null,
      cedula_identificacion_fiscal: null,
      curp: null,
      solicitud_certificacion: null,
      solicitud_certificacion_morales: null,
      curp_representante: null,
      cedula_fiscal_moral: null,
      testimonio_escritura: null,
      certificado_1: null,
      certificado_2: null,
      certificado_3: null,
      poliza_responsabilidad_civil_medica: null,
      copia_titulo_medico: null,
      cedula_profesional_medico: null,
      diploma_especialidad: null,
      cedula_radiologia: null,
      nivel_proveedor: null,
      datos_fiscales: {
        bloquear_rfc: false,
        calle: null,
        num_ext: null,
        num_int: null,
        razon_social: null,
        rfc: null,
        id_estado: null,
        id_municipio: null,
        id_regimen_fiscal: null,
        codigo_postal: null,
        colonia: null,
        numero_fiscal: null,
        ciudad: null,
        region: null,
        id_pais: null,
        telefono: null,
      }
    },
    defaultEditedItem: {
      id_proveedor: null,
      clave: null,
      id_tipo_persona: null,
      tipo_persona: null,
      id_tipo_proveedor: null,
      tipo_proveedor: null,
      nombre_corto: null,
      nombre_completo: null,
      nombre_contacto: null,
      telefono_contacto: null,
      correo_electronico: null,
      id_tipo_proveedor_nacional: null,
      direccion_google_maps: null,
      calle_sucursal: null,
      num_ext_sucursal: null,
      num_int_sucursal: null,
      id_estado_sucursal: null,
      id_municipio_sucursal: null,
      colonia_sucursal: null,
      codigo_postal_sucursal: null,
      ciudad_sucursal: null,
      region_sucursal: null,
      pais_sucursal: null,
      curriculum_vitae_empresarial: null,
      identificacion_oficial: null,
      comprobante_domicilio: null,
      caratula_estado_cuenta: null,
      situacion_fiscal: null,
      opinion_cumplimiento: null,
      referencia_comercial_1: null,
      referencia_comercial_2: null,
      certificacion: null,
      fotografia_producto_1: null,
      fotografia_producto_2: null,
      fotografia_producto_3: null,
      fotografia_producto_4: null,
      curriculum_empresa: null,
      acta_constitutiva: null,
      poder_representante: null,
      identificacion_representante: null,
      fachada_empresa_1: null,
      fachada_empresa_2: null,
      cedula_identificacion_fiscal: null,
      curp: null,
      solicitud_certificacion: null,
      solicitud_certificacion_morales: null,
      curp_representante: null,
      cedula_fiscal_moral: null,
      testimonio_escritura: null,
      certificado_1: null,
      certificado_2: null,
      certificado_3: null,
      poliza_responsabilidad_civil_medica: null,
      copia_titulo_medico: null,
      cedula_profesional_medico: null,
      diploma_especialidad: null,
      cedula_radiologia: null,
      nivel_proveedor: null,
      datos_fiscales: {
        bloquear_rfc: false,
        calle: null,
        num_ext: null,
        num_int: null,
        razon_social: null,
        rfc: null,
        id_estado: null,
        id_municipio: null,
        id_regimen_fiscal: null,
        codigo_postal: null,
        colonia: null,
        numero_fiscal: null,
        ciudad: null,
        region: null,
        id_pais: null,
        telefono: null,
      }
    },
    dialogValidate: false,
    dialogDelete: false,
  }),
  mounted() {
    this.getItems();
  },
  methods: {
    getItems() {
      const url = new URL(`http://localhost/Pagos/ListarProveedores`);
      //if(this.search.id_tipo_proveedor != null) url.searchParams.append("id_tipo_proveedor", this.search.id_tipo_proveedor);
      //if(this.search.nombre != null) url.searchParams.append("nombre", this.search.nombre);
      url.searchParams.append("nivel_proveedor", 2);
      var url_string = url.href.replace('http://localhost', '');

      this.loading = true;
      axios
        .get(url_string)
        .then((response) => {
          this.items = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openDialogValidate(editItem){
      this.editedItem = Object.assign({}, editItem);
      this.dialogValidate = true;
    },
    closeDialogValidate(){
      this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.dialogValidate = false;
    },
    openDialogDelete(editItem){
      this.editedItem = Object.assign({}, editItem);
      this.dialogDelete = true;
    },
    closeDialogDelete(){
      this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.dialogDelete = false;
    },
  },
};
</script>